import { fetchAPI } from "@/lib/api";
import { gql } from "@apollo/client";
import { responsiveImageFragment, pageRelatedFragment } from "@/lib/fragments";
import {
  HeroSliderFragment,
  HeroImageFragment,
  HeroVideoFragment,
  HeroFeaturedFragment,
  FormFragment,
} from "@/lib/section-fragment";

export async function getAccoladeHomepage(locale, preview, siteConfig) {
  const data = await fetchAPI(
    gql`
          query GetAccoladeHomepage($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
            brandAward(filter: {siteConfig: {eq: "${siteConfig}"}}, locale: $locale) {
              __typename
              id
              title
              accoladesShowRatings
              accoladesShowAwards
              accoladesRatingsYear
              accoladesRatings20 {
                ratingValue
              }
              accoladesRatings100 {
                ratingValue
              }
              accoladesAwardsType {
                title
              }
              awardsYear
              hero{
                __typename
                ...HeroSliderFragment
                ...HeroImageFragment
                ...HeroVideoFragment
                ...HeroFeaturedFragment 
                
              }
              backgroundColor {
              color {
                hex
              }
            }
            addHeadline
            headline
            description
            filterBarBgColor {

              color {
                hex
              }
            }
            filterButtonConfig {
              ...pageRelatedFragment
            }
            dynamicFilters {
              ... on AwardsFilterAccolateRecord {
                __typename
                id
                field
              }
              ... on AwardsFilterWineRecord {
                __typename
                id
                field
              }
            }
            addCallToAction
            buttonType
            callToActionPage
            buttonLabel {
              label
            }
            iconColor {
              color {
                hex
              }
            }
            labelColor {
              color {
                hex
              }
            }
            layout
            styleColor {
              color {
                hex
              }
            }
            addButtonIcon
            icon {
              img {
                alt
                url
                width
                height
              }
            }
            textColor {
              color {
                hex
              }
            }
            }
          }
         
          ${HeroSliderFragment}
          ${HeroImageFragment}
          ${HeroFeaturedFragment}
          ${HeroVideoFragment}
          ${responsiveImageFragment}
          ${pageRelatedFragment}
          ${FormFragment}
      `,
    {
      preview,
      variables: {
        locale,
      },
    }
  );
  return data;
}

export async function getAccoladesHomepageSlug(siteId) {
  const data = await fetchAPI(
    `
      query GetAccoladesHomepageSlug($eq: ItemId = "${siteId}") {
        brandsProduct(filter: {siteConfig: {eq: $eq}}, orderBy: _createdAt_ASC) {
              __typename
              accoladesSlug
          }
      }
      `,
    {
      variables: {
        siteId,
      },
    }
  );
  return data;
}
