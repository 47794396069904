
import { AwardCard, RatingCard } from "@/components/Molecules/RatingCard";
interface Props {
  awards: any;
  bgColor: any;
  color: any;
  blockBgColor: any;
  ratings: any;
  configuration: any;
  forwardedRef: any;
}
export function AwardsDetail({
  awards,
  bgColor,
  color,
  blockBgColor,
  ratings,
  configuration,
  forwardedRef,
}: Props) {
  const awardToShowByYear = awards.filter(
    (x: any) => x.contestYear >= configuration[3]
  );
  const ratingToShowByYear = ratings.filter(
    (x: any) => x.ratingYear >= configuration[3]
  );

  //filter awards by year with the category marked in configuration

  const filteredAwardsFinal: any = awardToShowByYear?.filter((award: any) => {
    const category = configuration[2]?.map((award: any) => award.title);
    return category?.includes(award.award?.title);
  });

  //filter ratings by rating value scale 0-20

  const ratingsAwardsFinal = ratingToShowByYear?.filter(
    (rating: any, i: number) => {
      if (
        rating.scale == "0-20" &&
        rating.rating?.ratingValue * 1 >= configuration[6]?.ratingValue * 1
      ) {
        return rating;
      } else return null;
    }
  );

  //filter ratings by rating value scale 0-20

  const ratingsAwardsFinal100 = ratingToShowByYear?.filter(
    (rating: any, i: number) => {
      if (
        rating.scale == "0-100" &&
        rating.rating?.ratingValue * 1 >= configuration[5]?.ratingValue * 1
      ) {
        return rating;
      }
    }
  );

  const FinalRatings = [...ratingsAwardsFinal, ...ratingsAwardsFinal100];

  // transform ratingYear property to year

  const accoladesRatings = FinalRatings?.map(
    ({ ratingYear: year, ...props }: any) => ({
      year,
      props,
    })
  );

  // transform contestYear property to year

  const accoladesAwards = filteredAwardsFinal?.map(
    ({ contestYear: year, ...props }: any) => ({
      year,
      props,
    })
  );

  // merge new arrays

  const accolades = [...accoladesAwards, ...accoladesRatings];

  // order array by year

  const orderedAccolades = accolades.sort((a, b) => b.year - a.year);


  return (
    <>
      <div ref={forwardedRef} style={{ background: bgColor?.color.hex }}>
        <div className="grid  px-6 lg:px-4 xl:px-2 2xl:px-0 col-span-1 lg:grid-cols-2 container mx-auto   gap-y-4 lg:gap-y-12 pb-32 pt-12 lg:pt-0">
          <div className="col-start-1 lg:col-start-2 col-span-1 flex flex-col gap-y-4  lg:pr-6">
            {orderedAccolades.map((entry: any, i: number) => {
              if (entry.props.__typename == "RatingRecord") {
                return (
                  <RatingCard
                    key={i}
                    content={entry.props}
                    year={entry.year}
                    color={color}
                    blockBgColor={blockBgColor}
                    conf={configuration[8]}
                  />
                );
              } else {
                return (
                  <AwardCard
                    key={i}
                    year={entry.year}
                    content={entry.props}
                    color={color}
                    blockBgColor={blockBgColor}
                    conf={configuration[8]}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
}
