"use client";

import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import fancyMapStyles from "./mapStyles.json";

export const MapComponent = (props: any) => {
	const mapRef = useRef<google.maps.Map | null>();
	const [mapLoaded, setMapLoaded] = useState(false);

	const options = {
		styles: props.mapStyle ? fancyMapStyles : undefined,
		minZoom: 4,
		maxZoom: 18,
		mapTypeControl: false,
		mapTypeId: "roadmap",
		fullscreenControl: false,
		zoomControl: true,
		scaleControl: true,
		gestureHandling: "auto",
	};

	const mapSize = {
		size: props.width < 450 ? "20vh" : "600px",
	};

	let containerStyle = {
		width: `100%`,
		height: mapSize.size,
	};

	const onMapLoad = useCallback((map: google.maps.Map) => {
		mapRef.current = map;

		setMapLoaded(true);
	}, []);

	const onUnmount = useCallback(function callback(map: google.maps.Map) {
		setMapLoaded(false);
	}, []);

	/**
	 * Run after map is loaded and fit bound
	 * Its run only on the start
	 */
	useEffect(() => {
		if (!mapLoaded) return;

		if (props.polygons && props.polygons.length === 1) {
			props.setCurrentRegionWhenIsOnlyOne();
		} else {
			fitBounds(props.markers);
		}
	}, [mapLoaded]);

	// Fit bounds only on mount
	const fitBounds = (markers: any[]) => {
		if (!mapRef?.current || !markers || markers.length === 0) return;

		const bounds = new google.maps.LatLngBounds();

		markers?.forEach((marker: any) =>
			bounds.extend(
				new window.google.maps.LatLng(marker.latitude, marker.longitude)
			)
		);
		mapRef.current.fitBounds(bounds);
	};

	useEffect(() => {
		if (!mapRef?.current) return;
		mapRef.current?.setZoom(props.zoom);
	}, [props.zoom]);

	/**
	 * When the user change zoom manually,
	 * this method change the zoom prop on the parent
	 * */
	const onZoomChange = () => {
		if (!mapRef.current) return;
		props.onZoomChange(mapRef.current.getZoom());
	};

	const panTo = (markers: any) => {
		if (!mapRef.current || !markers || markers.length === 0) return;
		markers.forEach((marker: any) =>
			mapRef.current!.panTo(
				new google.maps.LatLng(marker.latitude, marker.longitude)
			)
		);
	};

	// Fit bounds on mount, and when the markers change
	useEffect(() => {
		if (props.currentRegion && props.currentRegion.length > 0) {
			props.currentRegion.map((region: any) => {
				if (region.polygon !== undefined) {
					fitBoundsOnRegionOrCountry(region.polygon[0]);
				}
			});
		}
	}, [props.currentRegion]);

	useEffect(() => {
		if (props.currentCountry && props.currentCountry.length > 0) {
			props.currentCountry.map((country: any) => {
				if (country.polygon !== undefined) {
					fitBoundsOnRegionOrCountry(country.polygon[0]);
				}
			});
		}
	}, [props.currentCountry]);

	const fitBoundsOnRegionOrCountry = (regionPolygon: any) => {
		const bounds = new google.maps.LatLngBounds();

		regionPolygon.paths.forEach((path: any) => {
			if (path !== undefined) {
				path.forEach((p: any) => {
					bounds.extend(p);
				});
			}
		});

		mapRef?.current?.fitBounds(bounds);
	};

	useEffect(() => {
		if (props.currentMarkers && !props.currentMarkers.length) return;
		panTo(props.currentMarkers);
	}, [props.currentMarkers]);

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: `${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`,
	});

	return (
		<>
			{isLoaded && (
				<GoogleMap
					mapContainerStyle={containerStyle}
					id="map"
					center={props.defaultCenter}
					onLoad={onMapLoad}
					onUnmount={onUnmount}
					onZoomChanged={onZoomChange}
					options={options}
				>
					{props.children}
				</GoogleMap>
			)}
		</>
	);
};
