import { fetchAPI } from "@/lib/api";
import { pageRelatedFragment, responsiveImageFragment } from "@/lib/fragments";

import { HeroSliderFragment, HeroImageFragment } from "@/lib/section-fragment";

export async function getLegalPage(slug, locale, preview, siteConfig) {
  const data = await fetchAPI(
    `
        query LegalBySlug($slug: String, $locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          brandsLegal(filter: {slug: {eq: $slug}, siteConfig: {eq: "${siteConfig}"}}, locale: $locale) {
            _allSlugLocales {
              locale
              value
            }
      
            __typename
              title
              seo: _seoMetaTags {
                tag
                attributes
                content
              }
              metadata {
                description
                title
                twitterCard
              }
              hero{
                __typename
                ...HeroSliderFragment
                ...HeroImageFragment
              }
              content {
                value
                links {
                  ... on BrandsPageRecord {
                    __typename
                    id
                    title
                    slug
                  }
                  ... on BrandsPortfolioRecord {
                    __typename
                    id
                    slug
                    title
                  }
                  ... on BrandsLegalRecord {
                    __typename
                    id
                    slug
                    title
                  }
                  ... on BrandsHomepageRecord {
                    __typename
                    id
                    title
                  }
                }
                blocks {
                  ... on CallToActionRecord {
                    __typename
                    id
                    title
                    labelButton
                    description
                    buttonUrl
                  }
                  ... on CookieListRecord {
                    __typename
                    id
                    cookieBotId
                  }
                }
              }
            }
          }
          ${responsiveImageFragment}
          ${HeroSliderFragment}
          ${HeroImageFragment}
          ${pageRelatedFragment}
    `,
    {
      preview,
      variables: {
        slug,
        locale,
      },
    }
  );
  return data;
}
