import dynamic from "next/dynamic";
import { _getStaticProps as landingPageStaticProps } from "../pages_/landing-page";
import { _getStaticProps as productStaticProps } from "../pages_/product";
import { _getStaticProps as portfolioStaticProps } from "../pages_/portfolio";
import { _getStaticProps as legalStaticProps } from "../pages_/legal";
import { _getStaticProps as collectionStaticProps } from "../pages_/collection";
import { _getStaticProps as typeSpecificStaticProps } from "../pages_/type-specific";
import { _getStaticProps as awardsStaticProps } from "../pages_/awards";
import { _getStaticProps as ArticleStaticProps } from "../pages_/article";
import { _getStaticProps as RecipeStaticProps } from "../pages_/recipe";
import { _getStaticProps as AccoladesHomepageStaticProps } from "../pages_/accolades-homepage";
import { Locales } from "./types";

export interface LocaleRouteTypes extends Omit<Locales, "en" | "es" | "pt"> {
  pt: string[];
  en: string[];
  es: string[];
}
interface PagesType {
  component: any;
  getStaticProps: any;
  routers: LocaleRouteTypes;
}

interface DynamicPagesConfig {
  BrandsPortfolioRecord: PagesType;
  BrandsPageRecord: PagesType;
  BrandsLegalRecord: PagesType;
  AvailableSkuRecord: PagesType;
  ProductRecord: PagesType;
  BrandsProductRecord: PagesType;
  ProductCollectionRecord: PagesType;
  ProductTypeSpecificRecord: PagesType;
  ProductConfRecord: PagesType;
  AwardRecord: PagesType;
  RatingRecord: PagesType;
  ArticleRecord: PagesType;
  RecipeRecord: PagesType;
  BrandAwardRecord: PagesType;
}

const baseRoutes: LocaleRouteTypes = {
  pt: [],
  en: [],
  es: [],
};

export const dynamicPagesConfig: DynamicPagesConfig = {
  BrandsPortfolioRecord: {
    component: dynamic(() => import("../pages_/portfolio")),
    getStaticProps: portfolioStaticProps,
    routers: baseRoutes,
  },
  BrandsProductRecord: {
    component: dynamic(() => import("../pages_/portfolio")),
    getStaticProps: portfolioStaticProps,
    routers: baseRoutes,
  },
  BrandsPageRecord: {
    component: dynamic(() => import("../pages_/landing-page")),
    getStaticProps: landingPageStaticProps,
    routers: baseRoutes,
  },
  BrandsLegalRecord: {
    component: dynamic(() => import("../pages_/legal")),
    getStaticProps: legalStaticProps,
    routers: baseRoutes,
  },
  ArticleRecord: {
    component: dynamic(() => import("../pages_/article")),
    getStaticProps: ArticleStaticProps,
    routers: baseRoutes,
  },
  RecipeRecord: {
    component: dynamic(() => import("../pages_/recipe")),
    getStaticProps: RecipeStaticProps,
    routers: baseRoutes,
  },
  AvailableSkuRecord: {
    component: dynamic(() => import("../pages_/product")),
    getStaticProps: productStaticProps,
    routers: baseRoutes,
  },
  ProductRecord: {
    component: dynamic(() => import("../pages_/product")),
    getStaticProps: productStaticProps,
    routers: baseRoutes,
  },
  ProductCollectionRecord: {
    component: dynamic(() => import("../pages_/collection")),
    getStaticProps: collectionStaticProps,
    routers: baseRoutes,
  },
  ProductTypeSpecificRecord: {
    component: dynamic(() => import("../pages_/type-specific")),
    getStaticProps: typeSpecificStaticProps,
    routers: baseRoutes,
  },
  ProductConfRecord: {
    component: dynamic(() => import("../pages_/product")),
    getStaticProps: productStaticProps,
    routers: baseRoutes,
  },
  AwardRecord: {
    component: dynamic(() => import("../pages_/awards")),
    getStaticProps: awardsStaticProps,
    routers: baseRoutes,
  },
  RatingRecord: {
    component: dynamic(() => import("../pages_/awards")),
    getStaticProps: awardsStaticProps,
    routers: baseRoutes,
  },
  BrandAwardRecord: {
    component: dynamic(() => import("../pages_/accolades-homepage")),
    getStaticProps: AccoladesHomepageStaticProps,
    routers: baseRoutes,
  },
};
