import { gql } from "@apollo/client";

export const productFragment = gql`
  fragment productFragment on ProductRecord {
    __typename
    id
    productName
    h3SapCode
    slug
    productBrand {
      title
    }
    vintageYear
    harvestYear
    productDescriptionShort
    factSheetImage {
      responsiveImage(
        imgixParams: {
          fit: crop
          h: 700
          crop: focalpoint
          auto: format
          q: 70
        }
      ) {
        ...responsiveImageFragment
      }
    }
    sku {
      __typename
      id
      id
      skuName
      slug
      capacity {
        capacityValue
      }
      skuImageGallery {
        __typename
        id
        responsiveImage(
          imgixParams: {
            fit: crop
            h: 750
            crop: focalpoint
            auto: format
            q: 70
          }
        ) {
          ...responsiveImageFragment
        }
      }
    }
    productCountry {
      title
    }
    productCategory {
      style
    }
    productRegion {
      name
    }
    productColour {
      scale
    }
    winemakerBlender {
      title
    }
    productTypeSpecific {
      __typename
      slug
      id
      name
      _allSlugLocales {
        locale
        value
      }
    }
    productOrigin {
      originDesignation
    }
  }
`;
