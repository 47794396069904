import Image from "@/components/Atoms/Image";
import styles from "./index.module.scss";

interface Props {
  data: any;
  className?: any;
  position?: string;
}

export function BellowParallaxImage({ data }: Props): JSX.Element {
  return (
    <div
      className={` ${styles.behind} absolute top-0 bottom-0 m-auto flex items-end  `}
    >
      <Image data={data} />
    </div>
  );
}

export function AboveParallaxImage({
  data,
  className,
  position,
}: Props): JSX.Element {
  return (
    <div
      className={` w-[50%] absolute top-0 bottom-0 m-auto flex items-end ${className} ${
        styles.styleBg
      } ${
        position == "left"
          ? "left-0 translate-x-[-35%] lg:translate-x-[-50%]"
          : "right-0 translate-x-[45%] lg:translate-x-[50%]"
      }`}
    >
      <Image data={data} />
    </div>
  );
}

export function ParallaxImage({
  data,
  className,
  position,
}: Props): JSX.Element {
  return (
    <div
      className={` w-full absolute top-0 bottom-0 m-auto z-[-1] flex items-end ${className} ${styles.styleBg} `}
    >
      <Image data={data} />
    </div>
  );
}

export function StyleBackgroundImage({ data, position }: Props): JSX.Element {
  return (
    <div
      className={`  absolute m-auto  bottom-0 top-0 lg:top-[-50%] ${
        position == "left" ? "left-0 lg:left-[-30%]" : "left-[0%]"
      }  right-0 z-[0] opacity-50 `}
    >
      <Image
        data={data}
        className={`${
          position == "left" ? "lg:translate-x-[-30%]" : "lg:translate-x-[30%]"
        } m-auto`}
      />
    </div>
  );
}
