import { fetchAPI } from "@/lib/api";
import { responsiveImageFragment, pageRelatedFragment } from "@/lib/fragments";

export const siteConfigurationQuery = `
siteConfiguration(filter: {configurationId: {eq: "${process.env.WEBSITE_CONFIG_ID}"}}, locale: $locale) {
    _allMetadataLocales {
      locale
    }
    id
    favicon {
      url
      width
      height
    }
    metadata {
      description
      title
    }
    configurationId
    metaPixelId
    microsoftClarityId
    gtmId
    googleAnalyticsId
    googleConsoleId
    cookieBotId
    websiteName
    errorFourOFourMessage
    errorFiveOOMessage
    errorTextColor{
      color{
        hex
      }
    }
    backgroundImage {
      id
      responsiveImage {
        ... responsiveImageFragment
      }
    }
    _seoMetaTags {
      tag
      content
      attributes
    }
    headerMenu {
      id
      title
      hasNav
      burgerMenuIcon {
        img {
          url
          width
          height
        }
      }
      closeMenuIcon {
        img {
          url
          width
          height
        }
      }
      items {
        id
        label
        countryAvailability
        listOfCountries
        countries {
          title
          countryCode
        }
        linksToProductPage
        portfolioConfiguration {
          slug
        }
        product {
          product{
              __typename
            slug
            id
            productTypeSpecific {
              __typename
              slug
            }
          } 
        }
        link{
          ... on BrandsPortfolioRecord {
            __typename
            id
            slug
          }
          ... on BrandsProductRecord {
            __typename
            id
            slug
          }
          ... on BrandsPageRecord {
            __typename
            id
            slug
          }
        }
        subItems {
          id
          label
          link {
            ... on BrandsPageRecord {
              __typename
              id
              slug
              title
            }
          }
        }
      }
      metaItems {
        slug
        label
        id
      }
      onlineShopLinks {
        __typename
        label
        link
        externalLink
        externalUrl
      }
      logo {
        title
        alt
        url
        width
        height
        responsiveImage {
          ...responsiveImageFragment
        }
      }
      logoColour {
        title
        alt
        url
        width
        height
        responsiveImage {
          ...responsiveImageFragment
        }
      }

      mobileMenuBackgroundImage {
        url
        width
        height
      }
      mobileMenuBackgroundColor {
        color {
          hex
        }
      }
      desktopMenuBackgroudColor {
        color {
          hex
        }
      }
      desktopMenuItemsTextColor {
        color {
          hex
        }
      }
      menuTogglersColor {
        color {
          hex
        }
      }
      toggleAltLogoForMobile
      overwriteSocialIconsColor
      mobileMenuTextColor {
        color {
          hex
        }
      }
      logoAlignment
      navigationLinksAlignment
      headerMenuBarStyle
      addSocialLinks
    }
    footerMenu {
      id
      contestLabel {
        label
      }
      regulationFile {
        url
      }
      disclaimer {
        id
        label
      }
      addBuyCta
      ctaColor {
        color {
          hex
        }
      }
      ctaLabel {
        label
      }
      ctaLink
      countryAvailability
      listOfCountries
      countries {
        title
        countryCode
      }
      overwriteSocialIconsColor
      navigationLinksAlign
      addSpacer
      spacerType
      spacerLogo{
        url
        width
        height
      }
      textColor {
        hex
      }
      backgroundColor {
        hex
      }
      footerBackgroundColor {
        color {
          hex
        }
      }
      footerTextColor {
        color {
          hex
        }
      }
      hasNav
      hasSocial
      footerContent
      links {
        externalUrl
        externalLink
        newWindow
        slug
        image {
          title
          alt
          url
          width
          height
        }
      }
      navLinks {
        slug
        label
        link{ 
          ... on BrandsLegalRecord {
          id
          title
          slug
        }
        ... on BrandsPageRecord {
          id
          slug
        }
       }
      }
      socialIconsDisplay
      socialLinks {
        slug
        label
        externalUrl
        newWindow
        swapImage
        image {
          url
          width
          height
        }
      }
    }
    logoPicker
    ageGateContent
    ageGateQuestion
    ageGateRedirectUrl
    ageGateBackgroundColor{
      hex
    }
    ageGateBackgroundImage{
      url
      width
      height
    }
    ageGateTitle
    titleColor {
      hex
    }
    agBackgroundColor {
      color {
        hex
      }
    }
    agBodyColor {
      color {
        hex
      }
    }
    agHeadingColor {
      color {
        hex
      }
    }
    agTaglineColor {
      color {
        hex
      }
    }
    ageGateButton {
      ... on PageRelatedRecord {
        __typename
        id
        useExternalUrl
        externalUrl
        buttonStyle
        useAnchor
        sectionAnchor {
          __typename
          title
        }
        labelColor {
          name
          color {
            hex
          }
        }
        iconColor {
          name
          color {
            hex
            alpha
          }
        }
        icon {
          img {
            url
            width
            height
          }
        }
        addIcon
        linksToIframe
        iframeType
        buttonLabel {
          label
        }
        linksToProductPage
        portfolioConfiguration {
          slug
        }
        product {
          product{
              __typename
            slug
            id
            productTypeSpecific {
              __typename
              slug
            }
          } 
        }
        page {
          ... on BrandsPageRecord {
            id
            slug
            title
          }
          ... on BrandsProductRecord {
            __typename
            id
            slug
          }
          ... on BrandsPortfolioRecord {
            id
            title
            slug
          }
          ... on PageRecord {
            id
            title
            slug
          }
        }
        typeColor {
          name
          color {
            hex
          }
        }
      }
      ... on AgeGateButtonRecord {
        style
        styleColor {
          hex
        }
        buttonTextColor{
          hex
        }
        customButtonAsset
        buttonAsset{
          img{
            url
            width
            height
          }
        }
        assetColor {
          color{
            hex
          }
        }
      }
    }
    addTitleUnderline
    underlineColor{
      color{
        hex
      }
    }
    customUnderlineAsset{
      img{
        url
        width
        height
      }
    }
    questionColor {
      hex
    }
    contentColor {
      hex
    }
    showFooter
    dark {
      hex
    }
    light {
      hex
    }
    gray {
      hex
    }
    white {
      hex
    }
    danger {
      hex
    }
    primary {
      hex
    }
    secondary {
      hex
    }

    ageGateWelcome
    welcomeColor {
      hex
    }
}

`;

export async function getSiteConfiguration(locale, preview) {
  var siteId = 0;
  const data = await getSiteId()
    .then((config) => {
      siteId = config.id;
      return fetchAPI(
        `query Brand($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          brandsPortfolio(filter: {siteConfig: {eq: "${config.id}"}}, locale: $locale) {
            id
            umbrellaBrands {
              id
            }
          }
        }`
      );
    })
    .then((brandPortfolio) => {
      return fetchAPI(
        `
            query siteConfiguration($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
              
              ${siteConfigurationQuery}

              brandsPortfolio(filter: {siteConfig: {eq: "${siteId}"}}) {
                id
                title
                umbrellaBrands {
                  id
                  title
                }
              }

            }
              ${responsiveImageFragment}
        `,
        {
          preview,
          variables: {
            locale,
          },
        }
      );
    });
  return data;
}

export async function getSiteId() {
  const data = await fetchAPI(
    `
      query siteConfiguration($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) { 
        siteConfiguration(filter: {configurationId: {eq: "${process.env.WEBSITE_CONFIG_ID}"}}, locale: $locale) {
          id
          configurationId
        }
      }
    `
  );
  return data?.siteConfiguration;
}
