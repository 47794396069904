// See: https://www.datocms.com/blog/offer-responsive-progressive-lqip-images-in-2020
export const responsiveImageFragment = `
  fragment responsiveImageFragment on ResponsiveImage {
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    base64
  }
`;

export const metaTagsFragment = `
  fragment metaTagsFragment on Tag {
    attributes
    content
    tag
  }
`;

export const pageRelatedFragment = `
fragment pageRelatedFragment on PageRelatedRecord{
  __typename
    id
    useExternalUrl
    externalUrl
    buttonStyle
    useAnchor
    portfolioConfiguration {
      slug
      accoladesSlug
    }
    sectionAnchor {
      __typename
      title
    }
    labelColor {
      name
      color {
        hex
      }
    }
    iconColor {
      name
      color {
        hex
        alpha
      }
    }
    icon {
      img {
        url
        width
        height
      }
    }
    addIcon
    linksToIframe
    iframeType
    buttonLabel {
      label
    }
    linksToProductAwardsPage
    linksToProductPage
    product {
      product{
        __typename
      slug
      id
      productTypeSpecific {
        __typename
        slug
      }
      }
      
    }
    page {
      ... on BrandsPageRecord {
        id
        slug
        title
      }
      ... on BrandsProductRecord {
        __typename
        id
        slug
      }
      ... on ArticleRecord {
        id
        slug
      }
      ... on BrandsPortfolioRecord {
        id
        title
        slug
      }
      ... on PageRecord {
        id
        title
        slug
      }
    }
    typeColor {
      name
      color {
        hex
      }
    }

}
`;
