import { Circle } from "@/atoms/Vectors";
import styles from "./index.module.scss";

interface Props {
	currentSlide: number;
	index: number;
	onClick: any;
	type: string;
	color: any[any];
	activeColor: any[any];
	length: number;
	label: string;
	custom?: any;
}

export const NavigationSlider = ({
	currentSlide,
	onClick,
	type,
	index,
	color,
	activeColor,
	length,
	label,
	custom,
}: Props) => {
	const style = {
		"--color": color?.hex,
		"--activeColor": activeColor?.hex,
		"--shape": `url(${custom?.img?.url})`,
	} as React.CSSProperties;
	switch (type) {
		case "dotted":
			return (
				<>
					<button
						style={style as React.CSSProperties}
						aria-label={"navigation slider buttons"}
						onClick={onClick}
						className={`${
							currentSlide === index ? `${styles.active}` : ""
						} relative  w-2 h-2 rounded-full overflow-hidden ${
							styles.dottedButton
						}`}
						key={index}
					>
						{currentSlide === index ? (
							<span
								style={style as React.CSSProperties}
								className={`absolute right-0 left-0 top-0 bottom-0 m-auto w-4 h-4 ${styles.active}`}
							></span>
						) : (
							<span
								style={style as React.CSSProperties}
								className={`absolute right-0 left-0 top-0 bottom-0 m-auto w-4 h-4 ${styles.dottedSpan}`}
							></span>
						)}
					</button>
				</>
			);
			break;
		case "numbered":
			return (
				<>
					<button
						aria-label={"navigation slider buttons"}
						onClick={onClick}
						className={`${
							currentSlide === index ? " md:scale-120" : ""
						} relative `}
						key={index}
					>
						{currentSlide === index && <Circle color={color?.hex} />}
						<span
							style={style as React.CSSProperties}
							className={`${styles.numberedSpan} absolute right-0 left-0 top-0 bottom-0 m-auto h-fit`}
						>
							{index + 1}
						</span>
					</button>
				</>
			);
			break;
		case "numberedSq":
			return (
				<>
					<button
						aria-label={"navigation slider buttons"}
						onClick={onClick}
						className={`${
							currentSlide === index ? " md:scale-120" : ""
						} relative `}
						key={index}
					>
						<span
							style={style as React.CSSProperties}
							className={`${styles.numberedSquareSpan}   py-2 px-4  ${
								currentSlide !== index ? "border" : "border-[2px]"
							} `}
						>
							{index + 1}
						</span>
					</button>
				</>
			);
			break;
		case "labelled":
			return (
				<>
					<button
						aria-label={"navigation slider buttons"}
						onClick={onClick}
						className={`${
							currentSlide === index ? " opacity-100" : "opacity-50"
						} relative py-2 text-xs  flex flex-row justify-center font-primary  `}
						key={index}
					>
						<span
							style={style as React.CSSProperties}
							className={`${styles.labelledSpan}  flex flex-row gap-1`}
						>
							{index + 1}/{length} <span className="hidden">{label}</span>
						</span>
						{currentSlide === index && (
							<>
								<span
									style={style as React.CSSProperties}
									className={`${styles.activeLine}  w-full absolute bottom-0 left-0  h-[1px]`}
								></span>
							</>
						)}
					</button>
				</>
			);
			break;
		case "traced":
			return (
				<>
					<button
						aria-label={"navigation slider buttons"}
						onClick={onClick}
						className={`${
							currentSlide === index ? " opacity-100" : "opacity-50"
						} relative py-2 text-xs  flex flex-row justify-center font-primary  `}
						key={index}
					>
						<span
							style={style as React.CSSProperties}
							className={` ${
								currentSlide === index
									? `${styles.activeLine}`
									: `${styles.tracerLine}`
							}  flex flex-row gap-1  h-[2px] w-[1rem]`}
						></span>
					</button>
				</>
			);
			break;
		case "numberedTraced":
			return (
				<>
					<button
						aria-label={"navigation slider buttons"}
						onClick={onClick}
						className={`${
							currentSlide === index ? "opacity-100" : "opacity-50"
						} relative py-2 text-xs flex flex-row justify-center font-primary`}
						key={index}
					>
						<span className="relative flex flex-col items-center">
							<span
								style={{ color: color.hex }}
								className="text-base md:text-xl"
							>
								{index + 1}
							</span>
							<span
								style={style as React.CSSProperties}
								className={`${
									currentSlide === index
										? `${styles.activeLine}`
										: `${styles.tracerLine}`
								} flex flex-row gap-1 h-[2px] w-[1.5rem]`}
							></span>
						</span>
					</button>
				</>
			);
			break;

		case "custom":
			return (
				<>
					<button
						aria-label={"navigation slider buttons"}
						onClick={onClick}
						className={`${
							currentSlide === index ? " opacity-100" : "opacity-50"
						} relative py-2 text-xs  flex flex-row justify-center font-primary  `}
						key={index}
					>
						<span
							style={style as React.CSSProperties}
							className={` ${
								currentSlide === index
									? `${styles.activeCustom}`
									: `${styles.custom}`
							}  flex flex-row gap-1  h-[1.5rem] w-[5rem]`}
						></span>
					</button>
				</>
			);
			break;
		default:
			return <></>;
			break;
	}
};
