import Image from "@/components/Atoms/Image";
import ArticleCard from "../ArticleCard";
import { TagLabel } from "@/components/Atoms/Label";
import styles from "./index.module.scss";

interface ArticleBlockProps {
	data: any;
	pinPosts: any;
	layout: string;
	layoutCard: string;
	hideLabel: boolean;
	color: any;
	excerpt: any;
	cta: any;
	highlight: any;
	load: string;
}

export default function ArticleBlock({
	data,
	pinPosts,
	layout,
	layoutCard,
	hideLabel,
	color,
	excerpt,
	cta,
	highlight,
	load,
}: ArticleBlockProps) {
	let posts = [];

	if (load == "all") {
		posts = data;
	} else {
		posts = pinPosts;
	}
	switch (layout) {
		case "default":
			return (
				<div className="container mx-auto grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6 px-6 lg:px-4 xl:px-2 2xl:px-0">
					{posts.map((post: any, i: number) => {
						return (
							<div className={"col-span-1 flex flex-col"} key={i}>
								<div
									className={`${styles.imageContainerGrid} h-[300px] lg:h-[500px] col-span-1 relative`}
								>
									{!hideLabel && (
										<TagLabel
											highlight={highlight}
											label={post?.categories[0].title}
											color={color}
										/>
									)}
									<Image className={``} data={post.image} />
								</div>
								<div
									className={`${
										layoutCard === "card"
											? "translate-y-[-3rem] w-[90%] shadow-md md:shadow-none"
											: "w-full"
									} mx-auto`}
								>
									<ArticleCard
										className="truncate"
										post={post}
										cta={cta[0]}
										color={color}
										excerpt={excerpt}
										layout={layoutCard}
									/>
								</div>
							</div>
						);
					})}
				</div>
			);
			break;
		case "single":
			return (
				<div className="container mx-auto flex flex-col gap-6 mt-6 px-6 lg:px-4 xl:px-2 2xl:px-0">
					{posts.slice(0, 1).map((post: any, i: number) => {
						return (
							<div
								className={"flex flex-col md:flex-row gap-10 md:items-center"}
								key={i}
							>
								<div
									className={`${styles.imageContainerGrid} w-full md:w-3/5 relative`}
								>
									<Image data={post.image} className="rounded-xl" />
								</div>
								<ArticleCard
									className="truncate"
									post={post}
									cta={cta[0]}
									color={color}
									excerpt={excerpt}
									layout={layoutCard}
								/>
							</div>
						);
					})}
				</div>
			);
			break;
		case "grid":
			return (
				<div className="container mx-auto grid lg:grid-cols-2 gap-6 mt-6 px-6 lg:px-4 xl:px-2 2xl:px-0">
					{posts.map((post: any, i: number) => {
						return (
							<div
								className={"col-span-1 flex flex-col bg-white rounded-xl"}
								key={i}
							>
								<div
									className={`${styles.imageContainerGrid} h-[300px] lg:h-[500px] col-span-1 relative`}
								>
									{!hideLabel && (
										<TagLabel
											highlight={highlight}
											label={post?.categories[0].title}
											color={color}
										/>
									)}
									<Image className="rounded-t-xl" data={post.image} />
								</div>
								<div
									className={`${
										layoutCard === "card"
											? "translate-y-[-3rem] w-[90%] shadow-md md:shadow-none"
											: "w-full"
									} mx-auto`}
								>
									<ArticleCard
										className="truncate"
										post={post}
										cta={cta[0]}
										color={color}
										excerpt={excerpt}
										layout={layoutCard}
									/>
								</div>
							</div>
						);
					})}
				</div>
			);
			break;
		default:
			return (
				<div className="container mx-auto grid gap-6 mt-6 px-6 lg:px-4 xl:px-2 2xl:px-0">
					{posts.map((post: any, i: number) => {
						if (i == 0) {
							return (
								<div
									className="flex flex-col md:grid md:grid-cols-5 md:col-span-2"
									key={i}
								>
									<div className="md:col-span-3 relative">
										{!hideLabel && (
											<TagLabel
												highlight={highlight}
												label={post?.categories[0].title}
												color={color}
											/>
										)}
										<Image
											className="top-0 w-full h-full object-center md:object-bottom"
											data={post.image}
										/>
									</div>
									<ArticleCard
										post={post}
										cta={cta[0]}
										color={color}
										excerpt={excerpt}
										layout={layoutCard}
									/>
								</div>
							);
						} else {
							return (
								<div className={"grid grid-cols-1 md:grid-cols-2"} key={i}>
									<div className="col-span-2 relative">
										{!hideLabel && (
											<TagLabel
												highlight={highlight}
												label={post?.categories[0].title}
												color={color}
											/>
										)}
										<Image
											className={`top-0 w-full h-full object-center md:object-bottom`}
											data={post.image}
										/>
									</div>
									<ArticleCard
										className="truncate"
										post={post}
										cta={cta[0]}
										color={color}
										excerpt={excerpt}
										layout={layoutCard}
									/>
								</div>
							);
						}
					})}
				</div>
			);
			break;
	}
}
