import { GenericOverlayShadow } from "@/components/Molecules/OverlayShadow/Overlay";
import React, { useState, useRef } from "react";
import { PlayIcon } from "../Vectors";

interface VideoPlayerBlockProps {
  videoUrl: string;
  loop?: boolean;
  autoplay?: boolean;
  textColor?: string;
}

const VideoPlayerBlock: React.FC<VideoPlayerBlockProps> = ({
  videoUrl,
  loop = false,
  autoplay = false,
  textColor = "#000",
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleButtonClick = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
      setIsPlaying(!video.paused);
      video.controls = true;
    }
  };

  const handleVideoEnd = () => {
    const video = videoRef.current;
    if (video) {
      video.controls = false;
      video.currentTime = 0;
      setIsPlaying(false);
    }
  };

  return (
    <div className="videoContainer relative group cursor-pointer">
      {!isPlaying && (
        <button
          className="absolute right-0 bottom-0 left-0 top-0 m-auto w-fit h-fit scale-[2.5] z-10 group-hover:scale-[3] transition-all duration-300"
          onClick={handleButtonClick}
        >
          <PlayIcon color={textColor} />
        </button>
      )}
      {!isPlaying && <GenericOverlayShadow />}
      <video
        ref={videoRef}
        playsInline
        loop={loop}
        autoPlay={autoplay}
        muted={false}
        controls={false}
        src={videoUrl}
        className="w-full"
        poster=""
        onEnded={handleVideoEnd}
      />
    </div>
  );
};

export default VideoPlayerBlock;
