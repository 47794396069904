/**
 * Here we handle all the components based on __typename of DatoCms
 * It's used by Blocks Component to call specific component
 */

import dynamic from "next/dynamic";
import {
	query as BrandArticlesQuery,
	variables as ArticleVariables,
} from "@/components/Organisms/BrandArticle";
import {
	query as BrandRecipesQuery,
	variables as RecipesVariables,
} from "@/components/Organisms/BrandRecipe";
import { SectionBrandImagesFragment } from "./section-fragment";

export const sectionConfig = {
	SectionHeroFeaturedRecord: {
		repeatable: false,
		component: dynamic(() =>
			import("@/components/Organisms/Hero/HeroFeatured")
		),
	},
	SectionHeroImageRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Hero/HeroImage")),
	},
	SectionHeroSliderRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Hero/HeroSlider")),
	},
	SectionTextImageRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/TextImage")),
	},
	SectionTextGalleryRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/TextGallery")),
	},
	SectionImageRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/ImageVideo")),
	},
	SectionLogotypeRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Logotypes")),
	},
	SectionProductRangeRecord: {
		repeatable: false,
		component: dynamic(() =>
			import("@/components/Organisms/Product/ProductRange")
		),
	},
	TestimonialSectionRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Testimonial")),
	},
	SectionGalleryRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/ImageGallery")),
	},
	SectionFormRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Form")),
	},
	SectionHeroVideoRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Hero/HeroVideo")),
	},

	SectionMultiPurposeBlockRecord: {
		repeatable: false,
		component: dynamic(() =>
			import("@/components/Organisms/MultipurposeBlock")
		),
	},

	SectionSliderRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Slider")),
	},
	SectionQuoteRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Quote")),
	},
	SectionTextRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Text")),
	},
	SectionLocationsMapRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Location")),
	},
	SectionExperienceRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Experiences")),
	},
	SectionSocialWallRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/SocialWall")),
	},
	SectionStoreLocatorRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/StoreLocator")),
	},
	SectionGalleryVideoRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/VideoGallery")),
	},
	SectionGrapeVarietyRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/GrapeVariety")),
	},
	SectionBrandArticleRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/BrandArticle")),
		query: BrandArticlesQuery,
		variables: ArticleVariables,
	},
	SectionBrandRecipeRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/BrandRecipe")),
		query: BrandRecipesQuery,
		variables: RecipesVariables,
	},
	SectionTimelineRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/Timeline")),
	},
	SectionWineTourismRecord: {
		repeatable: false,
		component: dynamic(() => import("@/components/Organisms/EstateMap")),
	},
};
