import { fetchAPI } from "@/lib/api";
import { responsiveImageFragment } from "@/lib/fragments";
import { gql } from "@apollo/client";

export async function getRatings(locale) {
  const data = await fetchAPI(
    gql`
        query GetAllRatings($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          allRatings( orderBy: ratingYear_DESC, locale: $locale) {
            __typename
            id
            ratingYear
            rating {
              ratingValue
              ratingSubvalue
              ratingImage {
                responsiveImage {
                  src
                }
              }
            }
            scale
            product {
              slug
            }
            mediaPublication {
              title
              image {
                responsiveImage {
                  src
                }
              }
            }
          }

        }
       `,
    {
      variables: {
        locale,
      },
    }
  );
  return data;
}

export async function getRatingsById(locale, preview, productId) {
  const data = await fetchAPI(
    gql`
      
        query GetAllRatingsByProductsIdsList($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}, $productId: ItemId) {
          
          allRatings(orderBy: ratingYear_DESC, filter: {product: {eq: $productId}}, locale: $locale) {
            __typename
            product{
              id
              productName
              productBrand{
                image{
                  image{
                    responsiveImage {
                      ...responsiveImageFragment
                    }
                  }
                }
              }
              sku{
                skuImageGallery{
                  id
                  responsiveImage {
                    ...responsiveImageFragment
                  }
                }
              }
              slug
              h3SapCode
              _allSlugLocales{
                locale
                value
              }
            }
            id
            ratingYear
            tastingNote
            country {
              title
            }
            taster {
              id
              title
              country {
                title
              }
            }
            rating {
              ratingValue
              ratingSubvalue
              ratingImage {
                responsiveImage {
                  ...responsiveImageFragment
              title
                }
              }
            }
            scale
            product {
              slug
            }
            mediaPublication {
              title
              image {
                responsiveImage {
                  ...responsiveImageFragment
                }
              }
            }
          }
    
        }
        ${responsiveImageFragment}
       `,
    {
      variables: {
        preview,
        productId,
        locale,
      },
    }
  );
  return data;
}

export async function getRatingsByProductsIdsList(productsIdsList) {
  const data = await fetchAPI(
    gql`
      query GetAllRatingsByProductsIdsList($productsIdsList: [ItemId!]) {
        allRatings(
          first: "100"
          orderBy: ratingYear_DESC
          filter: { product: { in: $productsIdsList } }
        ) {
          __typename
          id
          product {
            id
            productName
            slug
            h3SapCode
            productBrand {
              image {
                image {
                  responsiveImage {
                    ...responsiveImageFragment
                  }
                }
              }
            }
            _allSlugLocales {
              locale
              value
            }
          }
          mediaPublication {
            title
          }
          taster {
            taster
            title
            country {
              id
              title
            }
          }
          ratingYear
          scale
          rating {
            ratingValue
            ratingSubvalue
            ratingImage {
              responsiveImage {
                ...responsiveImageFragment
                title
              }
            }
          }
        }
      }
      ${responsiveImageFragment}
    `,

    {
      variables: {
        productsIdsList,
      },
    }
  );
  return data;
}
