import { pageHandler } from "@/lib/pages-handler";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { getPortfolio, getPortfolioProductsByProductNameWithoutYear } from "@/dato-api/portfolio";
import { buildPortfolioSlug, getRealPortfolioSlug } from "@/lib/functions";
import { getAccoladeHomepage } from "@/dato-api/accolades-homepage";
import { getAwardsByProductsIdsList } from "@/dato-api/awards";
import { getRatingsByProductsIdsList } from "@/dato-api/ratings";
import AccoladesDisplayBlock from "@/organisms/AccoladesDisplay";
const Hero = dynamic(() => import("@/components/Organisms/Hero"));

export default function AccoladesHomePage({ data, relations }) {
  const { locale } = useRouter();
  const accolades = data.brandAward;

  return (
    <>
      <Hero data={accolades?.hero} />
      <AccoladesDisplayBlock
        config={accolades}
        locale={locale}
        portfolioSlug={data?.portfolio?.brandsProduct?.slug}
        data={data?.portfolio?.brandsProduct?.productsList}
      />
    </>
  );
}

export async function _getStaticProps(
  context,
  pageType,
  serverSideTranslations,
  siteConfig
) {
  const preview = context.preview !== undefined ? context.preview : false;
  const currentSlug = context.params.slug.at(-1);

  const result = await getAccoladeHomepage(
    context.locale,
    preview,
    siteConfig.siteConfiguration.id
  );

  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    result,
    "brandAward",
    true
  );

  // GET PORTFOLIO PRODUCTS AND CONFIGURATIONS
  const portfolio = await getPortfolio(
    context.locale,
    preview,
    siteConfig.siteConfiguration.id,
    "wines"
  );

  let products = [];
  let productsName = [];
  if (portfolio) {
    if (portfolio?.brandsProduct?.productsList?.length > 0) {
      portfolio.brandsProduct.productsList =
        portfolio?.brandsProduct?.productsList
          ?.map((list) => list.productsDefinition)?.[0]
          ?.filter((productDefinition) => {
            if (!productDefinition.hide) {
              products.push(productDefinition.product?.id);
              productsName.push(productDefinition.product?.productName);
              return productDefinition;
            }
          });
    }

    if (portfolio.brandsProduct?.productsCollection?.length > 0) {
      portfolio.brandsProduct.productsCollection =
        portfolio?.brandsProduct?.productsCollection?.filter((collection) => {
          if (collection.hideHome === false) {
            products.push(collection?.product?.id);
            productsName.push(collection?.product?.productName);
            return collection;
          }
        });
    }

    // get all products from list of products and make a request to get all products with the same name but with different years in the end of the name
    await Promise.all(productsName.map(async (product) => {
      // regex to get the product name without the year Ex: "Silk & Spice Spice Road Red 2020" => "Silk & Spice Spice Road Red"
      const productName = product?.replace(/ \d{4}$/, "");
   
      const p = await getPortfolioProductsByProductNameWithoutYear(context.locale, preview, productName);
      p.allProducts.map((product) => {
        products.push(product?.id);
      });
   
      products = products.filter((product) => product !== undefined);
    }));

    // products2 now contains the result of all the promises

    const awards =
      products.length > 0 ? await getAwardsByProductsIdsList(products) : [];
    const ratings =
      products.length > 0 ? await getRatingsByProductsIdsList(products) : [];

    portfolio?.brandsProduct?.productsList?.map((list) => {
      list.product.ratings =
        ratings?.allRatings?.filter(
          // (rating) => rating?.product?.id === list.product?.id
          (rating) => rating?.product?.productName.replace(/ \d{4}$/, "") === list.product?.productName.replace(/ \d{4}$/, "")
        ) || [];
      list.product.awards =
        awards?.allAwards?.filter(
          // (award) => award?.product?.id === list.product?.id
          (award) => award?.product?.productName.replace(/ \d{4}$/, "") === list.product?.productName.replace(/ \d{4}$/, "")
        ) || [];

      // Order ratings by year and get the 3 first
      const orderedRatings = list?.product?.ratings.sort(
        (a, b) => b?.ratingYear - a?.ratingYear
      );

      //Filter product ratings by brands awards configuration
      const ratingToShowByYear = orderedRatings.filter(
        (x) => x?.ratingYear >= pageData.data?.brandAward?.accoladesRatingsYear
      );
      const orderedRatingsFiltered = [];
      if (ratingToShowByYear.length > 0) {
        ratingToShowByYear?.filter((rating) => {
          if (
            rating.scale == "0-20" &&
            rating.rating?.ratingValue * 1 >=
            pageData.data?.brandAward?.accoladesRatings20?.ratingValue * 1
          ) {
            return orderedRatingsFiltered.push(rating);
          } else return null;
        });

        ratingToShowByYear?.filter((rating) => {
          if (
            rating.scale == "0-100" &&
            rating.rating?.ratingValue * 1 >=
            pageData.data?.brandAward?.accoladesRatings100?.ratingValue * 1
          ) {
            return orderedRatingsFiltered.push(rating);
          }
        });
      }

      // Order awards by year and get the 3 first
      const orderedAwards = list.product.awards.sort(
        (a, b) => b.awardYear - a.awardYear
      );

      //Filter product awards by brans awards configuration
      const awardToShowByYear = orderedAwards.filter(
        (x) => x?.contestYear >= pageData?.data?.brandAward?.awardsYear
      );
      const orderedAwardsFiltered = [];
      if (awardToShowByYear.length > 0) {
        awardToShowByYear?.filter((award) => {
          const category = pageData?.data?.brandAward?.accoladesAwardsType.map(
            (award) => award.title
          );
          return orderedAwardsFiltered.push(
            category.includes(award.award.title)
          );
        });
      }

      // Merged list of awards and ratings ordered by year
      list.product.awardsAndRatings = [
        ...orderedAwardsFiltered,
        ...orderedRatingsFiltered,
      ].sort((a, b) => b.ratingYear - a.ratingYear);
    });
  }



  const _allSlugLocales = [
    {
      locale: "en",
      value: portfolio?.brandsProduct?.accoladesSlug,
    },
    {
      locale: "es",
      value: portfolio?.brandsProduct?.accoladesSlug,
    },
    {
      locale: "pt",
      value: portfolio?.brandsProduct?.accoladesSlug,
    }
  ]

  return {
    props: {
      siteConfig: siteConfig.siteConfiguration,
      ...pageData.sst,
      data: {
        ...pageData.data,
        currentSlug,
        siteConfig,
        portfolio,
        _allSlugLocales,
      },
      preview: pageData.preview,
      pageType,
    },
  };
}
