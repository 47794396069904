import { pageHandler } from "@/lib/pages-handler";
import { getLegalPage } from "@/dato-api/legal";
import dynamic from "next/dynamic";
import { buildHrefLangsList } from "@/lib/functions";
import HrefLangs from "@/components/Atoms/hrefLangs";
import OgUrl from "@/components/Atoms/ogUrl";
import { useRouter } from "next/router";
const Hero = dynamic(() => import("@/components/Organisms/Hero"));
const GenericContent = dynamic(() =>
  import("@/components/Organisms/PageContent").then((c) => c.GenericContent)
);


export default function LegalPage({ data, relations }) {
  const { locale } = useRouter();
  return (
    <>
      <HrefLangs links={data.hrefLangs} />
      <OgUrl url={data.hrefLangs.filter(link => link.hrefLang === locale)?.[0]?.href} />
      <Hero data={data.hero} />
      <GenericContent content={data.content} />
    </>
  );
}

export async function _getStaticProps(
  context,
  pageType,
  serverSideTranslations,
  siteConfig
) {
  const preview = context.preview !== undefined ? context.preview : false;
  const currentSlug =
    context.params.slug.length > 1
      ? context.params.slug[1]
      : context.params.slug[0];
  const result = await getLegalPage(
    currentSlug,
    context.locale,
    preview,
    siteConfig.siteConfiguration.id
  );

  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    result,
    "brandsLegal"
  );

  const _allSlugLocales = pageData.data?._allSlugLocales
  const hrefLangs = buildHrefLangsList(_allSlugLocales, pageData.data?.__typename)

  return {
    props: {
      siteConfig: siteConfig.siteConfiguration,
      ...pageData.sst,
      data: { ...pageData.data, hrefLangs },
      preview: pageData.preview,
      pageType,
    },
  };
}
