import { pageHandler } from "@/lib/pages-handler";
import { getPortfolio } from "@/dato-api/portfolio";
import dynamic from "next/dynamic";
const Hero = dynamic(() => import("@/components/Organisms/Hero"));
import MenuPortfolio from "@/components/Organisms/MenuPortfolio";
import { useRouter } from "next/router";
import { buildHrefLangsList, getPortfolioSlug, getRealPortfolioSlug, portfolioMapper } from "@/lib/functions";
import { ProductGrid } from "@/components/Organisms/Product";
import HrefLangs from "@/components/Atoms/hrefLangs";
import OgUrl from "@/components/Atoms/ogUrl";


export default function BrandsPortfolioTypeSpecificPage({ data, relations }) {
  const { locale, query } = useRouter();
  const portfolioSlug = getPortfolioSlug(data.brandsProduct, locale)
  let ids = [];
  let products = []

  const slugContent = data.brandsProduct?.productsCollection.map((collections) => {

    collections.productsCollection.filter(collection => {
      if (collection.productTypeSpecific.slug === data.currentSlug && ids.indexOf(collection.id) === -1) {
        ids.push(collection.id)
        products.push(collection);
      }
    })
  });
  return (
    <>
      <HrefLangs links={data.hrefLangs} />
      <OgUrl url={data.hrefLangs.filter(link => link.hrefLang === locale)?.[0]?.href} />
      <Hero data={data.brandsProduct?.hero} />
      {data.brandsProduct.showNavbar && <MenuPortfolio
        bg={data.brandsProduct.hero.bgColor}
        text={data.brandsProduct.hero.textColor}
        data={data.brandsProduct.menu}
        portfolioSlug={portfolioSlug}
      />
      }

      <div>
        <ProductGrid
          bgColor={data.brandsProduct.bgColor}
          text={data.brandsProduct.productTextColor}
          style={data.brandsProduct.productStyleColor}
          btn={data.brandsProduct.productButtonTextColor}
          btnStyle={data.brandsProduct.productButtonStyleColor}
          product={products}
          portfolioSlug={portfolioSlug} />
      </div>

    </>
  );
}

export async function _getStaticProps(
  context,
  pageType,
  serverSideTranslations,
  siteConfig
) {
  const preview = context.preview !== undefined ? context.preview : false;
  const currentSlug = context.params.slug[context.params.slug.length - 1];
  const portfolioSlug = context.params.slug[0];

  const portfolioRealSlug = getRealPortfolioSlug(portfolioSlug, context.locale);

  const result = await getPortfolio(
    context.locale,
    preview,
    siteConfig.siteConfiguration.id,
    portfolioRealSlug
  );

  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    result,
    "",
    true
  );
  
  const portfolioSlugsMapper = portfolioMapper[portfolioRealSlug]
  const currentCollection = pageData.data.brandsProduct.productsCollection.filter((x) => x.title.slug.includes(currentSlug));
  const _allSlugLocales = currentCollection[0]?.title?._allSlugLocales
  // console.log('_allSlugLocales', _allSlugLocales, pageData.data.brandsProduct.productsCollection.map((x) => x));

  _allSlugLocales?.map((slug) => {
    const path1 = portfolioSlugsMapper[slug.locale]
    slug.value = `${path1}/${slug.value}`
  })


  const hrefLangs = buildHrefLangsList(_allSlugLocales, pageData.data?.__typename)

  return {
    props: {
      siteConfig: siteConfig.siteConfiguration,
      ...pageData.sst,
      data: { ...pageData.data, currentSlug, hrefLangs, _allSlugLocales: _allSlugLocales || [] },
      preview: pageData.preview,
      pageType,
    },
  };
}

