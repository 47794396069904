


export function filterWines(wines: any[], filters: any): any[] {
    const { productTypeSpecific, productColour, country, winemaker, year } = filters;

    let filteredWines = wines;



    if (productTypeSpecific && productTypeSpecific !== undefined && productTypeSpecific !== 'productTypeSpecific' && productTypeSpecific !== 'all') {
        filteredWines = filteredWines.filter(wine => {
            return wine.product.productTypeSpecific.name === productTypeSpecific;
        })
    }

    if (productColour && productColour !== undefined && productColour !== 'productColour' && productColour !== 'all') {
        filteredWines = filteredWines.filter(wine => {
            return wine.product.productColour.scale === productColour;
        })
    }

    if (country && country !== undefined && country !== 'country' && country !== 'all') {
        filteredWines = filteredWines.filter(wine => {
            return wine.product.productCountry.title === country;
        })
    }

    if (winemaker && winemaker !== undefined && winemaker !== 'winemaker' && winemaker !== 'all') {
        filteredWines = filteredWines.filter(wine => {
            return wine.product.winemakerBlender.some((w: { title: string; }) => w.title === winemaker);
        })
    }

    if (year && year !== undefined && year !== 'year' && year !== 'all') {
        filteredWines = filteredWines.filter(wine => {
            return wine.product.vintageYear === parseInt(year);
        })
    }

    return filteredWines;
}

/*
wines array example:
[
    {
        "__typename": "ProductDefinitionRecord",
        "id": "174903073",
        "label": "Red Blend",
        "hide": false,
        "customLabel": true,
        "overwriteDefaultData": true,
        "customData": [
            {
                "id": "178623315",
                "typeofcontent": "pdescription",
                "content": {
                    "__typename": "SectionTextRecord",
                    "text": "The Original"
                }
            },
            {
                "id": "180598795",
                "typeofcontent": "mbanner",
                "content": {
                    "__typename": "SectionImageRecord"
                }
            }
        ],
        "showProductAwards": true,
        "geo": [],
        "color": {
            "color": {
                "hex": "#ECE2CF"
            }
        },
        "highlightColor": {
            "color": {
                "hex": "#653A17",
                "blue": 23,
                "green": 58,
                "red": 101
            }
        },
        "media": [
            {
                "typeofimage": "image",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1690970875-red-blend-the-original.jpg?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 151w,https://www.datocms-assets.com/33016/1690970875-red-blend-the-original.jpg?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 303w,https://www.datocms-assets.com/33016/1690970875-red-blend-the-original.jpg?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 454w,https://www.datocms-assets.com/33016/1690970875-red-blend-the-original.jpg?auto=format&crop=focalpoint&fit=crop&q=70 606w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 606px) 100vw, 606px",
                        "src": "https://www.datocms-assets.com/33016/1690970875-red-blend-the-original.jpg?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 606,
                        "height": 373,
                        "aspectRatio": 1.6246648793565683,
                        "alt": "red blend the original",
                        "title": "red blend the original",
                        "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICAgLFgoLDhAQFhUNDhcVDBUYFx8ZHRYVFhUaHysjJh0oHSEWJDUlKC0vMjIyGSI4PTcwPCsxMi8BCgsLDg0OHA0QHC8oFigvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIAA8AGAMBIgACEQEDEQH/xAAXAAEAAwAAAAAAAAAAAAAAAAAFAAMG/8QAHxAAAQUAAQUAAAAAAAAAAAAAAQACAwQFBhETITFB/8QAFAEBAAAAAAAAAAAAAAAAAAAAA//EABgRAAMBAQAAAAAAAAAAAAAAAAABAiER/9oADAMBAAIRAxEAPwBTk2iyKn7Qub2rcIcT9RPILslin5JVWTPJFQBBROUmPKw1HI5YIsZrQ4KIixA/Rz+jnKJU1wGp0//Z"
                    }
                }
            },
            {
                "typeofimage": "altFeature",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1689785092-hero-image-red-blend.jpg?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 384w,https://www.datocms-assets.com/33016/1689785092-hero-image-red-blend.jpg?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 768w,https://www.datocms-assets.com/33016/1689785092-hero-image-red-blend.jpg?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 1152w,https://www.datocms-assets.com/33016/1689785092-hero-image-red-blend.jpg?auto=format&crop=focalpoint&fit=crop&q=70 1536w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 1536px) 100vw, 1536px",
                        "src": "https://www.datocms-assets.com/33016/1689785092-hero-image-red-blend.jpg?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 1536,
                        "height": 632,
                        "aspectRatio": 2.430379746835443,
                        "alt": "hero image red blend",
                        "title": "hero image red blend",
                        "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICAgFCgoFBQwFBQUFBREJCgUMFxMZGBYTFhUaHysjGh0oHRUWJDUlKC0vMjIyGSI4PTcwPCsxMi8BCgsLBQUFEAUFEC8cFhwvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIAAoAGAMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAABv/EABQQAQAAAAAAAAAAAAAAAAAAAAD/xAAVAQEBAAAAAAAAAAAAAAAAAAAFAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AJEAaUAEn//Z"
                    }
                }
            }
        ],
        "showCertificates": false,
        "product": {
            "__typename": "ProductRecord",
            "id": "86380782",
            "productName": "Silk & Spice Red Blend 2021",
            "h3SapCode": "000940000100001470",
            "slug": "silk-spice-red-blend-2021",
            "productBrand": {
                "title": "Silk & Spice"
            },
            "vintageYear": 2021,
            "harvestYear": "Winter cold and rainy compared to the normal climatological. Spring recorded maximum temperatures higher than normal. The emergence of bunches was verified in April, a particularly rainy month. The summer months presented maximum temperatures above the climatological normal. The harvest began at the end of August, extending into September.",
            "productDescriptionShort": "Silk & Spice pays homage to the sense of adventure, knowledge and courage of the Portuguese sailors that explored the world in the 15th century and opened the silk and spice trade routes between Europe and Asia. ",
            "sku": [
                {
                    "__typename": "AvailableSkuRecord",
                    "id": "86380559",
                    "skuName": "Silk & Spice Red Blend 2021 750mL",
                    "slug": "silk-spice-red-blend-2021-750ml",
                    "capacity": {
                        "capacityValue": "750 mL"
                    },
                    "skuImageGallery": [
                        {
                            "__typename": "FileField",
                            "id": "35799802",
                            "responsiveImage": {
                                "srcSet": "https://www.datocms-assets.com/33016/1674465912-silk-spice-red-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=0.25&fit=crop&h=750&q=70 132w,https://www.datocms-assets.com/33016/1674465912-silk-spice-red-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=0.5&fit=crop&h=750&q=70 265w,https://www.datocms-assets.com/33016/1674465912-silk-spice-red-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=0.75&fit=crop&h=750&q=70 397w,https://www.datocms-assets.com/33016/1674465912-silk-spice-red-blend-2021-750ml.png?auto=format&crop=focalpoint&fit=crop&h=750&q=70 530w,https://www.datocms-assets.com/33016/1674465912-silk-spice-red-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=1.5&fit=crop&h=750&q=70 795w,https://www.datocms-assets.com/33016/1674465912-silk-spice-red-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=2&fit=crop&h=750&q=70 1060w",
                                "webpSrcSet": "",
                                "sizes": "(max-width: 530px) 100vw, 530px",
                                "src": "https://www.datocms-assets.com/33016/1674465912-silk-spice-red-blend-2021-750ml.png?auto=format&crop=focalpoint&fit=crop&h=750&q=70",
                                "width": 530,
                                "height": 750,
                                "aspectRatio": 0.7066666666666667,
                                "alt": "Silk & Spice Red Blend 2021 750mL",
                                "title": "Silk & Spice Red Blend 2021 750mL",
                                "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAZCAYAAADXPsWXAAAC7UlEQVR42l2Va5IaMQyE5cc8gM3v3CRnyhlyiFwtN0lVKgs7wFjOJ9kGNrDamfHY7VarZfLPH99FahW1UBUtRXailDJx/02rfuXV7xDCr5TSn5yzTESeJskpSYhRsi2qYjgAaAOyeyIDnBlLvCVCUg1tIw82CkEia/N9352JfQeITyi6ssEK0FprXWAyMU1Y5yHBl0mMQfLtdhNxJrVHZ1TKEcAjIEfGLyxcqsa2YW3zbcMQ4gCpHUge+lTVL2pR68lBRFaNXbsOkJKBvDBxihJeWb3BCqD6xUAYPoTOtLZNvAjB0tn3uy82xBEdicXyxh1R/3K/NpbhkU7UzsTzMgaUarDhxcq/U2xsTqrxyLJVRuKDSVc5ewqdQUSkaGAxHhg9seCNySe0OHJdPA3pHwdot/glOUBKUVJMEnkGaGXswJZUyAFWGC/aKygPKM/BjJe9TAZmDrQrQEsHOQBCKFEWrhPPd+mMOhHJ87y4YZIDYOdsTNIc0YWJBzQ5KMYrgGDAGVb3T2kZyLLMroWDWE8QKcaF0i3MxLEAFAXAmJQZNueqA6T+zyS3dAwkxZl0ENJcCkAyFpFI87O3XphMdGMM4cnENYkTIDMT0UBnOm1GgSkITKL5JDrIQxOriglrVSENLzHpmdrWvdnwW4TsQd8G6QjdmHmUaWRYW2/YVnitBkQNsGnBc+15hIddumMH4uibYG1snkRV+gNd7Y/vEMONGbxvpHrv7M2tlgbniKXG487cHZQ7EDeQCL2xwd04NIDu7tHFITxfdFI3yrgV1Q9ILH4lGN/Ghqk529fl6/X6qYND02ZTX1guJGJn7Tsszry8xF4EM6W1iZ+xN0DkM4DpYjtfWHxGioQa79jrnRkfsbeHFvxk7n6cbB1gnJvot7HwAz0uDmIurXIeflL8ZM1IVcxT7aB+OdQchEVXUtkA2Twd1Y03m5+nVqDnUSixgZWXqss4+rysfKw6u3euyD1yxpZiwrZfBPsJsc8//c6DC39V7i8AAAAASUVORK5CYII="
                            }
                        }
                    ]
                }
            ],
            "productCountry": {
                "title": "Portugal"
            },
            "productCategory": null,
            "productRegion": null,
            "productColour": {
                "scale": "Red"
            },
            "winemakerBlender": [
                {
                    "title": "Diogo Sepúlveda"
                }
            ],
            "productTypeSpecific": {
                "__typename": "ProductTypeSpecificRecord",
                "slug": "red-wine",
                "id": "45180173",
                "name": "Red Wine",
                "_allSlugLocales": [
                    {
                        "locale": "en",
                        "value": "red-wine"
                    },
                    {
                        "locale": "es",
                        "value": "vino-tinto"
                    },
                    {
                        "locale": "pt",
                        "value": "vinho-tinto"
                    }
                ]
            },
            "productOrigin": {
                "originDesignation": "Wine with indication of vintage year"
            },
            "ratings": [
                {
                    "__typename": "RatingRecord",
                    "id": "181828220",
                    "product": {
                        "id": "86380782",
                        "productName": "Silk & Spice Red Blend 2021",
                        "slug": "silk-spice-red-blend-2021",
                        "h3SapCode": "000940000100001470",
                        "productBrand": {
                            "image": [
                                {
                                    "image": {
                                        "responsiveImage": null
                                    }
                                },
                                {
                                    "image": {
                                        "responsiveImage": null
                                    }
                                }
                            ]
                        },
                        "_allSlugLocales": [
                            {
                                "locale": "en",
                                "value": "silk-spice-red-blend-2021"
                            },
                            {
                                "locale": "es",
                                "value": "silk-spice-red-blend-2021"
                            },
                            {
                                "locale": "pt",
                                "value": "silk-spice-red-blend-2021"
                            }
                        ]
                    },
                    "mediaPublication": {
                        "title": "Wine Enthusiast"
                    },
                    "taster": [
                        {
                            "taster": true,
                            "title": "Roger Voss",
                            "country": {
                                "id": "44031673",
                                "title": "United States of America"
                            }
                        }
                    ],
                    "ratingYear": 2023,
                    "rating": {
                        "ratingValue": "91",
                        "ratingSubvalue": "",
                        "ratingImage": {
                            "responsiveImage": {
                                "title": null,
                                "srcSet": "https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.25 54w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.5 108w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.75 162w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png 216w",
                                "webpSrcSet": "https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.25&fm=webp 54w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.5&fm=webp 108w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.75&fm=webp 162w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?fm=webp 216w",
                                "sizes": "(max-width: 216px) 100vw, 216px",
                                "src": "https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png",
                                "width": 216,
                                "height": 216,
                                "aspectRatio": 1,
                                "alt": null,
                                "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEBElEQVR42mVWba7bOAwkJdkOtnhbtGjv/6tn6SX2AAW2+6OvaBxLotQZSnbeookZxZHM4ecw6Z+vX6Tz3Z+CWxFVCSFIjJQkKUVfI35TCF+9NzEzqZVSpJYqpVYxSjPprUuKKfGo37QTYCAAgBJdKVcCBgAGDdzGMyoDydHkjW2iFtyAtG3r2MBNa91Xx8ArXF4AxD0YogCgEhoTWvAzGsLlsVmErjY8uN1ufpDK+aNvAGSaAuP1BusTFOsAgP34kS+cMXh9xGg7RAxGJEsAoPLmetO6bhOAys03J8h7QLyHHgAAiN5AhvUAIUCQpl0y9n8C9DsMgIrx/BnuxOTRFaPPV7LlM+QTvr9g3UTayKrKDF5nPHCrvM9Y/wLkAgu+AdcYtlE4ImnEv185gAcfUBWfm9nH1hsBVhoOCztC1Z9hihDt8KbAtI3wUGXQ9m1a4XlKpRSPV/VyK0sp9e9ayku1+gKwdwBexIOiDRXUYvBEQz9z4omtvqtaoPCBs3fofZVZYOnxeHjMWLsAu+VStlLyCqDFrC7Ycy+pgcmFUniRAkHOqgLcgr0VOBtO3XD0VU+A/X5HKcADB8ias4uUXNg0cM569+aAB0FtKu2JTZei+gpvAgEDr5AAtLhHcCPt+34BQHHL+WjHwTU3hMoQOu2sAsQXkTcoQnciPCl1VEhAkTSANIB5fkZuvMo8CemAtSxPtjmVHvmw4zgsH9kQLjBBZQhZFKb0IHjTBSjtaSGAA/0J4iUNgLO5zAVvs06B5fheOyqqOwCJAVXfwogYLj1JYsrJGAgZ9p1OAEC+GWXtfNOHBOcfc/45S7978/K8hueq+oeMLj89WJZVGtqch0ejtUl45DTPXaAHNBoPEJxh0DQSjJXJfsO28STGCXDbNg9PjJVeNJRZP0krpYXlq2g4JZvMUvVGcwpHLKLHn2tkj6BXolHPleTttnkOavUOLWwcrBXWWVkqc9LYiHQP+pu6BwwhFA4CZOlmKM5w9sDzB87kGSWGaHGKcModcdsRmQceeqRaE/LuAcJZ0JVWPNx8PsQ5JyJKN8Qdz/900tPweuaDZZAYLxXSMzg8Otn9QDje4cTKhEP94qWhBAiVzTZ4yQujTaq4B3Zv0O9uhAzl/EievfmeFIWG0v/CKK+M7+ucUnUA0GJ0NXIFb2xwkP7C/o/Bd29qFh+JHNR8Hpgg4JPL2x3A/2L/gBGrOoDWQOuGoOyUiSF7Hn1M21Prm1cfbNrOgWPmQ9wcrO34PePUOomrwssqk5nEw6q8ZpvILPMJNLFSwb+Ba6KZd/MYeaMfYKHsOicZBjAvqJ5NdU2gOfDHxzVsHGAoe47M9hyZ1yF6gHzifkyqa66d6q+h1f8Hwus3XoUa2M3pMmIAAAAASUVORK5CYII="
                            }
                        }
                    }
                },
                {
                    "__typename": "RatingRecord",
                    "id": "181828222",
                    "product": {
                        "id": "86380782",
                        "productName": "Silk & Spice Red Blend 2021",
                        "slug": "silk-spice-red-blend-2021",
                        "h3SapCode": "000940000100001470",
                        "productBrand": {
                            "image": [
                                {
                                    "image": {
                                        "responsiveImage": null
                                    }
                                },
                                {
                                    "image": {
                                        "responsiveImage": null
                                    }
                                }
                            ]
                        },
                        "_allSlugLocales": [
                            {
                                "locale": "en",
                                "value": "silk-spice-red-blend-2021"
                            },
                            {
                                "locale": "es",
                                "value": "silk-spice-red-blend-2021"
                            },
                            {
                                "locale": "pt",
                                "value": "silk-spice-red-blend-2021"
                            }
                        ]
                    },
                    "mediaPublication": null,
                    "taster": [],
                    "ratingYear": 2023,
                    "rating": null
                }
            ],
            "awards": [],
            "awardsAndRatings": [
                {
                    "__typename": "RatingRecord",
                    "id": "181828220",
                    "product": {
                        "id": "86380782",
                        "productName": "Silk & Spice Red Blend 2021",
                        "slug": "silk-spice-red-blend-2021",
                        "h3SapCode": "000940000100001470",
                        "productBrand": {
                            "image": [
                                {
                                    "image": {
                                        "responsiveImage": null
                                    }
                                },
                                {
                                    "image": {
                                        "responsiveImage": null
                                    }
                                }
                            ]
                        },
                        "_allSlugLocales": [
                            {
                                "locale": "en",
                                "value": "silk-spice-red-blend-2021"
                            },
                            {
                                "locale": "es",
                                "value": "silk-spice-red-blend-2021"
                            },
                            {
                                "locale": "pt",
                                "value": "silk-spice-red-blend-2021"
                            }
                        ]
                    },
                    "mediaPublication": {
                        "title": "Wine Enthusiast"
                    },
                    "taster": [
                        {
                            "taster": true,
                            "title": "Roger Voss",
                            "country": {
                                "id": "44031673",
                                "title": "United States of America"
                            }
                        }
                    ],
                    "ratingYear": 2023,
                    "rating": {
                        "ratingValue": "91",
                        "ratingSubvalue": "",
                        "ratingImage": {
                            "responsiveImage": {
                                "title": null,
                                "srcSet": "https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.25 54w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.5 108w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.75 162w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png 216w",
                                "webpSrcSet": "https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.25&fm=webp 54w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.5&fm=webp 108w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?dpr=0.75&fm=webp 162w,https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png?fm=webp 216w",
                                "sizes": "(max-width: 216px) 100vw, 216px",
                                "src": "https://www.datocms-assets.com/33016/1635428796-ratingsen91-100-pontos.png",
                                "width": 216,
                                "height": 216,
                                "aspectRatio": 1,
                                "alt": null,
                                "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEBElEQVR42mVWba7bOAwkJdkOtnhbtGjv/6tn6SX2AAW2+6OvaBxLotQZSnbeookZxZHM4ecw6Z+vX6Tz3Z+CWxFVCSFIjJQkKUVfI35TCF+9NzEzqZVSpJYqpVYxSjPprUuKKfGo37QTYCAAgBJdKVcCBgAGDdzGMyoDydHkjW2iFtyAtG3r2MBNa91Xx8ArXF4AxD0YogCgEhoTWvAzGsLlsVmErjY8uN1ufpDK+aNvAGSaAuP1BusTFOsAgP34kS+cMXh9xGg7RAxGJEsAoPLmetO6bhOAys03J8h7QLyHHgAAiN5AhvUAIUCQpl0y9n8C9DsMgIrx/BnuxOTRFaPPV7LlM+QTvr9g3UTayKrKDF5nPHCrvM9Y/wLkAgu+AdcYtlE4ImnEv185gAcfUBWfm9nH1hsBVhoOCztC1Z9hihDt8KbAtI3wUGXQ9m1a4XlKpRSPV/VyK0sp9e9ayku1+gKwdwBexIOiDRXUYvBEQz9z4omtvqtaoPCBs3fofZVZYOnxeHjMWLsAu+VStlLyCqDFrC7Ycy+pgcmFUniRAkHOqgLcgr0VOBtO3XD0VU+A/X5HKcADB8ias4uUXNg0cM569+aAB0FtKu2JTZei+gpvAgEDr5AAtLhHcCPt+34BQHHL+WjHwTU3hMoQOu2sAsQXkTcoQnciPCl1VEhAkTSANIB5fkZuvMo8CemAtSxPtjmVHvmw4zgsH9kQLjBBZQhZFKb0IHjTBSjtaSGAA/0J4iUNgLO5zAVvs06B5fheOyqqOwCJAVXfwogYLj1JYsrJGAgZ9p1OAEC+GWXtfNOHBOcfc/45S7978/K8hueq+oeMLj89WJZVGtqch0ejtUl45DTPXaAHNBoPEJxh0DQSjJXJfsO28STGCXDbNg9PjJVeNJRZP0krpYXlq2g4JZvMUvVGcwpHLKLHn2tkj6BXolHPleTttnkOavUOLWwcrBXWWVkqc9LYiHQP+pu6BwwhFA4CZOlmKM5w9sDzB87kGSWGaHGKcModcdsRmQceeqRaE/LuAcJZ0JVWPNx8PsQ5JyJKN8Qdz/900tPweuaDZZAYLxXSMzg8Otn9QDje4cTKhEP94qWhBAiVzTZ4yQujTaq4B3Zv0O9uhAzl/EievfmeFIWG0v/CKK+M7+ucUnUA0GJ0NXIFb2xwkP7C/o/Bd29qFh+JHNR8Hpgg4JPL2x3A/2L/gBGrOoDWQOuGoOyUiSF7Hn1M21Prm1cfbNrOgWPmQ9wcrO34PePUOomrwssqk5nEw6q8ZpvILPMJNLFSwb+Ba6KZd/MYeaMfYKHsOicZBjAvqJ5NdU2gOfDHxzVsHGAoe47M9hyZ1yF6gHzifkyqa66d6q+h1f8Hwus3XoUa2M3pMmIAAAAASUVORK5CYII="
                            }
                        }
                    }
                },
                {
                    "__typename": "RatingRecord",
                    "id": "181828222",
                    "product": {
                        "id": "86380782",
                        "productName": "Silk & Spice Red Blend 2021",
                        "slug": "silk-spice-red-blend-2021",
                        "h3SapCode": "000940000100001470",
                        "productBrand": {
                            "image": [
                                {
                                    "image": {
                                        "responsiveImage": null
                                    }
                                },
                                {
                                    "image": {
                                        "responsiveImage": null
                                    }
                                }
                            ]
                        },
                        "_allSlugLocales": [
                            {
                                "locale": "en",
                                "value": "silk-spice-red-blend-2021"
                            },
                            {
                                "locale": "es",
                                "value": "silk-spice-red-blend-2021"
                            },
                            {
                                "locale": "pt",
                                "value": "silk-spice-red-blend-2021"
                            }
                        ]
                    },
                    "mediaPublication": null,
                    "taster": [],
                    "ratingYear": 2023,
                    "rating": null
                }
            ]
        }
    },
    {
        "__typename": "ProductDefinitionRecord",
        "id": "174903076",
        "label": "White Blend",
        "hide": false,
        "customLabel": true,
        "overwriteDefaultData": true,
        "customData": [
            {
                "id": "178738678",
                "typeofcontent": "pdescription",
                "content": {
                    "__typename": "SectionTextRecord",
                    "text": "Fresh and Light White Blend"
                }
            }
        ],
        "showProductAwards": false,
        "geo": [],
        "color": {
            "color": {
                "hex": "#FFFFFF"
            }
        },
        "highlightColor": {
            "color": {
                "hex": "#A7B141",
                "blue": 65,
                "green": 177,
                "red": 167
            }
        },
        "media": [
            {
                "typeofimage": "image",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1690970942-white-blend-and-friends.png?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 151w,https://www.datocms-assets.com/33016/1690970942-white-blend-and-friends.png?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 303w,https://www.datocms-assets.com/33016/1690970942-white-blend-and-friends.png?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 455w,https://www.datocms-assets.com/33016/1690970942-white-blend-and-friends.png?auto=format&crop=focalpoint&fit=crop&q=70 607w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 607px) 100vw, 607px",
                        "src": "https://www.datocms-assets.com/33016/1690970942-white-blend-and-friends.png?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 607,
                        "height": 372,
                        "aspectRatio": 1.631720430107527,
                        "alt": "white blend and friends",
                        "title": "white blend and friends",
                        "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAIAAABxxpiMAAACHklEQVR42j2Ti3bbIBBEgV0ecnPapI///03HCOgdpEQHy0LAZWZYefi6ioVHy49WWy3Fs6eYYghrzTnmnPwvuilFN8tuJXvJ3M1pxlS/KDmFVq3WXGtpBZA7awQCYrAWl0AxAdoIVyvGRIGSHyWYxZrz0eqjtaO2VjJzAKUtaG1Fc4MiS9xR5LVAuUXZBv15f+MJUGvtUSu4W1GUoK1og8KkFy0lKOxTC+Jv1qXo3+93k9h8QKmIwh/j7AJICd2KwgpSlCSnlNyUQr50ZRfo78cvRhk6iuQcpZB15hUg7IhyWZsKyJR0RjMBwGotf4M+fr5doFakiDmVqSz49jXGFfaVtGdSqEV7NuEQlTMb+I/jcEtYqwLdR5aV9PY10khxzkjYMQWSdtxch9uURVFSG6TUL+OcBn1LVJCx+Qoc04jcVtSJzSjU4igR6zEwLSt6VZVAbIwHXKyr4YVFa5/3OMdJ6wN3axKakoqBDcfpDOF6qVmI0Tt9pK9F/bFbhJXs5IB4e3L10TuwtcP27qxWQTFbTnAbUWHD/Pn5SXHsRKfup/eUTEHz3M++29lvEOfAy10NKJThEM4Bxv35fEIePqSf3y5Fsgg8i/Lq3yBE8FVglo6SSyvGwUfKIjL6fL2kCMGqXnQMgRQQoFd/vWCdsj+kiGj09ZKT6RNz4yVpSpE2SLfrtKs5XKW4k5YYKRKIYRwRjfVteP+sZ8qUA/sP7l527w4iCSgAAAAASUVORK5CYII="
                    }
                }
            },
            {
                "typeofimage": "altFeature",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1689785026-hero-image-with-blend.jpg?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 384w,https://www.datocms-assets.com/33016/1689785026-hero-image-with-blend.jpg?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 768w,https://www.datocms-assets.com/33016/1689785026-hero-image-with-blend.jpg?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 1152w,https://www.datocms-assets.com/33016/1689785026-hero-image-with-blend.jpg?auto=format&crop=focalpoint&fit=crop&q=70 1536w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 1536px) 100vw, 1536px",
                        "src": "https://www.datocms-assets.com/33016/1689785026-hero-image-with-blend.jpg?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 1536,
                        "height": 631,
                        "aspectRatio": 2.434231378763867,
                        "alt": "hero image withe blend",
                        "title": "hero image withe blend",
                        "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICAgFCgoFBQwFBQUFBREJCgUMFxMZGBYTFhUaHysjGh0oHRUWJDUlKC0vMjIyGSI4PTcwPCsxMi8BCgsLBQUFEAUFEC8cFhwvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIAAoAGAMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAABf/EABQQAQAAAAAAAAAAAAAAAAAAAAD/xAAWAQADAAAAAAAAAAAAAAAAAAAAAgb/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwC0AljAAD//2Q=="
                    }
                }
            },
            {
                "typeofimage": "bg",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 384w,https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 768w,https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 1152w,https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&fit=crop&q=70 1536w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 1536px) 100vw, 1536px",
                        "src": "https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 1536,
                        "height": 925,
                        "aspectRatio": 1.6605405405405405,
                        "alt": "Silk & Spice - grid",
                        "title": "Silk & Spice - grid",
                        "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPAQMAAADEap2YAAAAA1BMVEXu4ctP1RnlAAAAAXRSTlMAQObYZgAAAA9JREFUeNpjYWBgYCETAwAHvAA9sG+9lQAAAABJRU5ErkJggg=="
                    }
                }
            }
        ],
        "showCertificates": false,
        "product": {
            "__typename": "ProductRecord",
            "id": "86380785",
            "productName": "Silk & Spice White Blend 2021",
            "h3SapCode": "000940000100001477",
            "slug": "silk-spice-white-blend-2021",
            "productBrand": {
                "title": "Silk & Spice"
            },
            "vintageYear": 2021,
            "harvestYear": "Winter was colder and wetter than usual. Spring saw higher average temperatures than normal. The grape clusters appeared in April, which was a particularly wet month. Summer temperatures were hotter than the climatological norm. The harvest started at the end of August and extended into September.",
            "productDescriptionShort": "Silk & Spice White Blend reveals intense notes of ripe peaches, melon and white flowers, as well as hints of vanilla.",
            "sku": [
                {
                    "__typename": "AvailableSkuRecord",
                    "id": "86380635",
                    "skuName": "Silk & Spice White Blend 2021 750mL",
                    "slug": "silk-spice-white-blend-2021-750ml",
                    "capacity": {
                        "capacityValue": "750 mL"
                    },
                    "skuImageGallery": [
                        {
                            "__typename": "FileField",
                            "id": "31269455",
                            "responsiveImage": {
                                "srcSet": "https://www.datocms-assets.com/33016/1672742161-silk-spice-white-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=0.25&fit=crop&h=750&q=70 132w,https://www.datocms-assets.com/33016/1672742161-silk-spice-white-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=0.5&fit=crop&h=750&q=70 265w,https://www.datocms-assets.com/33016/1672742161-silk-spice-white-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=0.75&fit=crop&h=750&q=70 397w,https://www.datocms-assets.com/33016/1672742161-silk-spice-white-blend-2021-750ml.png?auto=format&crop=focalpoint&fit=crop&h=750&q=70 530w,https://www.datocms-assets.com/33016/1672742161-silk-spice-white-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=1.5&fit=crop&h=750&q=70 795w,https://www.datocms-assets.com/33016/1672742161-silk-spice-white-blend-2021-750ml.png?auto=format&crop=focalpoint&dpr=2&fit=crop&h=750&q=70 1060w",
                                "webpSrcSet": "",
                                "sizes": "(max-width: 530px) 100vw, 530px",
                                "src": "https://www.datocms-assets.com/33016/1672742161-silk-spice-white-blend-2021-750ml.png?auto=format&crop=focalpoint&fit=crop&h=750&q=70",
                                "width": 530,
                                "height": 750,
                                "aspectRatio": 0.7066666666666667,
                                "alt": "Silk & Spice White Blend 2021 750mL",
                                "title": "Silk & Spice White Blend 2021",
                                "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAZCAMAAADg4DWlAAABXFBMVEXX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06jX06gFBhFmAAAAdHRSTlMABQgJCw8QERITFxkaGx0eICEiJigrLS8yMzQ3ODk7PD0+P0BBQ0ZHSkxNUVJTVFVWV1hbXV5gYWJnaGlqa25wcXJzdXd4enx/goOEhYaIiouNjo+VlpeZmp+hoqWmqqutrrO0tre5ur2/wsPExczN0tPV1jw0LhIAAACUSURBVHjaXdCxFcMwCARQGzjJ9hDZf69skDQWCDmO0kCu4v13UnGyzOCxv57XPOUnTCzkUYgBSYIbSosidev1naTUXpb/zpGkFhTQSK+wb2hRAIcEWeUOEP5hMDo4iUCzMK/gKMJEJLlzC0VZZyjI8DHGFRfzr3jcp5u59R5Em6lZFD9VraVV9TzMNEpz9546PjPPD26mRM2Mn4TNAAAAAElFTkSuQmCC"
                            }
                        }
                    ]
                }
            ],
            "productCountry": {
                "title": "Portugal"
            },
            "productCategory": null,
            "productRegion": null,
            "productColour": {
                "scale": "White"
            },
            "winemakerBlender": [
                {
                    "title": "Diogo Sepúlveda"
                }
            ],
            "productTypeSpecific": {
                "__typename": "ProductTypeSpecificRecord",
                "slug": "white-wine",
                "id": "45174939",
                "name": "White Wine",
                "_allSlugLocales": [
                    {
                        "locale": "en",
                        "value": "white-wine"
                    },
                    {
                        "locale": "es",
                        "value": "vino-blanco"
                    },
                    {
                        "locale": "pt",
                        "value": "vinho-branco"
                    }
                ]
            },
            "productOrigin": {
                "originDesignation": "Wine with indication of vintage year"
            },
            "ratings": [],
            "awards": [],
            "awardsAndRatings": []
        }
    },
    {
        "__typename": "ProductDefinitionRecord",
        "id": "174903079",
        "label": "Silk Route",
        "hide": false,
        "customLabel": true,
        "overwriteDefaultData": true,
        "customData": [
            {
                "id": "178738679",
                "typeofcontent": "pdescription",
                "content": {
                    "__typename": "SectionTextRecord",
                    "text": "Smooth Red Blend"
                }
            }
        ],
        "showProductAwards": false,
        "geo": [],
        "color": {
            "color": {
                "hex": "#ECE2CF"
            }
        },
        "highlightColor": {
            "color": {
                "hex": "#ED9F6E",
                "blue": 110,
                "green": 159,
                "red": 237
            }
        },
        "media": [
            {
                "typeofimage": "image",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1690971022-silk-route-smooth-red-blend.jpg?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 151w,https://www.datocms-assets.com/33016/1690971022-silk-route-smooth-red-blend.jpg?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 303w,https://www.datocms-assets.com/33016/1690971022-silk-route-smooth-red-blend.jpg?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 454w,https://www.datocms-assets.com/33016/1690971022-silk-route-smooth-red-blend.jpg?auto=format&crop=focalpoint&fit=crop&q=70 606w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 606px) 100vw, 606px",
                        "src": "https://www.datocms-assets.com/33016/1690971022-silk-route-smooth-red-blend.jpg?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 606,
                        "height": 372,
                        "aspectRatio": 1.6290322580645162,
                        "alt": "silk route smooth red blend",
                        "title": "silk route smooth red blend",
                        "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICAgLCgoLDiMQFQ0WDicSHREYHSUZJRYTFhUmHysjGh0oHR0WJDUlKC0vMjIyHSI4PTcwPCsxMi8BCgsLDg0OHA0FGC8oFhw7Ly8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIAA8AGAMBIgACEQEDEQH/xAAXAAADAQAAAAAAAAAAAAAAAAAABQYD/8QAHxAAAgEEAgMAAAAAAAAAAAAAAQQAAgMFEhEhExQi/8QAFQEBAQAAAAAAAAAAAAAAAAAABAP/xAAYEQEAAwEAAAAAAAAAAAAAAAABAAIRIf/aAAwDAQACEQMRAD8A0yeaccZ4NBil2+0KT8mWmWWXVvci2Ii9i1fa8Zo6g0NiKjkhxkXLDZOhhLlnEK1V7aDuEqBkhY7P/9k="
                    }
                }
            },
            {
                "typeofimage": "altFeature",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1689785205-hero-image-silk-route.jpg?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 384w,https://www.datocms-assets.com/33016/1689785205-hero-image-silk-route.jpg?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 768w,https://www.datocms-assets.com/33016/1689785205-hero-image-silk-route.jpg?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 1152w,https://www.datocms-assets.com/33016/1689785205-hero-image-silk-route.jpg?auto=format&crop=focalpoint&fit=crop&q=70 1536w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 1536px) 100vw, 1536px",
                        "src": "https://www.datocms-assets.com/33016/1689785205-hero-image-silk-route.jpg?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 1536,
                        "height": 631,
                        "aspectRatio": 2.434231378763867,
                        "alt": "hero image silk route",
                        "title": "hero image silk route",
                        "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICAgFCgoFBQwFBQUFBREJCgUMFxMZGBYTFhUaHysjGh0oHRUWJDUlKC0vMjIyGSI4PTcwPCsxMi8BCgsLBQUFEAUFEC8cFhwvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIAAoAGAMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAABv/EABQQAQAAAAAAAAAAAAAAAAAAAAD/xAAWAQADAAAAAAAAAAAAAAAAAAAABQb/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCnAIlEAAP/2Q=="
                    }
                }
            }
        ],
        "showCertificates": false,
        "product": {
            "__typename": "ProductRecord",
            "id": "86380786",
            "productName": "Silk & Spice Silk Route Red 2020",
            "h3SapCode": "000940000100001476",
            "slug": "silk-spice-silk-route-2020",
            "productBrand": {
                "title": "Silk Route"
            },
            "vintageYear": 2020,
            "harvestYear": "Winter was warmer and dryer than usual, while spring was wet and hot - flowering occurring at the end of May. In summer, temperatures were above average and there was little rainfall. The harvest started in early September.",
            "productDescriptionShort": "Silk Route celebrates its namesake and is a smooth, ruby-coloured red wine with aromas of red fruit, white chocolate and vanilla.",
            "sku": [
                {
                    "__typename": "AvailableSkuRecord",
                    "id": "86380649",
                    "skuName": "Silk & Spice Silk Route Red 2020 750mL",
                    "slug": "silk-spice-silk-route-red-2020-750ml",
                    "capacity": {
                        "capacityValue": "750 mL"
                    },
                    "skuImageGallery": [
                        {
                            "__typename": "FileField",
                            "id": "31269459",
                            "responsiveImage": {
                                "srcSet": "https://www.datocms-assets.com/33016/1672742321-silk-spice-silk-route-2020-750ml.png?auto=format&crop=focalpoint&dpr=0.25&fit=crop&h=750&q=70 132w,https://www.datocms-assets.com/33016/1672742321-silk-spice-silk-route-2020-750ml.png?auto=format&crop=focalpoint&dpr=0.5&fit=crop&h=750&q=70 265w,https://www.datocms-assets.com/33016/1672742321-silk-spice-silk-route-2020-750ml.png?auto=format&crop=focalpoint&dpr=0.75&fit=crop&h=750&q=70 397w,https://www.datocms-assets.com/33016/1672742321-silk-spice-silk-route-2020-750ml.png?auto=format&crop=focalpoint&fit=crop&h=750&q=70 530w,https://www.datocms-assets.com/33016/1672742321-silk-spice-silk-route-2020-750ml.png?auto=format&crop=focalpoint&dpr=1.5&fit=crop&h=750&q=70 795w,https://www.datocms-assets.com/33016/1672742321-silk-spice-silk-route-2020-750ml.png?auto=format&crop=focalpoint&dpr=2&fit=crop&h=750&q=70 1060w",
                                "webpSrcSet": "",
                                "sizes": "(max-width: 530px) 100vw, 530px",
                                "src": "https://www.datocms-assets.com/33016/1672742321-silk-spice-silk-route-2020-750ml.png?auto=format&crop=focalpoint&fit=crop&h=750&q=70",
                                "width": 530,
                                "height": 750,
                                "aspectRatio": 0.7066666666666667,
                                "alt": "Silk & Spice Silk Route 2020 750mL",
                                "title": "Silk & Spice Silk Route 2020",
                                "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAZCAMAAADg4DWlAAAC/VBMVEVIPC1IPC5JPS9KPjBKPjBLPzFLPzFMQDFNQTNNQTNOQjRPQzVQQzVQQzVQQzVQRDVQRDVRRTdSRjdSRjdSRjhSRjhTRzhTRzlUSDlUSDpUSDpVSTpVSTtVSTtVSTtVSTtWSjtWSjxWSjxWSjxWSjxWSjxWSjxXSjxXSzxXSz1XSz1XSz1YSz1YSz1YTD1YTD1YTD5YTD5ZTD5ZTD5ZTT5ZTT9ZTT9ZTT9ZTT9ZTT9aTT9aTT9aTj9aTkBaTkBaTkBaTkBbTkBbTkBbT0BbT0FbT0FbT0FbT0FbT0FcT0FcUEFcUEJdUEJdUEJdUUJdUUNdUUNdUUNeUUNeUUNeUkNeUkNeUkNeUkReUkReUkReUkRfUkRfUkRfU0VfU0VfU0VgU0VgU0VgU0VgVEZgVEZgVEZhVEZhVUdhVUdiVUdiVkhiVkhiVkhjVkhjV0hjV0hjV0hjV0ljV0ljV0lkV0lkV0lkWElkWEpkWEpkWEpkWEpkWEpkWEplWEplWEplWUplWUtlWUtlWUtmWkxmWkxmWkxnW0xnW01nW01nW01oW01oXE1oXE5oXE5oXE5oXE5oXE5oXE5pXE5pXU5pXU9pXU9qXU9qXk9qXlBqXlBqXlBrXlBrXlBrX1BrX1BrX1BrX1BrX1FsYFFsYFJsYFJsYFJsYFJsYFJtYFJtYVNtYVNtYVNuYVNuYVNuYlRuYlRuYlRuYlRuYlRvYlRvYlRvY1VvY1VvY1VvY1VvY1VwY1VwZFVwZFVwZFZwZFZwZFZwZFZxZVdxZVdxZVdxZVdxZVdyZVdyZldyZldyZlhyZlhyZlhzZlhzZ1hzZ1lzZ1lzZ1l0Z1l0Z1l0aFl0aFp0aFp0aFp0aFp1aFp1aVp1aVp1aVt1aVt1aVt2alt2alx2alx3alx3a1x3a113a113a113a113a114a114bF14bF54bF55bV95bV95bV96bV96bl96bmB6bmB6bmB7b2B7b2F7b2F8b2F8b2F8cGF8cGJ9cWN9cWN9cWN+cmNmoIkbAAAA/3RSTlNEfwBIlgCDAH+aAE8ARIMGiwAAogBIAAAAAIwAAAlPWRAAD0RYhZYAAABQegCPAJYArRaLAAAHYHuhADkAAD1ucACFNABFf5aZAAAAZGWrABgcACoAWqEALWeOAGEAAk4AEXIAobkAAFkAACG0AAAhogAilwBVAAAJIVZtbgBnAABlrABPqQAAGBsAAAAYJku9xAAAAF0AoAApc0atABBfdgAQABBEsLUAAFVwACoADD9AxQAlAC5Sec0AAAsAO3ypABgtZ7YACLUAMLt9AAAwfwDMAAB4f9MAALsALL6xAL4AAAAgb7vSAAAA1gB81bcAADC9AHa8ACcAfgAwey0MpeDNAAABTUlEQVR42hWOy27VMBRFz7G3H7FzkyKYIxjy/9/BkEElpAqhSnRAyY3T+G3S6V57SQvfpeBR0plyh5vXyWAnUoVKyKVxIaoZvxQTUTlz7uo4/Ao8SVYXqvlscPr2Mr0vEqO3nOvQzs0Sj8ZAyZpTqqyc8Rq/jVXWjLbH2K1xTiPmdCPiXHMZe6yZQWy0NQzuo7IWRHgwt2VyXML6WvrlK3zF4pwRKcUPsZPVEp/N6mbLMR1voUDLCd/g/apEvYcQrg8UvtjZe8Up7PtRpTHvlp0sWp6s8V1duVisNpCDR3JyKAuAiQX13lq7EhXBINZRFJe0hXCa+EAdz1Z5wWnf/qZkbV4UnoRWZpzbseVmfJgdfkwS6PH12N7gl+AdfkqtqYf7y5Hk8incZjwKZSmFf6kV3Lf4ccEfIZhaTaU1oTqlA5mkHLWU2sYQ+Qz0H1AVvQmX3Qy5AAAAAElFTkSuQmCC"
                            }
                        }
                    ]
                }
            ],
            "productCountry": {
                "title": "Portugal"
            },
            "productCategory": null,
            "productRegion": null,
            "productColour": {
                "scale": "Red"
            },
            "winemakerBlender": [
                {
                    "title": "Diogo Sepúlveda"
                }
            ],
            "productTypeSpecific": {
                "__typename": "ProductTypeSpecificRecord",
                "slug": "red-wine",
                "id": "45180173",
                "name": "Red Wine",
                "_allSlugLocales": [
                    {
                        "locale": "en",
                        "value": "red-wine"
                    },
                    {
                        "locale": "es",
                        "value": "vino-tinto"
                    },
                    {
                        "locale": "pt",
                        "value": "vinho-tinto"
                    }
                ]
            },
            "productOrigin": {
                "originDesignation": "Wine with indication of vintage year"
            },
            "ratings": [],
            "awards": [],
            "awardsAndRatings": []
        }
    },
    {
        "__typename": "ProductDefinitionRecord",
        "id": "174903082",
        "label": "Spice Road",
        "hide": false,
        "customLabel": true,
        "overwriteDefaultData": true,
        "customData": [
            {
                "id": "178738680",
                "typeofcontent": "pdescription",
                "content": {
                    "__typename": "SectionTextRecord",
                    "text": "Intense Red Blend"
                }
            }
        ],
        "showProductAwards": false,
        "geo": [],
        "color": {
            "color": {
                "hex": "#FFFFFF"
            }
        },
        "highlightColor": {
            "color": {
                "hex": "#A13734",
                "blue": 52,
                "green": 55,
                "red": 161
            }
        },
        "media": [
            {
                "typeofimage": "image",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1690995447-spice-road-intense-red-blend.jpg?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 151w,https://www.datocms-assets.com/33016/1690995447-spice-road-intense-red-blend.jpg?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 303w,https://www.datocms-assets.com/33016/1690995447-spice-road-intense-red-blend.jpg?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 454w,https://www.datocms-assets.com/33016/1690995447-spice-road-intense-red-blend.jpg?auto=format&crop=focalpoint&fit=crop&q=70 606w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 606px) 100vw, 606px",
                        "src": "https://www.datocms-assets.com/33016/1690995447-spice-road-intense-red-blend.jpg?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 606,
                        "height": 373,
                        "aspectRatio": 1.6246648793565683,
                        "alt": "spice road intense red blend",
                        "title": "spice road intense red blend",
                        "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICAgLCg4XDhgNDQ0NDxgOFhEYFxMZGBYVIhUaHysjGh0oHSEWJDUlKC0vMjIyGSI4PTcwPCsxMi8BCgsLDg0OHBAQHDscFhw1Ly8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIAA8AGAMBIgACEQEDEQH/xAAYAAACAwAAAAAAAAAAAAAAAAAEBgEFB//EAB4QAAEDBAMAAAAAAAAAAAAAAAEAAgMEBQYRITFB/8QAFwEAAwEAAAAAAAAAAAAAAAAAAwQFAv/EABcRAAMBAAAAAAAAAAAAAAAAAAECEQD/2gAMAwEAAhEDEQA/AA6qczRnhVlujcK0nXq0R2KxMhJ4QFBj8Taw9dqfGz4kyBldNJPrTSoT/d7FC5+iApWwWGAyi7//2Q=="
                    }
                }
            },
            {
                "typeofimage": "altFeature",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1689785266-hero-image-spice-road.jpg?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 384w,https://www.datocms-assets.com/33016/1689785266-hero-image-spice-road.jpg?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 768w,https://www.datocms-assets.com/33016/1689785266-hero-image-spice-road.jpg?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 1152w,https://www.datocms-assets.com/33016/1689785266-hero-image-spice-road.jpg?auto=format&crop=focalpoint&fit=crop&q=70 1536w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 1536px) 100vw, 1536px",
                        "src": "https://www.datocms-assets.com/33016/1689785266-hero-image-spice-road.jpg?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 1536,
                        "height": 631,
                        "aspectRatio": 2.434231378763867,
                        "alt": "hero image spice road",
                        "title": "hero image spice road",
                        "base64": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICAgFCgoFBQwFBQUFBREJCgUMFxMZGBYTFhUaHysjGh0oHRUWJDUlKC0vMjIyGSI4PTcwPCsxMi8BCgsLBQUFEAUFEC8cFhwvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIAAoAGAMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAABv/EABQQAQAAAAAAAAAAAAAAAAAAAAD/xAAWAQADAAAAAAAAAAAAAAAAAAAABQb/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCZALleAAP/2Q=="
                    }
                }
            },
            {
                "typeofimage": "bg",
                "image": {
                    "responsiveImage": {
                        "srcSet": "https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&dpr=0.25&fit=crop&q=70 384w,https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&dpr=0.5&fit=crop&q=70 768w,https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&dpr=0.75&fit=crop&q=70 1152w,https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&fit=crop&q=70 1536w",
                        "webpSrcSet": "",
                        "sizes": "(max-width: 1536px) 100vw, 1536px",
                        "src": "https://www.datocms-assets.com/33016/1689093853-behind-s-s.png?auto=format&crop=focalpoint&fit=crop&q=70",
                        "width": 1536,
                        "height": 925,
                        "aspectRatio": 1.6605405405405405,
                        "alt": "Silk & Spice - grid",
                        "title": "Silk & Spice - grid",
                        "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPAQMAAADEap2YAAAAA1BMVEXu4ctP1RnlAAAAAXRSTlMAQObYZgAAAA9JREFUeNpjYWBgYCETAwAHvAA9sG+9lQAAAABJRU5ErkJggg=="
                    }
                }
            }
        ],
        "showCertificates": false,
        "product": {
            "__typename": "ProductRecord",
            "id": "86380787",
            "productName": "Silk & Spice Spice Road Red 2020",
            "h3SapCode": "000940000100001475",
            "slug": "silk-spice-spice-road-2020",
            "productBrand": {
                "title": "Spice Road"
            },
            "vintageYear": 2020,
            "harvestYear": "Winter was warmer and drier than usual, while spring was hot and wet. Flowering occurred at the end of May. During summer, temperatures were higher than normal and there was little rainfall. The harvest started in early September.",
            "productDescriptionShort": "Spice Road is a blended red, with an opaque ruby colour, whch has intense aromas of well-ripened black fruit and notes of dark chocolate and spices.",
            "sku": [
                {
                    "__typename": "AvailableSkuRecord",
                    "id": "86380666",
                    "skuName": "Silk & Spice Spice Road Red 2020 750mL",
                    "slug": "silk-spice-spice-road-red-2020-750ml",
                    "capacity": {
                        "capacityValue": "750 mL"
                    },
                    "skuImageGallery": [
                        {
                            "__typename": "FileField",
                            "id": "31269463",
                            "responsiveImage": {
                                "srcSet": "https://www.datocms-assets.com/33016/1672742433-silk-spice-spice-road-2020-750ml.png?auto=format&crop=focalpoint&dpr=0.25&fit=crop&h=750&q=70 132w,https://www.datocms-assets.com/33016/1672742433-silk-spice-spice-road-2020-750ml.png?auto=format&crop=focalpoint&dpr=0.5&fit=crop&h=750&q=70 265w,https://www.datocms-assets.com/33016/1672742433-silk-spice-spice-road-2020-750ml.png?auto=format&crop=focalpoint&dpr=0.75&fit=crop&h=750&q=70 397w,https://www.datocms-assets.com/33016/1672742433-silk-spice-spice-road-2020-750ml.png?auto=format&crop=focalpoint&fit=crop&h=750&q=70 530w,https://www.datocms-assets.com/33016/1672742433-silk-spice-spice-road-2020-750ml.png?auto=format&crop=focalpoint&dpr=1.5&fit=crop&h=750&q=70 795w,https://www.datocms-assets.com/33016/1672742433-silk-spice-spice-road-2020-750ml.png?auto=format&crop=focalpoint&dpr=2&fit=crop&h=750&q=70 1060w",
                                "webpSrcSet": "",
                                "sizes": "(max-width: 530px) 100vw, 530px",
                                "src": "https://www.datocms-assets.com/33016/1672742433-silk-spice-spice-road-2020-750ml.png?auto=format&crop=focalpoint&fit=crop&h=750&q=70",
                                "width": 530,
                                "height": 750,
                                "aspectRatio": 0.7066666666666667,
                                "alt": "Silk & Spice Spice Road 2020 750mL",
                                "title": "Silk & Spice Spice Road 2020",
                                "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAZCAMAAADg4DWlAAABQVBMVEVdOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTddOTfZ9ySEAAAAa3RSTlMAAQIGBwgMDxARFBYYGRobICEiIyUoKSssLTAxMzQ1Njo+QURFR0hJSkxPUFJVVllaXV9gYmVmZ2tsbW9yc3Z3eHp7fX5/gIGCg4mLk5SWmpyeoaKkpqusra+ztLW2ubq8vcDExczN0tPV1pYi2IcAAACYSURBVHjaVdBBDgMhCAXQQT7Upifo/U/WM3QxqYiWdBYFNuqLH6I4fvV49vfr2uJamIloFwEazywqqlW6isgnSWO9n5o749ZVq4hyFUFD5ywkiCophrSSaswKiP2FoyBIQgyKO2dJNRbkWY0COb8rMCzJmmvtTUnm9OXuWczUioxhZsOyxHnayKnh5pZ/1WdMKxIk82p8fAHc4D3m6wKQIwAAAABJRU5ErkJggg=="
                            }
                        }
                    ]
                }
            ],
            "productCountry": {
                "title": "Portugal"
            },
            "productCategory": null,
            "productRegion": null,
            "productColour": {
                "scale": "Red"
            },
            "winemakerBlender": [
                {
                    "title": "Diogo Sepúlveda"
                }
            ],
            "productTypeSpecific": {
                "__typename": "ProductTypeSpecificRecord",
                "slug": "red-wine",
                "id": "45180173",
                "name": "Red Wine",
                "_allSlugLocales": [
                    {
                        "locale": "en",
                        "value": "red-wine"
                    },
                    {
                        "locale": "es",
                        "value": "vino-tinto"
                    },
                    {
                        "locale": "pt",
                        "value": "vinho-tinto"
                    }
                ]
            },
            "productOrigin": {
                "originDesignation": "Wine with indication of vintage year"
            },
            "ratings": [
                {
                    "__typename": "RatingRecord",
                    "id": "181828225",
                    "product": {
                        "id": "86380787",
                        "productName": "Silk & Spice Spice Road Red 2020",
                        "slug": "silk-spice-spice-road-2020",
                        "h3SapCode": "000940000100001475",
                        "productBrand": {
                            "image": []
                        },
                        "_allSlugLocales": [
                            {
                                "locale": "en",
                                "value": "silk-spice-spice-road-2020"
                            },
                            {
                                "locale": "es",
                                "value": "silk-spice-spice-road-2020"
                            },
                            {
                                "locale": "pt",
                                "value": "silk-spice-spice-road-2020"
                            }
                        ]
                    },
                    "mediaPublication": null,
                    "taster": [],
                    "ratingYear": 2023,
                    "rating": null
                }
            ],
            "awards": [],
            "awardsAndRatings": [
                {
                    "__typename": "RatingRecord",
                    "id": "181828225",
                    "product": {
                        "id": "86380787",
                        "productName": "Silk & Spice Spice Road Red 2020",
                        "slug": "silk-spice-spice-road-2020",
                        "h3SapCode": "000940000100001475",
                        "productBrand": {
                            "image": []
                        },
                        "_allSlugLocales": [
                            {
                                "locale": "en",
                                "value": "silk-spice-spice-road-2020"
                            },
                            {
                                "locale": "es",
                                "value": "silk-spice-spice-road-2020"
                            },
                            {
                                "locale": "pt",
                                "value": "silk-spice-spice-road-2020"
                            }
                        ]
                    },
                    "mediaPublication": null,
                    "taster": [],
                    "ratingYear": 2023,
                    "rating": null
                }
            ]
        }
    }
]

*/