import { LazyMotion, domAnimation, m } from "framer-motion";
import styles from "./index.module.scss";
import { GalleryImage } from "@/molecules/ImageCard";
import { Paragraph, ImageLabel } from "@/atoms/Paragraph";
import { Shape } from "@/components/Atoms/Spacer";
import { FeaturedTitle, Title } from "@/components/Atoms/Title";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import ImageSlider from "@/components/Molecules/ImageSlider";
import { GalleryText, MultiTextComponent } from "@/components/Atoms/MultiText";

export default function ImageGallery(data: any) {
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        className="relative"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        exit="exit"
      >
        {data.addSpacer && (
          <Shape
            shape={data.spacerConfiguration?.customShape}
            color={data.bgColor}
            direction={data.spacerConfiguration?.spacerDirection}
            icon={[
              data.spacerConfiguration?.addIconElement,
              data.spacerConfiguration?.spacerIcon,
            ]}
            position={data.spacerConfiguration?.spacerPosition}
            invert={false}
          />
        )}
        <div
          style={{ backgroundColor: data?.bgColor?.color.hex }}
          className={`${data.useSectionMargins ? "py-12" : "py-0"}  ${
            data.useExtraBottomMargins ? "md:pt-12 md:pb-32" : ""
          } flex  gap-6 ${
            data.placeTextFirst ? "flex-col-reverse" : "flex-col"
          } `}
        >
          <div
            className={` flex w-full  flex-col gap-4 justify-center mx-auto relative  ${
              data.layout == "FullWidth" ? "pb-2 " : "px-6 md:px-0 mx-auto"
            }`}
          >
            {data.layout == "FullWidth" ? (
              <>
                <div className="grid grid-cols-2 md:flex flex-row gap-2 px-6 md:px-0 mx-auto">
                  {data.gallery.map((entry: any, i: number) => (
                    <div
                      key={i}
                      className={`${styles.galleryImage} h-full object-cover`}
                    >
                      <GalleryImage
                        options={data.contentBorderRadius}
                        data={entry}
                      />
                      {data.showImageLabel && !data.useSingleLabel && (
                        <ImageLabel
                          copy={entry?.title}
                          color={data.labelColor?.color}
                        />
                      )}
                    </div>
                  ))}
                </div>
                {data.showImageLabel && data.useSingleLabel && (
                  <ImageLabel
                    className="text-center"
                    copy={data.imageLabel}
                    color={data.labelColor?.color}
                  />
                )}
              </>
            ) : data.layout == "Container" ? (
              <div className="grid grid-cols-2 md:flex flex-row container gap-2 mx-auto">
                {data.gallery?.map((entry: any, i: number) => {
                  const style = {
                    "--ar": entry.responsiveImage.aspectRatio,
                  } as React.CSSProperties;

                  return (
                    <div
                      style={style as React.CSSProperties}
                      className={`${styles.galleryImage} flex-1`}
                      key={i}
                    >
                      <GalleryImage
                        options={data.contentBorderRadius}
                        data={entry}
                      />
                      {data.showImageLabel && !data.useSingleLabel && (
                        <ImageLabel
                          copy={entry?.title}
                          color={data.labelColor?.color}
                        />
                      )}
                    </div>
                  );
                })}
                {data.showImageLabel && data.useSingleLabel && (
                  <ImageLabel
                    className="text-center"
                    copy={data.imageLabel}
                    color={data.labelColor?.color}
                  />
                )}
              </div>
            ) : data.layout == "Slider" ? (
              <>
                {data.swapImageGalleryForCardGallery ? (
                  <ImageSlider
                    breakDesktop={3}
                    sliderClass="px-[5px]"
                    data={data}
                  >
                    {data.galleryCard.map((entry: any, i: number) => {
                      return (
                        <div
                          className={`${styles.bgCard} flex-auto shadow-lg `}
                          key={i}
                        >
                          <GalleryImage
                            options={data.contentBorderRadius}
                            data={entry.asset}
                          />
                          <div className={` flex flex-col p-6`}>
                            <GalleryText
                              data={entry.description}
                              color={data.textColor?.color}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </ImageSlider>
                ) : (
                  <ImageSlider
                    breakDesktop={3}
                    sliderClass="px-[5px]"
                    data={data}
                  >
                    {" "}
                    {data.gallery.map((entry: any, i: number) => {
                      const style = {
                        "--ar": entry.responsiveImage.aspectRatio,
                      } as React.CSSProperties;
                      return (
                        <div
                          style={style as React.CSSProperties}
                          className={`lg:h-[15rem] xl:h-[25rem] flex-auto`}
                          key={i}
                        >
                          <GalleryImage
                            options={data.contentBorderRadius}
                            data={entry}
                          />
                        </div>
                      );
                    })}
                  </ImageSlider>
                )}
              </>
            ) : (
              <div className="max-w-[1360px] mx-auto grid grid-cols-2 mb-20 gap-y-6">
                {data.title && (
                  <div className="flex flex-col w-fit lg:col-start-2 col-span-2 lg:col-span-1">
                    <FeaturedTitle
                      layout="left"
                      color={data.textColor}
                      tag="h4"
                      data={data.title}
                    />
                    {data.addTitleUnderline && (
                      <>
                        {data.customUnderlineAsset?.img ? (
                          <CustomUnderline
                            shape={data.customUnderlineAsset?.img}
                            color={data.underlineColor?.color}
                          />
                        ) : (
                          <Underline color={data.underlineColor?.color} />
                        )}
                      </>
                    )}
                  </div>
                )}
                <div className="flex flex-wrap gap-4 lg:col-start-2 col-span-2 lg:col-span-1">
                  {data.gallery.map((entry: any, i: number) => {
                    const style = {
                      "--ar": entry.responsiveImage.aspectRatio,
                    } as React.CSSProperties;
                    return (
                      <div
                        style={style as React.CSSProperties}
                        className={`${styles.galleryImageHalf} ${
                          data.gallery.length > 1
                            ? "lg:h-[15rem] xl:h-[20rem]"
                            : ""
                        } flex-auto`}
                        key={i}
                      >
                        <GalleryImage
                          options={data.contentBorderRadius}
                          data={entry}
                        />
                        {data.showImageLabel && !data.useSingleLabel && (
                          <ImageLabel
                            copy={entry?.title}
                            color={data.textColor?.color}
                          />
                        )}
                      </div>
                    );
                  })}
                  {data.showImageLabel && data.useSingleLabel && (
                    <ImageLabel
                      className="text-center"
                      copy={data.imageLabel}
                      color={data.textColor?.color}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          {data.addText && (
            <div className="w-full px-6 lg:px-0 container mx-auto ">
              <article
                className={`mx-auto container flex flex-col gap-12  pb-6 lg:pb-10  2xl:max-w-[1360px]`}
              >
                {data.title && (
                  <div className="flex flex-col w-fit gap-4">
                    <Title
                      tag="h3"
                      data={data.title}
                      color={data.titleColor?.color || data.textColor?.color}
                      layout={"left"}
                    />

                    {data.addTitleUnderline && (
                      <>
                        {data.customUnderlineAsset?.img ? (
                          <CustomUnderline
                            shape={data.customUnderlineAsset?.img}
                            color={data.underlineColor?.color}
                          />
                        ) : (
                          <Underline color={data.underlineColor?.color} />
                        )}
                      </>
                    )}
                  </div>
                )}
                <div
                  className={`${
                    data.textColumnLayout !== "single"
                      ? `${styles.doubleColumn}`
                      : `${styles.singleColumn}`
                  }`}
                >
                  <MultiTextComponent
                    columns={data.textColumnLayout}
                    data={data.textContent}
                    color={data.textColor?.color}
                    layout={data.textAlignment}
                  />
                </div>
                {data.file?.url && (
                  <div className="text-center">
                    <Paragraph
                      copy={data.downloadDescription}
                      color={data.textColor?.color}
                      column={data.textColumnLayout}
                      layout={data.textAlignment}
                    />
                    {data.file?.url && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={data.file?.url}
                        download={data.downloadDescription}
                      >
                        <button
                          style={{ color: data.textColor?.color.hex }}
                          className={` m-auto flex flex-row items-center gap-2  py-2 px-4  font-secondary text-sm lg:text-base  mb-12 lg:mb-0 font-primary md:min-w-[10rem] justify-center`}
                        >
                          {data.downloadLabel.label}
                        </button>
                      </a>
                    )}
                  </div>
                )}
              </article>
            </div>
          )}
        </div>
      </m.div>
    </LazyMotion>
  );
}

export const fragment = `
    fragment SectionGalleryFragment on SectionGalleryRecord{
        __typename
        id
        title
        layout
        placeTextFirst
        addTitleUnderline
        customUnderlineAsset {
          img {
            url
            width
            height
          }
        }
        underlineColor {
          color {
            hex
          }

        }
        textAlignment
        addText
        textColor{
          color{
            hex
          }
        }
        labelColor{
          color{
            hex
          }
        }
        bgColor{
          color{
            hex
          }
        }
        addSpacer
        spacerConfiguration {
          id
          spacerDirection
          spacerPosition
          customShape {
            img {
              alt
              url
              width
              height
            }
          }
        }
        showImageLabel
        useSingleLabel
        imageLabel
        contentBorderRadius
        addDownloadBlock
        downloadDescription
        downloadLabel {
          label
        }
        file {
          url
          filename
        }
        textContent(markdown: true)
        textColumnLayout
        useSectionMargins
        useExtraBottomMargins
        swapImageGalleryForCardGallery
        galleryCard {
          id
          description
          asset {
            responsiveImage (imgixParams: { fit: crop, crop: focalpoint,  auto:format, q:70}) {
              ... responsiveImageFragment
            }
          }
        }
        gallery {
          title
          responsiveImage (imgixParams: { fit: crop, crop: focalpoint,  auto:format, q:70}) {
           ... responsiveImageFragment
          }
        }
    }
`;
