"use client";

import { Paragraph } from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";
import { TimelineIcon } from "@/components/Atoms/Vectors";
import Image from "@/components/Atoms/Image";
import { useState } from "react";
import RangeSlider from "@/components/Atoms/RangeSlider";

interface TimelineRangerProps {
	title: string;
	titleColor: any;
	yearColor: any;
	textColor: any;
	historyMoments: any[];
}

export const TimelineRange = ({
	historyMoments,
	textColor,
	title,
	titleColor,
	yearColor,
}: TimelineRangerProps) => {
	const [historyMoment, setHistoryMoment] = useState(
		historyMoments ? historyMoments[0] : false
	);

	const [rangeValue, setRangeValue] = useState(0);

	/* const getClosestItem = (year: number) => {
		return data.historyMoments.find((item: any) => parseInt(item.year) >= year);
	}; */

	const onRangeChange = (e: any) => {
		const index = parseInt(e.target.value);
		const selectedMoment = historyMoments[index];

		if (selectedMoment) {
			setRangeValue(index);
			setHistoryMoment(selectedMoment);
		}
	};
	return (
		<div className="flex flex-col gap-4 mx-10 md:flex md:flex-row md:gap-8">
			<div className="flex flex-col gap-6 lg:gap-14 w-full md:w-1/3">
				<Title tag="h3" data={title} color={titleColor || textColor} />
				<div className="flex flex-col gap-6 max-w-96">
					{historyMoment.addRangeOfYears ? (
						<h3
							className="brandTimeline text-timelineYear lg:text-timelineYear_lg xl:text-timelineYear_xl"
							style={{ color: yearColor.hex }}
						>
							{historyMoment.rangeYear}
						</h3>
					) : (
						<h3
							className="brandTimeline text-timelineYear lg:text-timelineYear_lg xl:text-timelineYear_xl"
							style={{ color: yearColor.hex }}
						>
							{historyMoment.year}
						</h3>
					)}
					<Paragraph color={textColor} copy={historyMoment.description} />
				</div>
			</div>
			<div className="flex flex-col items-center gap-16 w-full mt-0 md:mt-20 lg:mt-40 md:w-2/3">
				<div className="relative w-full max-w-[608px]">
					<TimelineIcon className="absolute" moments={historyMoments} />
					<RangeSlider
						className="absolute w-full left-2 md:left-2 lg:left-0 -bottom-14 md:-bottom-14 lg:-bottom-7"
						min="0"
						max={(historyMoments.length - 1).toString()}
						value={rangeValue}
						onChange={onRangeChange}
					/>
				</div>
				<Image data={historyMoment.image} className="w-full" />
			</div>
		</div>
	);
};
