import Link from "next/link";
import { useRouter } from "next/router";

export default function CustomLink(props) {
  const router = useRouter();
  const regex = new RegExp("http(.?):\\/\\/", "gm");
  if (!props.href) {
    return <a {...props}>{props.children}</a>;
  }

  if (props.external || regex.test(props.href)) {
    return (
      <a {...props} rel="noopener noreferrer" target="_blank">
        {props.children}
      </a>
    );
  }

  return (
    <Link
      href={props.href}
      locale={props.locale || router.locale}
      passHref={true}
      target={props.target}
    >
      <a {...props}> {props.children}</a>
    </Link>
  );
}
