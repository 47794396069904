import { string_to_slug } from "@/lib/functions";
import Link from "next/link";

import styles from "./index.module.scss";

interface Props {
	data: any;
	color: any;
	type: string;
	styleColor: any;
	bg: any;
}

export function HeroAnchors({ data, color, type, styleColor, bg }: Props) {
	const anchorExecuteScroll = (e: any) => {
		e.preventDefault();
		document
			.getElementById(e.currentTarget.hash.slice(1))!
			.scrollIntoView({ behavior: "smooth" });
	};
	const style = {
		"--color": color?.hex,
		"--styleColor": styleColor?.hex,
		"--bg": bg?.hex,
	} as React.CSSProperties;
	if (data?.length < 1) {
		return null;
	} else {
		return (
			<nav style={{ backgroundColor: bg?.hex }} className="overflow-auto">
				{type === "bFullRounded" ? (
					<>
						<ul
							className={`px-6 md:pl-0 flex flex-row flex-nowrap gap-6 justify-start md:justify-center pt-14 pb-6  cursor-pointer`}
						>
							{data.map((anchor: any, i: number) => (
								<li
									style={style as React.CSSProperties}
									className={`px-4 py-2 border rounded font-primary text-sm flex-none ${styles.roundedAnchor}`}
									key={i}
								>
									<Link href={`./#${string_to_slug(anchor.title)}`}>
										<a
											style={style as React.CSSProperties}
											onClick={anchorExecuteScroll}
										>
											{anchor.title}
										</a>
									</Link>
								</li>
							))}
						</ul>
					</>
				) : (
					<>
						<ul
							className={`px-6 md:pl-0 flex flex-row flex-nowrap gap-6 justify-start md:justify-center pt-12 pb-6 cursor-pointer`}
						>
							{data.map((anchor: any, i: number) => (
								<li
									style={style as React.CSSProperties}
									className={`px-4 py-2 text-sm md:text-md lg:text-lg font-primary flex-none ${styles.roundedAnchor}`}
									key={i}
								>
									<Link href={`./#${string_to_slug(anchor.title)}`}>
										<a
											style={style as React.CSSProperties}
											onClick={anchorExecuteScroll}
										>
											{anchor.title}
										</a>
									</Link>
								</li>
							))}
						</ul>
					</>
				)}
			</nav>
		);
	}
}
