import { useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import styles from "./index.module.scss";
import { AccoladeSelectedFiltersProps } from "..";

interface Props {
  filters: any;
  products: any;
  config: any;
  onFilterChange: (category: string, value: string, type: string) => void;
  selectedFilters: AccoladeSelectedFiltersProps;
}

//SELECT DYNAMICFILTER COMPONENT//

const DynamicFilter = ({
  filters,
  products,
  config,
  onFilterChange,
  selectedFilters,
}: Props) => {
  switch (filters.__typename) {
    case "AwardsFilterWineRecord":
      return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {" "}
          {filters.field.map((entry: any, i: number) => {
            if (entry == "country") {
              const flattenedArray = products.flatMap(
                (item: any) => item?.product.productCountry?.title
              );
              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            } else if (entry == "productTypeSpecific") {
              const flattenedArray = products.flatMap(
                (item: any) => item?.product.productTypeSpecific.name
              );
              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            } else if (entry == "year") {
              const flattenedArray = products.flatMap(
                (item: any) => item?.product.vintageYear
              );
              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            } else if (entry == "productColour") {
              const flattenedArray = products.flatMap(
                (item: any) => item?.product.productColour?.scale
              );
              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            } else if (entry == "winemaker") {
              const flattenedArray = products.flatMap((item: any) =>
                item?.product.winemakerBlender.map((y: any) => y.title)
              );
              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            } else if (entry == "productCategory") {
              const flattenedArray = products.flatMap(
                (item: any) => item?.product.productCategory
              );

              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            }
          })}
        </div>
      );

      break;
    default:
      return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {" "}
          {filters.field.map((entry: any, i: number) => {
            if (entry == "awards") {
              const flattenedArray = products.flatMap(
                (item: any) => item?.product.awards
              );

              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            } else if (entry == "contest") {
              const flattenedArray = products.flatMap((item: any) =>
                item?.product.awards.map((y: any) => y.wineContest.title)
              );
              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            } else if (entry == "ratings") {
              const flattenedArray = products.flatMap((item: any) =>
                item?.product.ratings
                  .map((z: any) => z.rating?.ratingValue)
                  .filter(Boolean)
              );

              const scale = products.flatMap((item: any) =>
                item?.product.ratings.map((z: any) => z?.scale).filter(Boolean)
              );
              const filterScale = Array.from(new Set(scale));
              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              const ranges: any = [];
              filterScale.map((scaleValue: any) => {
                if (scaleValue == "0-100") {
                  // set range of values 90-100

                  const lowerBound = 90;
                  const upperBound = 100;

                  const hasRatingsInRange = filterValues.some(
                    (rating: any) =>
                      rating >= lowerBound && rating <= upperBound
                  );

                  if (hasRatingsInRange) {
                    ranges.push(`${lowerBound}-${upperBound}`);
                  }
                  return ranges;
                } else {
                  // set range of values 90-100

                  const lowerBound = 15;
                  const upperBound = 20;

                  const hasRatingsInRange = filterValues.some(
                    (rating: any) =>
                      rating >= lowerBound && rating <= upperBound
                  );

                  if (hasRatingsInRange) {
                    ranges.push(`${lowerBound}-${upperBound}`);
                  }
                  return ranges;
                }
              });

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    parent={parent}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={ranges}
                  />
                );
              }
            } else if (entry == "mediaPublication") {
              const flattenedArray = products.flatMap((item: any) =>
                item?.product.ratings.map((y: any) => y.mediaPublication?.title)
              );
              // Remove duplicates
              const filterValues = Array.from(new Set(flattenedArray));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    parent={parent}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            } else if (entry == "taster") {
              const flattenedArray = products.flatMap((item: any) =>
                item?.product.ratings.map((y: any) =>
                  y.taster.map((z: any) => z.title)
                )
              );
              // Remove empty arrays
              let filterValues = Array.from(
                new Set(
                  flattenedArray.filter((subArray: any) => subArray.length > 0)
                )
              ).map((item: any) => item[0]);

              // remove duplicates
              filterValues = Array.from(new Set(filterValues));

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    parent={parent}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            } else if (entry == "year") {
              const flattenedArrayRatings = products.flatMap((item: any) =>
                item?.product.awardsAndRatings.map((y: any) => y?.ratingYear)
              );

              const flattenedArrayAwards = products.flatMap((item: any) =>
                item?.product.awardsAndRatings.map((y: any) => y?.contestYear)
              );


              // Remove duplicates
              const filterValues = Array.from(
                new Set([...flattenedArrayRatings, ...flattenedArrayAwards])
              );

              entry = "accoladeYear";

              if (filterValues[0] == null) {
                return null;
              } else {
                return (
                  <SelectFiter
                    type={filters.__typename}
                    parent={parent}
                    key={i}
                    config={config}
                    onSelection={onFilterChange}
                    selectedValue={selectedFilters[entry]}
                    entry={entry}
                    values={filterValues}
                  />
                );
              }
            }
          })}
        </div>
      );
      break;
  }
};

export default DynamicFilter;

interface SelectFiter {
  entry: string;
  config: any;
  values: any;
  sub?: boolean;
  onSelection: (category: string, value: string, type: string) => void;
  selectedValue?: any;
  parent?: any;
  type: string;
}

//SELECT FILTER CHILD COMPONENT//

const SelectFiter = ({
  entry,
  values,
  sub,
  config,
  parent,
  type,
  onSelection,

  selectedValue,
}: SelectFiter) => {
  const style = {
    "--filterBg": config?.filterBarBgColor?.color.hex,
    "--label": config?.labelColor?.color?.hex,
    "--textColor": config?.textColor?.color?.hex,
  } as React.CSSProperties;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (selectedValue === undefined) {
      // Reset local state if the selectedValue is removed
      handleSelectOption(entry);
    }
  }, [selectedValue, entry]);

  const handleSelectOption = (value: string) => {
    onSelection(entry, value, type);
    setIsOpen(false);
  };

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      style={style}
      className={` ${styles.filterSelect} ${sub ? "ml-6  text-xs" : "text-xs md:text-sm"
        } relative border-b font-secondary`}
      ref={dropdownRef}
    >
      <div className="">
        <button
          id=""
          className="w-full text-left pb-1 "
          onClick={toggleDropdown}
        >
          {t(selectedValue)}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.1812 0.317645C10.5933 -0.103512 11.2611 -0.103512 11.6874 0.303606C11.9005 0.514185 12 0.780917 12 1.06169C12 1.32842 11.9005 1.59515 11.6874 1.79169L6.75666 6.69115C6.55773 6.88769 6.28774 7 6.00355 7C5.71936 7 5.44938 6.88769 5.25044 6.69115L0.319714 1.79169C-0.106573 1.38457 -0.106573 0.724763 0.319715 0.303606C0.731792 -0.103513 1.41385 -0.103513 1.82593 0.317644L6.00355 4.45902L10.1812 0.317645Z"
                fill="#653A17"
              />
            </svg>
          </span>
        </button>
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white shadow-lg mt-1 max-h-60 overflow-auto">
            <li
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelectOption("all")}
            >
              {t("all")}
            </li>
            {values.map((item: any, index: number) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSelectOption(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
