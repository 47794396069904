import { Label } from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";
import { useRouter } from "next/router";

import ArticleBlock from "@/components/Molecules/ArticleBlock";
import { string_to_slug } from "@/lib/functions";
import Link from "next/link";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { ButtonMaster } from "@/components/Atoms/Buttons";

export default function SectionBrandArticles(data: any) {
	const ARTICLES_TO_LOAD_MORE = 4;
	const articleTotal = data.relations.allArticles.length;

	const router = useRouter();
	const { t } = useTranslation();

	const [newsPage, SetPage] = useState(router.query.slug);
	const [articlesToShow, setArticlesToShow] = useState(4);

	const anchorExecuteScroll = (e: any) => {
		e.preventDefault();
		document
			.getElementById(e.currentTarget.hash.slice(1))!
			.scrollIntoView({ behavior: "smooth" });
	};

	const handleLoadMore = () => {
		setArticlesToShow((prevCount) => prevCount + ARTICLES_TO_LOAD_MORE);
	};

	const displayedArticles = data.relations.allArticles.slice(0, articlesToShow);

	const pinPosts = data.relations.allArticles
		?.filter((x: any) => Number(x.pinPost) == 1)
		.slice(0, 3);

	return (
		<div
			style={{ backgroundColor: data.bgColor?.color.hex }}
			id={string_to_slug(data.title)}
			className="w-full"
		>
			<header className="relative flex flex-col items-center gap-4 py-12">
				{newsPage?.length! <= 1 && (
					<>
						{data.additionalContent == "anchor" && (
							<div className="flex flex-wrap flex-row gap-4 md:gap-10 pb-6">
								{data.blocksToAnchor.map((anchor: any, i: number) => (
									<Link href={`./#${string_to_slug(anchor.title)}`} key={i}>
										<a onClick={anchorExecuteScroll}>
											<Label
												copy={anchor.title}
												color={data.excerptColor.color}
											/>
										</a>
									</Link>
								))}
							</div>
						)}
					</>
				)}

				<div
					className={`flex flex-col gap-4  ${
						data.textAlignment === "default" ? "items-center" : ""
					} 2xl:max-w-[1360px]`}
				>
					<Title
						tag={"h3"}
						data={
							newsPage?.length! <= 1
								? `${data.title}`
								: `${t("relatedArticles")} `
						}
						color={data.headerColor?.color}
					/>
					<ArticleBlock
						layout={data.layout}
						data={data.relations.allArticles}
						pinPosts={pinPosts}
						color={data.articleTitleColor}
						excerpt={data.excerptColor}
						cta={data?.ctaConfig}
						highlight={data?.highlightColor}
						load={data?.articlesToShow}
						layoutCard={data.layoutArticleCard}
						hideLabel={data.hideTagLabel}
					/>

					{data.ctaSectionConfig &&
						articleTotal > 4 &&
						articleTotal > articlesToShow && (
							<ButtonMaster
								anim={"slideLeft"}
								type={data.ctaSectionConfig[0]?.buttonStyle}
								cta={data.ctaSectionConfig[0]?.typeColor?.color}
								color={data.ctaSectionConfig[0]?.labelColor?.color}
								customIcon={data.ctaSectionConfig[0]?.icon?.img}
								customColor={data.ctaSectionConfig[0]?.iconColor?.color}
								onClick={handleLoadMore}
							>
								{data?.ctaSectionConfig[0]?.buttonLabel?.label}
							</ButtonMaster>
						)}
				</div>
			</header>
		</div>
	);
}

export const query = `
    query BrandArticlesByCategory($locale: SiteLocale = en, $websiteConfiguration  : [ItemId],  $tagsToShow: [ItemId], $datefrom: DateTime = "01-01-2000", $dateto: DateTime = "01-01-2100") {
      allArticles (filter: { websiteRelated: {eq: $websiteConfiguration }, categories: { allIn: $tagsToShow },  _publishedAt: {gte: $datefrom, lte: $dateto} }, orderBy: _firstPublishedAt_DESC, locale: $locale) {
            __typename
            title
            slug
            excerpt
            _publishedAt
            categories {
           title
            }
            pinPost
            image{
              responsiveImage { 
                ... responsiveImageFragment
              }
            }
            content {
              ... on TextRecord {
                id
                text
              }
              ... on VideoRecord {
                id
                thumbTime
                loop
                layout
                autoplay
                video {
                  url
                  alt
                }
              }
            }
        }
    }
`;

export const variables = (data: any) => {
	return {
		websiteConfiguration: data.websiteConfiguration.id,
	};
};

export const fragment = `
    fragment SectionBrandArticleFragment on SectionBrandArticleRecord{
        __typename
        title
        description
        articlesToShow
        websiteConfiguration {
          id
        }
        additionalContent
          blocksToAnchor {
            ... on SectionBrandArticleRecord {
          title
            }
            ... on SectionBrandRecipeRecord {
            title
            
            }
        }
        layout
        layoutArticleCard
		textAlignment
        hideTagLabel
        headerColor {
          color {
            hex
          }
        }
        highlightColor {
          color {
            hex
          }
        }
        bgColor {
          color{
            hex
          }
        }
        articleTitleColor {
          color {
            hex
          }
        }
        excerptColor {
          color {
            hex
          }
        }
        ctaConfig {
          ...pageRelatedFragment
        }
        ctaSectionConfig {
          ...pageRelatedFragment
        }
  }
`;
