import React, { useRef, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { motion, LazyMotion, domAnimation } from "framer-motion";
import { resize } from "@/lib/functions";
import { ImageCopyCard } from "@/components/Molecules/HeroCopyCard";
import { VideoCard } from "@/components/Molecules/VideoCard";
import { OverlayShadow } from "@/components/Molecules/OverlayShadow/Overlay";
import { cva, VariantProps } from "class-variance-authority";
import { PlayIcon } from "@/components/Atoms/Vectors";
import { Shape } from "@/components/Atoms/Spacer";
import { PageScroll } from "@/components/Atoms/PageScroll";
import { IconGallery } from "@/components/Molecules/IconGallery";

export type HeroVideoVariantProps = VariantProps<typeof HeroVideoVariants>;
const HeroVideoVariants = cva(` relative flex flex-col `, {
	variants: {
		heroHeight: {
			small: `min-h-[200px] lg:min-h-[500px] pt-[50px] lg:pt-[100px] `,
			tall: ` items-center min-h-[75vh] md:h-[800px]`,
			content: `pt-24`,
			default: `min-h-[60vh] md:min-h-[90vh] lg:min-h-[100vh]`,
		},
		textFlexPosition: {
			start: `justify-start pt-24`,
			center: ` justify-center`,
			end: `justify-end`,
		},
	},
	defaultVariants: {
		heroHeight: "default",
		textFlexPosition: "center",
	},
});

export interface HeroVideoProps
	extends React.HTMLAttributes<HTMLHeadingElement>,
		HeroVideoVariantProps {
	color?: any;
	id: string;
	overlay: any;
	addScrollIcon: boolean;
	scrollIcon: any;
	imageOverlayBaseColor: any;
	__typename: string;
	video: any;
	fontColor: any;
	loop: boolean;
	muted: boolean;
	controls: boolean;
	videoPreview: any;
	autoplay: boolean;
	textFlexPosition: any;
	title: string;
	description: string;
	label: string;
	swapTitleForImage: boolean;
	useLabelBeforeTitle: boolean;
	imageReplacer: any;
	textColor: any;
	textWidth: string;
	textAlignment: string;
	posterImage: any;
	addSpacer: boolean;
	spacerConfiguration: any;
	bgColor: any;
	playIcon: any;
}

export default function HeroVideo({
	id,
	overlay,
	imageOverlayBaseColor,
	__typename,
	video,
	fontColor,
	loop,
	muted,
	addScrollIcon,
	scrollIcon,
	controls,
	videoPreview,
	autoplay,
	textFlexPosition,
	title,
	description,
	label,
	swapTitleForImage,
	useLabelBeforeTitle,
	imageReplacer,
	textColor,
	textWidth,
	heroHeight,
	textAlignment,
	posterImage,
	addSpacer,
	spacerConfiguration,
	bgColor,
	playIcon,
}: HeroVideoProps) {
	const [playing, setPlaying] = useState(false);

	const togglePlay = () => {
		setPlaying(!playing);
	};

	const scrollRef: any = useRef(null);
	const executeScroll = () =>
		scrollRef.current.scrollIntoView({
			block: "start",
			behavior: "smooth",
			inline: "start",
		});

	return (
		<LazyMotion features={domAnimation}>
			<motion.div
				variants={resize()}
				animate={playing ? "visible" : "hidden"}
				viewport={{ once: true }}
				exit="exit"
				id={id}
				className={HeroVideoVariants({ heroHeight, textFlexPosition })}
			>
				<div className={` top-0 left-0 absolute w-full h-full`}>
					{!playing && (
						<>
							{overlay && (
								<OverlayShadow
									format={__typename}
									data={overlay}
									color={imageOverlayBaseColor}
									layout={""}
								/>
							)}
						</>
					)}

					{video && (
						<VideoCard
							bgColor={imageOverlayBaseColor}
							placement={__typename}
							poster={posterImage}
							onClick={() => togglePlay()}
							color={fontColor?.hex}
							file={video.video}
							controls={[loop, muted, controls, videoPreview, autoplay]}
							className={""}
						/>
					)}
				</div>
				{!playing && (
					<div className="relative flex flex-col w-full h-fit items-start">
						<ImageCopyCard
							flex={textFlexPosition}
							title={title}
							description={description}
							label={label}
							swapTitle={swapTitleForImage}
							labelPosition={useLabelBeforeTitle}
							imageReplacer={imageReplacer}
							color={textColor?.color}
							layout={textAlignment}
							width={textWidth}
							underline={undefined}
						/>
						{controls && (
							<div
								className={` ${
									textAlignment == "center" ? "justify-center" : "left"
								} container mx-auto px-6 md:px-12 lg:px-16  z-[1000] relative flex`}
							>
								<button
									onClick={() => togglePlay()}
									aria-label="video play"
									className={` ${
										__typename === "SectionHeroVideoRecord"
											? ""
											: "justify-center lg:justify-end bottom-0 right-12 lg:right-0 lg:bottom-12 m-auto h-fit w-fit flex flex-row gap-8 md:gap-12 "
									}   z-10 xs:scale-75 lg:scale-150`}
									id={video.video.muxAssetId}
									type="button"
									data-state="play"
								>
									{!playing ? (
										<PlayIcon
											custom={playIcon?.img}
											color={textColor?.color?.hex}
										/>
									) : (
										<></>
									)}
								</button>
							</div>
						)}
					</div>
				)}
				{!playing && (
					<>
						{addSpacer && (
							<Shape
								shape={spacerConfiguration?.customShape}
								color={bgColor}
								direction={spacerConfiguration?.spacerDirection}
								icon={[
									spacerConfiguration?.addIconElement,
									spacerConfiguration?.spacerIcon,
								]}
								position={spacerConfiguration?.spacerPosition}
								invert={false}
							/>
						)}
						{addScrollIcon && (
							<div ref={scrollRef}>
								<PageScroll
									onClick={() => executeScroll()}
									spacer={addSpacer}
									type={scrollIcon.scrollIconType}
									color={scrollIcon.iconColor?.color}
									bg={[
										scrollIcon.addCustomIconBackgroundElement,
										scrollIcon.iconBackgroundAsset,
										scrollIcon.iconBackgroundColor?.color,
									]}
									custom={scrollIcon.customScrollIcon.img}
									featured={scrollIcon.iconFeatured}
								/>
							</div>
						)}
					</>
				)}
			</motion.div>
		</LazyMotion>
	);
}

export const fragment = `
    fragment HeroVideoFragment on SectionHeroVideoRecord{
     __typename
    id
    addScrollIcon
    scrollIcon {
      scrollIconType
      iconColor {
        color {
          hex
        }
      }
      iconBackgroundColor{
        color{
          hex
        }
      }
      iconBackgroundAsset{
        img{
          url
        }
      }
      addCustomIconBackgroundElement
      iconBackgroundAsset{
        img{
          url
        }
      }
      customScrollIcon{
        img{
          url
        }
      }
	  iconFeatured
    }
    muted
    loop
    title
    autoplay
    controls
    videoPreview
    swapTitleForImage
    textAlignment
    label
    overlay
    textFlexPosition
    addIconGallery
    gallery {
      id
      url
      width
      height
    }
    galleryPosition
    imageReplacer {
      format
      responsiveImage {
        alt
        base64
        bgColor
        title
		aspectRatio
      }
      url
      width
      height
    }
    bgColor {
      color {
        hex
      }
    }
    description
    heroHeight
    overlay
    imageOverlayBaseColor {
      hex
    }
    textAlignment
    textColor {
      color {
        hex
      }
    }
    textWidth
    useLabelBeforeTitle
    playIcon {
      img {
        url
        height
        width
      }
    }
    addSpacer

    spacerConfiguration {
      id
      spacerDirection
      spacerPosition
      customShape {
        img {
          alt
          url
          width
          height
        }
      }
    }
    posterImage {
      responsiveImage {
        ...responsiveImageFragment
      }
    }
    video {
      video {
        mp4Url
        muxAssetId
        thumbnailUrl
        streamingUrl
        mp4High: mp4Url(res: high)
        mp4Med: mp4Url(res: medium)
        mp4Low: mp4Url(res: low)
        duration
        framerate
        thumbJpg: thumbnailUrl(format: jpg)
        thumbPng: thumbnailUrl(format: png)
        thumbGif: thumbnailUrl(format: gif)
      }
    }
  }
`;
