import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";

import { PlayIcon, PauseIcon } from "@/components/Atoms/Vectors";
import { FullBackgroundImage } from "../ImageCard";

interface Props {
	file: any;
	color: any[any];
	className: string;
	onClick: any;
	poster?: any;
}

interface VideoCardProps extends Props {
	controls: any[any];
	color: any[any];
	bgColor?: any;
	className: string;
	placement: string;
	customIcon?: any;
}

export function VideoHeroSlider({
	file,
	color,
	className,
	onClick,
	poster,
}: Props) {
	const videoRef = useRef(null);
	const src = file?.mp4High;
	const [playing, setPlaying] = useState(false);

	useEffect(() => {
		const video: any = videoRef.current;
		if (!video) return;

		video.controls = false;
		video.src = src;

		const playpause: any = document.getElementById(`${file.muxAssetId}`);
		const mute = document.getElementById(`mute-${file.muxAssetId}`);
		playpause.addEventListener("click", function () {
			if (video.paused || video.ended) video.play();
			else video.pause();
		});

		video.addEventListener(
			"play",
			function () {
				changeButtonState("playpause");
			},
			false
		);
		video.addEventListener(
			"pause",
			function () {
				changeButtonState("playpause");
			},
			false
		);

		function changeButtonState(type: string) {
			// Play/Pause button
			if (type == "playpause") {
				if (video.paused || video.ended) {
					playpause.setAttribute("data-state", "play");
					setPlaying(false);
				} else {
					playpause.setAttribute("data-state", "pause");
					setPlaying(true);
				}
			}
		}
	}, [src, videoRef]);

	return (
		<>
			<button
				onClick={onClick}
				aria-label="Video play/pause"
				className={`${
					!playing
						? " left-0 right-0 w-fit mx-auto  top-[10rem] bottom-0 scale-[2] hover:scale-[2.5] transition-all ease-in-out duration-300"
						: "justify-center lg:justify-end mx-auto  left-10  bottom-6 flex flex-row gap-8 md:gap-12"
				} absolute z-30`}
				id={file.muxAssetId}
				type="button"
				data-state="play"
			>
				{" "}
				{!playing ? <PlayIcon color={color} /> : <PauseIcon color={color} />}
			</button>
			{!playing && (
				<span
					className={`${styles.gradient} absolute w-full h-full z-10 `}
				></span>
			)}
			<video
				playsInline
				onEnded={onClick}
				className={`${className} ${styles.animate} ${
					!playing ? `${styles.obCover}` : `${styles.obContain}`
				} w-full h-full max-w-[100%] cursor-pointer absolute `}
				ref={videoRef}
				poster={`${poster?.responsiveImage.src}?&fit_mode=smartcrop`}
			/>
		</>
	);
}

export function VideoCard({
	file,
	controls,
	color,
	className,
	onClick,
	placement,
	poster,
	bgColor,
	customIcon,
}: VideoCardProps) {
	const videoRef = useRef(null);
	const src = file?.mp4High;
	const [playing, setPlaying] = useState(false);

	useEffect(() => {
		const video: any = videoRef.current;
		if (!video) return;

		video.controls = false;
		video.src = src;

		const playpause: any = document?.getElementById(`${file?.muxAssetId}`);
		const mute = document?.getElementById(`mute-${file?.muxAssetId}`);
		playpause?.addEventListener("click", function () {
			video.play();
		});

		video.addEventListener(
			"play",
			function () {
				changeButtonState("playpause");
			},
			false
		);
		video.addEventListener(
			"pause",
			function () {
				changeButtonState("playpause");
				setPlaying(!playing);
			},
			false
		);
		video.addEventListener(
			"ended",
			function () {
				changeButtonState("playpause");
				setPlaying(false);
				video.controls = false;
				video.currentTime = `0`;
			},
			false
		);

		function changeButtonState(type: string) {
			// Play/Pause button
			if (type == "playpause") {
				setPlaying(true);
				video.controls = true;
			}
		}
	}, [src, videoRef]);

	if (placement == "SectionHeroVideoRecord") {
		return (
			<>
				{!playing ? (
					<div className={""}>
						<FullBackgroundImage data={poster} />
					</div>
				) : (
					<></>
				)}
				<video
					playsInline
					style={{ backgroundColor: bgColor?.hex }}
					loop={controls[0]}
					autoPlay={controls[4]}
					muted={controls[1]}
					controls={controls[2]}
					onEnded={onClick}
					className={`${className} w-full cursor-pointer  md:min-h-[400px] ${
						controls[2] ? "" : "pointer-events-none"
					}  ${
						placement == "SectionHeroVideoRecord" ? "object-center h-full" : ""
					} ${playing ? "object-contain" : "object-cover"}    ${
						controls[4] ? "object-cover" : ""
					} `}
					ref={videoRef}
					poster={`${file?.thumbJpg}?&time=${controls[3]}&fit_mode=smartcrop`}
				/>
			</>
		);
	} else {
		return (
			<>
				{!playing ? (
					<div className={""}>
						<FullBackgroundImage data={poster} />
					</div>
				) : (
					<></>
				)}
				{controls[2] && (
					<button
						onClick={onClick}
						aria-label="video play"
						className={` z-[1000] justify-center lg:justify-end  bottom-4 right-12 lg:right-12  lg:bottom-12 m-auto h-fit  w-fit flex flex-row gap-8 md:gap-12  absolute xs:scale-75 lg:scale-150`}
						id={file.muxAssetId}
						type="button"
						data-state="play"
					>
						{customIcon?.[0] ? (
							<>
								{!playing ? (
									<PlayIcon custom={customIcon[1]?.img} color={color} />
								) : (
									<></>
								)}
							</>
						) : (
							<> {!playing ? <PlayIcon color={color} /> : <></>}</>
						)}
					</button>
				)}
				{!playing ? <div className={styles.overlayVideo}></div> : <></>}
				<video
					playsInline
					style={{ backgroundColor: bgColor?.hex }}
					loop={controls[0]}
					autoPlay={controls[4]}
					muted={controls[1]}
					controls={controls[2]}
					onEnded={onClick}
					className={`${className} w-full cursor-pointer  md:min-h-[400px] ${
						controls[2] ? "" : "pointer-events-none"
					}  ${
						placement == "SectionHeroVideoRecord" ? "object-center h-full" : ""
					} ${playing ? "object-contain" : "object-cover"}    ${
						controls[4] ? "object-cover" : ""
					}`}
					ref={videoRef}
					poster={`${file?.thumbJpg}?&time=${controls[3]}&fit_mode=smartcrop`}
				/>
			</>
		);
	}
}
