import Image from "@/components/Atoms/Image";

import { LazyMotion, domAnimation, m, motion } from "framer-motion";
import { slideReveal, string_to_slug } from "@/lib/functions";

import styles from "./index.module.scss";

import { ImageLabel } from "@/atoms/Paragraph";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { TextCopyCard } from "@/components/Molecules/CopyCard";
import {
	AboveParallaxImage,
	BellowParallaxImage,
	StyleBackgroundImage,
} from "@/components/Atoms/ParallaxImage";
import { Shape } from "@/components/Atoms/Spacer";
import React from "react";
import GeoWrapper from "@/components/Templates/Main/GeoConditional";
import { cva, VariantProps } from "class-variance-authority";

export type TextImageHalfVariantProps = VariantProps<
	typeof TextImageHalfVariants
>;
const TextImageHalfVariants = cva(` mx-auto relative w-full `, {
	variants: {
		contentHeight: {
			small: "",
			default: "overflow-x-hidden",
		},
		useSectionMargins: {
			true: "py-6 md:py-12",
			false: "py-0",
		},
		useExtraTopMargins: {
			true: "pt-12 pb-12 md:pt-24 md:pb-12",
			false: "",
		},
		useExtraPadding: {
			true: "pb-32 lg:pb-32",
			false: "",
		},
	},
	defaultVariants: {
		contentHeight: "default",
		useSectionMargins: false,
		useExtraTopMargins: false,
		useExtraPadding: false,
	},
});

export interface TextImageHalfProps
	extends React.HTMLAttributes<HTMLHeadingElement>,
		TextImageHalfVariantProps {
	data: any;
	radius: string;
}

export default function TextImageHalf({
	data,
	contentHeight,
	useSectionMargins,
	useExtraTopMargins,
	useExtraPadding,
	radius,
}: TextImageHalfProps) {
	const geoFilter = data?.geo?.map((filter: any) => filter);

	return (
		<GeoWrapper
			list={geoFilter?.[0]?.listOfCountries}
			country={geoFilter?.[0]?.countries}
			initial={geoFilter?.[0]?.countryAvailability}
		>
			{data.addSpacer && (
				<Shape
					shape={data.spacerConfiguration?.customShape}
					color={data.bgColor}
					direction={data.spacerConfiguration?.spacerDirection}
					position={data.spacerConfiguration?.spacerPosition}
					icon={[
						data.spacerConfiguration?.addIconElement,
						data?.spacerConfiguration?.spacerIcon,
					]}
					invert={false}
				/>
			)}

			<div
				style={{ backgroundColor: data?.bgColor?.color?.hex }}
				className={` ${TextImageHalfVariants({
					contentHeight,
					useSectionMargins,
					useExtraTopMargins,
					useExtraPadding,
				})} `}
			>
				{data.addBackground && (
					<>
						{data.backgroundImage.format == "svg" ? (
							<> </>
						) : (
							<FullBackgroundImage data={data.backgroundImage} />
						)}
					</>
				)}
				<div
					className="absolute top-[-100px]"
					id={string_to_slug(data.title)}
				></div>
				<div
					className={`${
						data.contentHeight === "small" &&
						" pt-12   sm:translate-y-[-18%] md:translate-y-[0]  lg:h-[450px]"
					} container mx-auto`}
				>
					<div
						className={` ${
							data.mobileColumnDisplay ? `flex-col-reverse` : `flex-col`
						}  flex  lg:grid lg:grid-cols-2 ${
							data.contentHeight === "textSmall"
								? data.textAlignment == "left"
									? "lg:grid-cols-[450px_auto]"
									: "lg:grid-cols-[auto_450px]"
								: ""
						} relative mx-auto w-full px-6 lg:px-2 gap-12 lg:gap-16 container 2xl:max-w-[1360px]`}
					>
						<div
							className={` relative w-full ${
								data?.addOverlayParallaxImage
									? `${
											data?.parallaxImagePosition !== "bellow"
												? `${
														data.textAlignment == "left"
															? " lg:pl-[3rem]"
															: "lg:pl-[15rem]"
												  }`
												: ``
									  }`
									: ""
							}
       ${styles.innerTextContainer} flex  ${
								data.useSmallerContainer && `${styles.smallerContainer}`
							}   ${
								data.textAlignment === "right"
									? `${styles.textRight}`
									: `${styles.textLeft}`
							}  ${
								data.contentHeight === "small" &&
								`${styles.smallInnerContainer}`
							}  `}
						>
							<TextCopyCard
								layout={"left"}
								ctaLayout={data.ctaLayout}
								data={data}
							/>
							{data.addBackground && (
								<>
									{data.backgroundImage.format == "svg" && (
										<StyleBackgroundImage
											position={data.textAlignment}
											data={data.backgroundImage}
										/>
									)}
								</>
							)}
						</div>

						<div
							className={` flex-none lg:col-span-1 flex   ${
								data.textAlignment === "right"
									? "  order-1 "
									: " order-1 lg:order-2"
							} ${
								data.contentHeight === "small"
									? "translate-y-[0] xl:translate-y-[-25%] justify-center flex "
									: ""
							}  z-20 `}
						>
							<LazyMotion features={domAnimation}>
								<m.div
									className={`   ${
										data.contentHeight === "small"
											? `${styles.imageFixHeight} max-w-[30rem] lg:max-w-[33rem] relative  bottom-0`
											: `${styles.imageFix} relative `
									}  w-full flex flex-row lg:flex-col gap-12 justify-center `}
									initial="hidden"
									whileInView="visible"
									viewport={{ once: true }}
									exit="exit"
								>
									<motion.div
										variants={slideReveal(data.textAlignment)}
										initial="hidden"
										whileInView="visible"
										viewport={{ once: true }}
										exit="exit"
										className="relative flex flex-col"
									>
										<div
											className={`${
												data.mainAssetHeight == "reduced"
													? "md:max-w-[400px] m-auto"
													: ""
											} `}
										>
											{data.image.format == "png" ? (
												<>
													{" "}
													<Image
														data={data.image}
														className={` ${
															data.parallaxImage
																? ` ${styles.parallaxMain}`
																: ` ${styles.imageFix}`
														}`}
													/>
												</>
											) : (
												<Image
													data={data.image}
													className={` ${
														radius == "rounded"
															? "rounded-md"
															: radius == "roundedXl"
															? "rounded-3xl"
															: ""
													} ${
														data.parallaxImage
															? ` ${styles.parallaxMain}`
															: ` ${styles.imageFix}`
													}`}
												/>
											)}

											{data.showLabel && (
												<ImageLabel
													className={
														data.image?.format === "png"
															? "text-center md:px-24"
															: "text-left"
													}
													color={data.textLabelColor?.color}
													copy={data.image?.title}
												/>
											)}
										</div>
										{data.addOverlayParallaxImage && (
											<>
												{data.parallaxImagePosition == "bellow" ? (
													<BellowParallaxImage
														position={data.textAlignment}
														data={data.parallaxImage}
													/>
												) : (
													<AboveParallaxImage
														position={data.textAlignment}
														data={data.parallaxImage}
													/>
												)}
											</>
										)}
									</motion.div>
								</m.div>
							</LazyMotion>
						</div>
					</div>
				</div>
			</div>
		</GeoWrapper>
	);
}
