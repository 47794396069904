import OverviewCard from "@/molecules/OverviewCard";
import { useTranslation } from "next-i18next";

interface Props {
  data: any;
  config: any;
  layout: string;
}

export default function RecipeOverviewBlock({ data, config, layout }: Props) {
  const { t } = useTranslation();
  return (
    <div className=" flex flex-row flex-wrap gap-4 pb-12">
      {data.difficulty && (
        <OverviewCard
          layout={layout}
          label={t("difficulty")}
          value={data.difficulty}
          color={config?.highlightTextColor?.color}
          labelColor={config?.headlineTextColor?.color}
          bg={config?.cardBackgroundColor?.color}
        />
      )}
      {data.recipeDetails.map((detail: any, i: number) => (
        <OverviewCard
          layout={layout}
          key={i}
          label={t(detail.detailType)}
          value={detail.value}
          color={config?.highlightTextColor?.color}
          labelColor={config?.headlineTextColor?.color}
          bg={config?.cardBackgroundColor?.color}
        />
      ))}
      {data.flavour && (
        <OverviewCard
          layout={layout}
          label={t("flavour")}
          value={data?.flavour.label}
          color={config?.highlightTextColor?.color}
          bg={config?.cardBackgroundColor?.color}
          labelColor={config?.headlineTextColor?.color}
        />
      )}
      {data.recipeCategory && (
        <OverviewCard
          layout={layout}
          label={t("meal")}
          value={data?.recipeCategory.title}
          color={config?.highlightTextColor?.color}
          bg={config?.cardBackgroundColor?.color}
          labelColor={config?.headlineTextColor?.color}
        />
      )}
    </div>
  );
}
