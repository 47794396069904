import { pageHandler } from "@/lib/pages-handler";
import { getRecipePage, getRecipeConfig } from "@/dato-api/recipe";
import { buildHrefLangsList } from "@/lib/functions";
import { getReferencingPortfolioByProductId } from "@/dato-api/awards";
import { useLayoutEffect, useRef, useState } from "react";
import { fetchComponentRelations } from "@/lib/api";
import HeroRecipe, { HeroRecipeCocktail } from "@/organisms/Hero/HeroRecipe";
import RecipeOverview from "@/organisms/RecipeOverview";
import IngredientsBlock from "@/organisms/IngredientsBlock";
import DirectionsBlock from "@/organisms/DirectionsBlock";
import RecipeGallery from "@/organisms/RecipeGallery";
import Blocks from "@/components/Organisms";

import { useRouter } from "next/router";

export default function Recipe({ data, relations }) {
  const { locale } = useRouter();
  const [detailSize, setDetailSize] = useState();
  const detailRef = useRef(null);


  if (data.layoutType == "cocktail") {
    return (
      <div
        style={{
          backgroundColor:
            data.pageConfig?.brandRecipe?.backgroundColor?.color?.hex,
        }}
      >
        <HeroRecipeCocktail
          config={data.pageConfig?.brandRecipe}
          title={data.name}
          description={data?.alternativeHeadline}
          image={data?.featuredImage}
        />
        <div className="grid lg:grid-cols-2 container mx-auto px-6 lg:px-4 xl:px-2 2xl:px-0  2xl:max-w-[1360px]">
          <div className="col-start-2">
            <RecipeOverview
              layout={data.layoutType}
              data={data}
              config={data.pageConfig?.brandRecipe}
            />
            <IngredientsBlock
              data={data}
              config={data.pageConfig?.brandRecipe}
            />
            <DirectionsBlock
              data={data}
              layout={data.layoutType}
              config={data.pageConfig?.brandRecipe}
            />
            <RecipeGallery data={data} config={data.pageConfig?.brandRecipe} />
          </div>
        </div>
        <Blocks
          sections={data.pageConfig?.brandRecipe.relatedSections}
          relations={relations}
        ></Blocks>
      </div>
    );
  } else {
    useLayoutEffect(() => {
      setDetailSize(detailRef.current.clientHeight);
    }, []);

    return (
      <>
        <HeroRecipe
          pairing={data.pairing}
          config={data.pageConfig?.brandRecipe}
          title={data.name}
          description={data?.alternativeHeadline}
          image={data?.featuredImage}
          locale={locale}
          overlay={data.addOverlay}
          portfolioSlug={data?.portfolioConf?.[0]?.slug}
          detailSize={detailSize}
        />

        <div
          ref={detailRef}
          className="grid lg:grid-cols-2 container mx-auto px-6 lg:px-4 xl:px-2 2xl:px-0  2xl:max-w-[1360px]"
        >
          <div className="col-start-2">
            <RecipeOverview data={data} config={data.pageConfig?.brandRecipe} />
            <IngredientsBlock
              data={data}
              config={data.pageConfig?.brandRecipe}
            />
            <DirectionsBlock
              data={data}
              config={data.pageConfig?.brandRecipe}
            />
            <RecipeGallery data={data} config={data.pageConfig?.brandRecipe} />
          </div>
        </div>
        <Blocks
          sections={data.pageConfig?.brandRecipe.relatedSections}
          relations={relations}
        ></Blocks>
      </>
    );
  }
}

export async function _getStaticProps(
  context,
  pageType,
  serverSideTranslations,
  siteConfig
) {
  const preview = context.preview !== undefined ? context.preview : false;

  const currentSlug =
    context.params.slug.length > 1
      ? context.params.slug[1]
      : context.params.slug[0];

  const result = await getRecipePage(
    currentSlug,
    context.locale,
    preview,
    siteConfig.siteConfiguration.id
  );

  const pageConfig = await getRecipeConfig(
    context.locale,
    preview,
    siteConfig.siteConfiguration.id
  );

  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    result,
    "recipe"
  );

  // Get portfolio configuration
  const { productPortfolio } = await getReferencingPortfolioByProductId(
    context.locale,
    preview,
    pageData.data.pairing.id,
    siteConfig.siteConfiguration.id
  );

  const { portfolioConf } = productPortfolio;

  const _allSlugLocales = pageData.data?._allSlugLocales;
  const hrefLangs = buildHrefLangsList(
    _allSlugLocales,
    pageData.data?.__typename
  );

  const relations = await fetchComponentRelations(
    { sections: pageConfig?.brandRecipe.relatedSections },
    context.locale,
    context.preview
  );

  return {
    props: {
      siteConfig: siteConfig.siteConfiguration,
      ...pageData.sst,
      data: {
        ...pageData.data,
        pageConfig,
        hrefLangs,
        portfolioConf,
      },
      preview: pageData.preview,
      relations: relations,
      pageType,
    },
  };
}
