import CustomImage from "@/components/Atoms/Image";
import { BigNumber, Paragraph } from "@/components/Atoms/Paragraph";
import { VariantProps, cva } from "class-variance-authority";

export type BlockVariantProps = VariantProps<typeof BlockVariants>;
const BlockVariants = cva(` grid container mx-auto gap-6 lg:gap-16 pb-6  2xl:max-w-[1360px]` , {
  variants: {
    gridDisplay: {
      half: `grid-cols-2`,
      third: `grid-cols-1 md:grid-cols-3 `,
      quarter: "grid-cols-4",
    },
  },
  defaultVariants: {
    gridDisplay: "third",
  },
});

interface Props {
  __typename: string;
  composer: any;
  backgroundColor: any;
  gridDisplay: "half" | "third" | "quarter";
  textColor: any;
}

export default function MultiPurposeBlock({
  composer,
  backgroundColor,
  textColor,
  gridDisplay,
}: Props) {
  return (
    <div
      style={{ backgroundColor: backgroundColor?.color.hex }}
      className={` py-6 md:py-12  `}
    >
      <div className={BlockVariants({ gridDisplay })}>
        {composer.map((entry: any, index: number) => (
          <div
            key={index}
            className="flex flex-col gap-6 justify-start items-center"
          >
            {entry.title && (
              <BigNumber copy={entry.title} color={textColor?.color} />
            )}
            {entry.image && (
              <div className="w-12 h-12 lg:w-20 lg:h-20 relative">
                <CustomImage data={entry.image} />
              </div>
            )}
            <div className="max-w-[50%] md:max-w-full">
              <Paragraph
                copy={entry?.text}
                color={textColor?.color}
                layout="fixedCenter"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export const fragment = `
    fragment SectionMultiPurposeBlock on SectionMultiPurposeBlockRecord{
        __typename
        id
        backgroundColor {
          color {
            hex
          }
        }
        textColor {
          color {
            hex
          }
        }
        gridDisplay
        composer {
          ... on TextRecord {
            id
            addTitle
            title
            text
          }
          ... on TextWithImageRecord {
            id
            text
            image {
              url
            }
          }
        }
    }
`;
