import React, { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import { ButtonGroupProps, DotProps } from "react-multi-carousel/lib/types";
import Image from "@/components/Atoms/Image";
import "react-multi-carousel/lib/styles.css";
import { useRouter } from "next/router";

import styles from "./index.module.scss";

import { VideoHeroSlider } from "@/components/Molecules/VideoCard";
import { OverlayShadow } from "@/components/Molecules/OverlayShadow/Overlay";
import { FullBackgroundImageHero } from "@/components/Molecules/ImageCard";
import {
  FeaturedCopyCard,
  SliderCopyCard,
} from "@/components/Molecules/HeroCopyCard";
import { NavigationSlider } from "@/atoms/NavigationSlider";
import { PageScroll } from "@/atoms/PageScroll";
import GeoWrapper from "@/components/Templates/Main/GeoConditional";
import { IconGallery } from "@/components/Molecules/IconGallery";

type CarouselInternalState = {
  itemWidth: number;
  containerWidth: number;
  slidesToShow: number;
  currentSlide: number;
  totalItems: number;
  domLoaded: boolean;
  deviceType?: string;
  transform: number;
};

interface SliderNav extends DotProps {
  next: any;
  previous: any;
  goToSlide: any;
}

export default function HeroSlider(data: any[any], {}: SliderNav) {
  const router = useRouter();
  const currentCountry = router.query.country;
  const ignoreGeo = router.query.ignoregeo;

  const filteredSlides = data.slider.filter((entry: any[any]) => {
    if (entry.countryAvailability !== false || ignoreGeo == "true") {
      return true;
    } else if (entry.listOfCountries) {
      return entry.countries?.some(
        (country: any) => country.countryCode !== currentCountry
      );
    } else {
      return entry.countries?.some(
        (country: any) => country.countryCode == currentCountry
      );
    }
  });

  const responsiveSlider = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  interface CarouselButtonGroupProps extends ButtonGroupProps {
    className?: string;
  }

  const CustomDots = ({ carouselState, goToSlide }: SliderNav) => {
    const currentSlide: any = carouselState?.currentSlide;
    return (
      <>
        {" "}
        {!playing && (
          <>
            {" "}
            {filteredSlides.length > 1 && (
              <div
                className={` absolute z-30 w-full lg:w-fit  justify-end mx-auto right-6  lg:right-10 bottom-6 md:bottom-24 lg:bottom-8 flex flex-row gap-4`}
              >
                {filteredSlides.map((entry: any[any], i: number) => (
                  <GeoWrapper
                    key={i}
                    initial={entry.countryAvailability}
                    country={entry?.countries}
                    list={entry?.listOfCountries}
                  >
                    <NavigationSlider
                      onClick={() => goToSlide(i)}
                      currentSlide={currentSlide}
                      key={i}
                      index={i}
                      type={data.navigationMarkersType}
                      custom={data.customNavigationMarker}
                      color={data.markersColor?.color}
                      activeColor={data.activeMarkerColor?.color}
                      length={filteredSlides.length}
                      label={entry.title}
                    />
                  </GeoWrapper>
                ))}
              </div>
            )}{" "}
          </>
        )}
      </>
    );
  };

  const scrollRef: any = useRef(null);
  const executeScroll = () =>
    scrollRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "end",
    });

  const [playing, setPlaying] = useState(false);

  const togglePlay = () => {
    setPlaying(!playing);
  };

  return (
    <div className="relative pt-[4.2rem] lg:pt-[90px]">
      <Carousel
        containerClass={`${
          data.heroLayout === "tall" ? "min-h-[90vh]" : "min-h-[500px]"
        } ${styles.Slider} ${
          playing ? `${styles.playing}` : `${styles.noPlaying}`
        }  relative  w-full transition-all duration-500 ease-in xs-[4rem] `}
        responsive={responsiveSlider}
        itemClass="h-full"
        arrows={false}
        autoPlay={false}
        customButtonGroup={
          <CustomDots next={""} previous={""} goToSlide={""} />
        }
      >
        {filteredSlides.map((entry: any[any], index: number) => (
          <GeoWrapper
            key={index}
            initial={entry?.countryAvailability}
            country={entry?.countries}
            list={entry?.listOfCountries}
          >
            <div
              style={{ backgroundColor: entry?.bgColor?.color.hex }}
              className="h-full"
              key={index}
            >
              {entry.overlay && (
                <>
                  {" "}
                  {!playing && (
                    <OverlayShadow
                      format={entry.__typename}
                      data={entry.overlay}
                      color={entry.imageOverlayBaseColor}
                      layout={entry.textAlign}
                    />
                  )}
                </>
              )}
              {entry.__typename == "SliderFeaturedRecord" ? (
                <>
                  {" "}
                  <FullBackgroundImageHero
                    position={entry.imagePosition}
                    data={entry.image}
                  />
                  <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto h-full relative gap-6 md:gap-0">
                    <div className="col-span-1 flex items-end order-2 lg:order-1  ">
                      <Image
                        data={entry?.featuredImage}
                        className=" max-w-[50%] lg:max-w-[470px] mx-auto"
                      />
                    </div>
                    <div className="col-span-1 flex items-center  order-1 lg:order-2">
                      <FeaturedCopyCard
                        parallaxImage={[
                          entry?.addParallaxElements,
                          entry?.parallaxAsset,
                        ]}
                        title={entry.title}
                        description={entry.description}
                        color={entry.textColor?.color}
                        descriptionColor={entry.descriptionColor?.color}
                        cta={[entry.addCallToAction, entry.pageRelated]}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  {entry.__typename == "SliderImageRecord" && (
                    <FullBackgroundImageHero
                      position={entry.imagePosition}
                      data={entry.image}
                    />
                  )}
                  {entry.__typename == "SliderVideoRecord" && (
                    <VideoHeroSlider
                      onClick={() => togglePlay()}
                      color={entry.textColor?.color.hex}
                      poster={entry.posterImage}
                      file={entry.video.video}
                      className=""
                    />
                  )}
                  {!playing && (
                    <>
                      {entry.addIconGallery && (
                        <IconGallery
                          layout={entry.galleryPosition}
                          data={entry.gallery}
                        />
                      )}

                      <SliderCopyCard
                        title={entry.title}
                        description={entry.description}
                        label={[entry.useLabelBeforeTitle, entry.label]}
                        color={entry.textColor?.color}
                        layout={entry.textAlign}
                        flex={entry.textFlexPosition}
                        cta={[entry.addCallToAction, entry.pageRelated]}
                        underline={[
                          entry.addTitleUnderline,
                          entry.customUnderlineAsset,
                          entry.underlineColor,
                        ]}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </GeoWrapper>
        ))}
      </Carousel>
      {data.addScrollIcon && (
        <div
          className={`absolute bottom-0 h-[60px] md:h-[45px] w-full left-0 right-0`}
          ref={scrollRef}
        >
          {!playing && (
            <PageScroll
              onClick={() => executeScroll()}
              spacer={data.addSpacer}
              type={data.scrollIcon?.scrollIconType}
              color={data.scrollIcon?.iconColor?.color}
              bg={[
                data.scrollIcon?.addCustomIconBackgroundElement,
                data.scrollIcon?.iconBackgroundAsset,
                data.scrollIcon?.iconBackgroundColor?.color,
              ]}
              custom={data.scrollIcon?.customScrollIcon.img}
              featured={data.scrollIcon?.iconFeatured}
            />
          )}
        </div>
      )}
    </div>
  );
}

export const fragment = `
    fragment HeroSliderFragment on SectionHeroSliderRecord{
      id
    name
    heroLayout
    addScrollIcon
    scrollIcon {
      scrollIconType
      iconColor {
        color {
          hex
        }
      }
      iconBackgroundColor{
        color{
          hex
        }
      }
      addCustomIconBackgroundElement
      iconBackgroundAsset{
        img{
          url
        }
      }
      customScrollIcon{
        img{
          url
        }
      }
    iconFeatured
    }
    activeMarkerColor {
      color {
        hex
      }
    }
    markersColor {
      color {
        hex
      }
    }
    customNavigationMarker{
      img{
        url
        width
        height
        alt
      }
    }
    addSliderNavigationsMarkers
    navigationMarkersType
    slider {
      ... on SliderFeaturedRecord{
        __typename
        title
        description
        countryAvailability
        listOfCountries
        countries {
          title
          countryCode
        }
        featuredImage {
          responsiveImage (imgixParams: { fit: crop, w: "1000", crop: focalpoint, auto:format, q:80 }) {
            ... responsiveImageFragment
          }
        }
        addParallaxElements
        parallaxAsset {
          url
        }
        addCallToAction
        pageRelated {
        ...pageRelatedFragment
        }
        bgColor {
          color {
            hex
            cssRgb
          }
        }
        textColor {
          color {
            hex
            cssRgb
          }
        }
        descriptionColor {
          color {
            hex
            cssRgb
          }
        }
        textFlexPosition
        textAlign
        overlay
        imagePosition
        image {
          responsiveImage (imgixParams: { fit: crop, w: "1600", crop: focalpoint, auto:format, q:80 }) {
            ... responsiveImageFragment
          }
        }
      }
      ... on SliderImageRecord {
        __typename
        id
        title
        overlay
        imageOverlayBaseColor {
          hex
        }
        textAlign
        textFlexPosition
        description
        imagePosition
        bgColor{
          color{
            hex
          }
        }
        textColor{
          color{
            hex
          }
        }
        label
        useLabelBeforeTitle
        addCallToAction
        pageRelated {
        ...pageRelatedFragment
        }
        addTitleUnderline
        underlineColor{
          color{
            hex
          }
        }
        customUnderlineAsset{
          img{
            url
            width
            height
          }
        }
        addIconGallery
        gallery {
          id
          url
          width
          height
        }
        galleryPosition
        countryAvailability
        listOfCountries
        countries {
          title
          countryCode
        }
        image {
          responsiveImage (imgixParams: { fit: crop, w: "1600", crop: focalpoint, auto:format, q:80 }) {
            ... responsiveImageFragment
          }
          focalPoint {
            y
            x
          }
        }
      }
      ... on SliderVideoRecord {
        __typename
        id
        title
        overlay
        imageOverlayBaseColor {
          hex
        }
        textAlign
        textFlexPosition
        description
        bgColor{
          color{
            hex
          }
        }
        textColor{
          color{
            hex
          }
        }
        label
        useLabelBeforeTitle
        addCallToAction
        pageRelated {
        ...pageRelatedFragment
        }
        posterImage{
          responsiveImage (imgixParams: { fit: crop, w: "1600", ar:"4:2", crop: focalpoint, auto:format, q:80 }) {
            ...responsiveImageFragment
          }
        }
        video {
          id
          url
          width
          height
          title
          video{
            muxAssetId
            streamingUrl
            mp4High: mp4Url(res: high)
            mp4Med: mp4Url(res: medium)
            mp4Low: mp4Url(res: low)
            duration
            framerate
            thumbJpg: thumbnailUrl(format: jpg)
            thumbPng: thumbnailUrl(format: png)
            thumbGif: thumbnailUrl(format: gif)
          }
          }
        }
      }
    }
`;
