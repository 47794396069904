import { Paragraph } from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";

interface Props {
	data: any;
	titleColor: string;
	descriptionColor: string;
}

export function GrapeVarietyBlock({
	data,
	descriptionColor,
	titleColor,
}: Props) {
	return (
		<>
			{data.grapeVariety.map((variety: any) => (
				<div className="flex flex-col gap-4" key={variety.id}>
					<Title color={titleColor} tag={"h4"} data={variety.title} />
					{variety.description && (
						<Paragraph color={descriptionColor} copy={variety.description} />
					)}
				</div>
			))}
		</>
	);
}
