import CustomLink from "@/components/Atoms/CustomLink";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { Menu, Transition } from "@headlessui/react";
import styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import { DropdownIcon } from "@/components/Atoms/Vectors";
import { bg } from "date-fns/locale";

interface Related {
	data: any;
	color?: any;
	bgColor?: any;
	iconColor?: any;
	query: any;
	locale: string;
	portfolioSlug: string;
	id?: string;
	dropdown?: boolean;
}

export function RelatedSlugs({ data, color, locale, portfolioSlug }: Related) {
	return (
		<>
			{data?.relatedProducts.map((entry: any, i: number) => (
				<div
					id={entry.productName}
					className="brandCall flex flex-col relative w-fit gap-2 hover:scale-105 transition-all duration-300"
					key={i}
				>
					<CustomLink
						className={``}
						href={`${routerHandler({
							typename: entry?.__typename,
							slug: [
								portfolioSlug,
								entry?.productTypeSpecific?.slug,
								entry?.slug,
							],
							locale: locale,
						})}`}
					>
						{entry.productName}
					</CustomLink>
					<span
						style={{ backgroundColor: color?.hex }}
						className="h-[1px] w-full"
					></span>
				</div>
			))}
		</>
	);
}

export function RelatedSlugsDropdown({
	data,
	color,
	bgColor,
	iconColor,
	locale,
	portfolioSlug,
}: Related) {
	const style = {
		"--bg": bgColor.color.hex,
		"--color": iconColor.color.hex,
	} as React.CSSProperties;

	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef<HTMLUListElement>(null);

	const handleMenuClick = () => {
		setIsOpen((prev) => !prev);
	};

	const handleClickOutside = (event: any) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	if (data.relatedProducts.length < 1) return <></>;
	return (
		<div className="flex flex-col gap-3">
			<h3 className="text-xl">Anos Colheitas lançadas</h3>
			<p className="text-sm">Escolha o ano de colheita:</p>
			<Menu
				ref={menuRef}
				style={style as React.CSSProperties}
				as="nav"
				className={`flex flex-row items-center gap-6 brandNav`}
			>
				<div className="relative">
					<Menu.Button
						as="li"
						onClick={handleMenuClick}
						className={`${styles.navigationItem} flex flex-row gap-2 items-center relative px-5 py-2`}
					>
						{data.product.productName}
						{isOpen && (
							<Transition
								show={isOpen}
								className={`${styles.navigationItem} absolute left-0 top-16 md:top-12 lg:top-12 border-0 focus:outline-none w-full`}
								enter="transition duration-300 ease-out"
								enterFrom="transform scale-95 opacity-0 -translate-y-4"
								enterTo="transform scale-100 opacity-100 translate-y-4"
								leave="transition duration-75 ease-out"
								leaveFrom="transform scale-100 opacity-100"
								leaveTo="transform scale-95 opacity-0"
							>
								<Menu.Items
									as="ul"
									style={style as React.CSSProperties}
									className={`${styles.dropdownMenu}`}
								>
									{data?.relatedProducts.map((entry: any, index: number) => (
										<Menu.Item
											style={style as React.CSSProperties}
											className={`${styles.itemNav} py-2 px-6 transition duration-300 ease-out`}
											as="li"
											key={index}
										>
											<CustomLink
												href={`${routerHandler({
													typename: entry?.__typename,
													slug: [
														portfolioSlug,
														entry?.productTypeSpecific?.slug,
														entry?.slug,
													],
													locale: locale,
												})}`}
											>
												{entry.productName}
											</CustomLink>
										</Menu.Item>
									))}
								</Menu.Items>
							</Transition>
						)}
						<DropdownIcon
							className={`transition duration-300 ease-out ${
								isOpen ? "rotate-180" : ""
							}`}
							color={iconColor.color.hex}
						/>
					</Menu.Button>
				</div>
			</Menu>
		</div>
	);
}

export function RelatedCollectionDropdown({
	data,
	color,
	bgColor,
	iconColor,
	locale,
	portfolioSlug,
	id,
}: Related) {
	const style = {
		"--bg": bgColor.color.hex,
		"--color": iconColor.color.hex,
	} as React.CSSProperties;

	let currentProduct = data?.relatedProducts
		.filter((x: any) => x.id == id)
		.map((entry: any) => ({ ...entry }));

	let otherProducts = data?.relatedProducts
		.filter((x: any) => x.id !== id)
		.map((entry: any) => ({ ...entry }));

	let newRelatedProducts = [...otherProducts, data.product];

	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef<HTMLUListElement>(null);

	const handleMenuClick = () => {
		setIsOpen((prev) => !prev);
	};

	const handleClickOutside = (event: any) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	if (newRelatedProducts.length < 1) return <></>;
	return (
		<div className="flex flex-col gap-3">
			<h3 className="text-xl">Anos Colheitas lançadas</h3>
			<p className="text-sm">Escolha o ano de colheita:</p>
			<Menu
				ref={menuRef}
				style={style as React.CSSProperties}
				as="nav"
				className={`flex flex-row items-center gap-6 brandNav`}
			>
				<div className="relative">
					<Menu.Button
						as="li"
						onClick={handleMenuClick}
						className={`${styles.navigationItem} flex flex-row gap-2 items-center relative px-5 py-2`}
					>
						{currentProduct[0].productName}
						{isOpen && (
							<Transition
								show={isOpen}
								className={`${styles.navigationItem} absolute left-0 top-16 md:top-12 lg:top-12 border-0 focus:outline-none w-full`}
								enter="transition duration-300 ease-out"
								enterFrom="transform scale-95 opacity-0 -translate-y-4"
								enterTo="transform scale-100 opacity-100 translate-y-4"
								leave="transition duration-75 ease-out"
								leaveFrom="transform scale-100 opacity-100"
								leaveTo="transform scale-95 opacity-0"
							>
								<Menu.Items
									as="ul"
									style={style as React.CSSProperties}
									className={`${styles.dropdownMenu}`}
								>
									{newRelatedProducts.map((entry: any, index: number) => (
										<Menu.Item
											style={style as React.CSSProperties}
											className={`${styles.itemNav} py-2 px-6 transition duration-300 ease-out`}
											as="li"
											key={index}
										>
											<CustomLink
												href={`${routerHandler({
													typename: entry?.__typename,
													slug: [
														portfolioSlug,
														entry?.productTypeSpecific?.slug,
														entry?.slug,
													],
													locale: locale,
												})}`}
											>
												{entry.productName}
											</CustomLink>
										</Menu.Item>
									))}
								</Menu.Items>
							</Transition>
						)}
						<DropdownIcon
							className={`transition duration-300 ease-out ${
								isOpen ? "rotate-180" : ""
							}`}
							color={iconColor.color.hex}
						/>
					</Menu.Button>
				</div>
			</Menu>
		</div>
	);
}

export function RelatedCollectionSlugs({
	data,
	color,
	locale,
	portfolioSlug,
	id,
}: Related) {
	return (
		<div className="flex flex-row flex-wrap gap-4 text-xs">
			<div
				id={data?.product.productName}
				className="brandTitle flex flex-col relative w-fit gap-2 hover:scale-105 transition-all duration-300"
			>
				<CustomLink
					className={``}
					href={`${routerHandler({
						typename: data?.product?.__typename,
						slug: [
							portfolioSlug,
							data?.product?.productTypeSpecific?.slug,
							data?.product?.slug,
						],
						locale: locale,
					})}`}
				>
					{data?.product?.productName}
				</CustomLink>
				<span
					style={{ backgroundColor: color?.hex }}
					className="h-[1px] w-full"
				></span>
			</div>
			{data?.relatedProducts
				.filter((x: any) => x.id !== id)
				.map((entry: any, i: number) => (
					<div
						id={entry.productName}
						className="brandTitle flex flex-col relative w-fit gap-2 hover:scale-105 transition-all duration-300"
						key={i}
					>
						<CustomLink
							className={``}
							href={`${routerHandler({
								typename: entry?.__typename,
								slug: [
									portfolioSlug,
									entry?.productTypeSpecific?.slug,
									entry?.slug,
								],
								locale: locale,
							})}`}
						>
							{entry.productName}
						</CustomLink>
						<span
							style={{ backgroundColor: color?.hex }}
							className="h-[1px] w-full"
						></span>
					</div>
				))}
		</div>
	);
}
