import { useTranslation } from "next-i18next";
import { FeaturedTitle } from "@/components/Atoms/Title";
import { ImageLabel, Paragraph } from "@/components/Atoms/Paragraph";
import { NutriIcon } from "@/components/Atoms/Vectors";
import styles from "./index.module.scss";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";

interface Props {
	title: string;
	content: any;
	color: any;
	titleColor?: any;
	type?: string;
	underline?: any;
	nutritionalInfoFor100?: any;
	specificNutritional?: any;
	energetic?: boolean;
	highlight?: any;
}

export function ProductArticle({
	title,
	content,
	color,
	titleColor,
	underline,
	highlight,
}: Props) {
	const style = {
		"--highlight": highlight?.color?.hex,
	} as React.CSSProperties;
	return (
		<>
			<article
				style={style}
				className={`${
					highlight ? `${styles.highlightText}` : ``
				} flex flex-col gap-4`}
				id={title}
			>
				{title && (
					<div className="flex flex-col w-fit gap-4">
						<FeaturedTitle
							layout="left"
							color={titleColor ?? color}
							tag="h5"
							data={title}
						/>
						{underline?.[0] && (
							<>
								{underline[1]?.img ? (
									<CustomUnderline
										shape={underline[1]?.img}
										color={highlight?.color || underline[2]?.color}
									/>
								) : (
									<Underline color={highlight?.color || underline[2]?.color} />
								)}
							</>
						)}
					</div>
				)}
				{content && <Paragraph color={color} className={``} copy={content} />}
			</article>
		</>
	);
}

export function ProductTable({
	type,
	color,
	content,
	nutritionalInfoFor100,
	specificNutritional,
	underline,
	energetic,
	highlight,
}: Props) {
	const { t } = useTranslation();
	const style = {
		"--color": color?.hex,
		"--r": nutritionalInfoFor100?.[5]?.color.red,
		"--g": nutritionalInfoFor100?.[5]?.color.green,
		"--b": nutritionalInfoFor100?.[5]?.color.blue,
		"--rAlt": highlight?.color.red,
		"--gAlt": highlight?.color.green,
		"--bAlt": highlight?.color.blue,
	} as React.CSSProperties;

	return (
		<>
			<article className="flex flex-col gap-2" id={type}>
				{type == "nutritional" && (
					<div className={`  grid-cols-1  gap-y-4 grid`}>
						{energetic && (
							<div
								className={`${
									specificNutritional[1].length > 8 ||
									specificNutritional[5].length > 8
										? `grid-cols-1 sm:grid-cols-2`
										: `grid-cols-1  `
								} grid `}
							>
								{nutritionalInfoFor100 && (
									<div className=" flex flex-col">
										<div className="flex flex-col w-fit mb-2 pl-[4.5rem]">
											<ImageLabel
												copy={t("for100ml")}
												color={highlight?.color || color}
											/>
										</div>
										<div className="gap-4 flex flex-col">
											<div
												style={style as React.CSSProperties}
												className={` ${
													highlight
														? `${styles.bgColumnAlt}`
														: `${styles.bgColumn}`
												}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
											>
												<NutriIcon
													className="w-[3rem] aspect-square"
													type="alcohol"
													color={highlight?.color.hex || color?.hex}
												/>
												<div className="flex flex-col  gap-1">
													<Paragraph
														color={color}
														className="text-base py-0 font-bold "
														copy={t("alcohol")}
													/>
													<Paragraph
														color={color}
														className="text-base py-0 "
														copy={nutritionalInfoFor100[0].replace(
															/100mL|\/|/g,
															""
														)}
													/>
												</div>
											</div>
											<div
												style={style as React.CSSProperties}
												className={` ${
													highlight
														? `${styles.bgColumnAlt}`
														: `${styles.bgColumn}`
												}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
											>
												<NutriIcon
													className="w-[3rem] aspect-square"
													type="energy"
													color={highlight?.color.hex || color?.hex}
												/>
												<div className="flex flex-col  gap-1">
													<Paragraph
														color={color}
														className="text-base py-0 font-bold "
														copy={t("energeticValue")}
													/>
													<Paragraph
														color={color}
														className="text-base py-0 "
														copy={`${nutritionalInfoFor100[3].replace(
															/100mL|\/|/g,
															""
														)}/${nutritionalInfoFor100[2].replace(
															/100mL|\/|/g,
															""
														)}`}
													/>
												</div>
											</div>
											<div
												style={style as React.CSSProperties}
												className={` ${
													highlight
														? `${styles.bgColumnAlt}`
														: `${styles.bgColumn}`
												}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
											>
												<NutriIcon
													className="w-[3rem] aspect-square"
													type="sugar"
													color={highlight?.color.hex || color?.hex}
												/>
												<div className="flex flex-col gap-1">
													<Paragraph
														color={color}
														className=" py-0 font-bold "
														copy={t("sugar")}
													/>
													<Paragraph
														color={color}
														className=" py-0 "
														copy={nutritionalInfoFor100[1].replace(
															/100mL|\/|/g,
															""
														)}
													/>
												</div>
											</div>
										</div>
									</div>
								)}
								{specificNutritional[0] && (
									<>
										{specificNutritional[1].length > 8 && (
											<div className=" flex flex-col">
												<div className="flex flex-col w-fit mb-2 pl-[4.5rem] sm:pl-0 ">
													<ImageLabel
														copy={t("for60ml")}
														color={highlight?.color || color}
													/>
												</div>
												<div className="gap-4 flex flex-col">
													<div
														style={style as React.CSSProperties}
														className={` ${
															highlight
																? `${styles.bgColumnAlt}`
																: `${styles.bgColumn}`
														}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
													>
														<NutriIcon
															className="w-[3rem] aspect-square sm:hidden"
															type="alcohol"
															color={highlight?.color.hex || color?.hex}
														/>
														<div className="flex flex-col  gap-1">
															<Paragraph
																color={color}
																className="text-base py-0 font-bold "
																copy={t("alcohol")}
															/>
															<Paragraph
																color={color}
																className="text-base py-0 "
																copy={specificNutritional[1].replace(
																	/60mL|\/|/g,
																	""
																)}
															/>
														</div>
													</div>
													<div
														style={style as React.CSSProperties}
														className={` ${
															highlight
																? `${styles.bgColumnAlt}`
																: `${styles.bgColumn}`
														}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
													>
														<NutriIcon
															className="w-[3rem] aspect-square sm:hidden"
															type="energy"
															color={highlight?.color.hex || color?.hex}
														/>
														<div className="flex flex-col  gap-1">
															<Paragraph
																color={color}
																className="text-base py-0 font-bold "
																copy={t("energeticValue")}
															/>
															<Paragraph
																color={color}
																className="text-base py-0 "
																copy={`${specificNutritional[4].replace(
																	/60mL|\/|/g,
																	""
																)}/${specificNutritional[3].replace(
																	/60mL|\/|\./g,
																	""
																)}`}
															/>
														</div>
													</div>
													<div
														style={style as React.CSSProperties}
														className={` ${
															highlight
																? `${styles.bgColumnAlt}`
																: `${styles.bgColumn}`
														}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
													>
														<NutriIcon
															className="w-[3rem] aspect-square sm:hidden"
															type="sugar"
															color={highlight?.color.hex || color?.hex}
														/>
														<div className="flex flex-col gap-1">
															<Paragraph
																color={color}
																className=" py-0 font-bold "
																copy={t("sugar")}
															/>
															<Paragraph
																color={color}
																className=" py-0 "
																copy={specificNutritional[2].replace(
																	/60mL|\/|/g,
																	""
																)}
															/>
														</div>
													</div>
												</div>
											</div>
										)}
										{specificNutritional[5].length > 8 && (
											<div className="mb-6 flex flex-col">
												<div className="flex flex-col w-fit mb-2  pl-[4.5rem] sm:pl-0 ">
													<ImageLabel
														copy={t("for30ml")}
														color={highlight?.color || color}
													/>
												</div>
												<div className="gap-4 flex flex-col">
													<div
														style={style as React.CSSProperties}
														className={` ${
															highlight
																? `${styles.bgColumnAlt}`
																: `${styles.bgColumn}`
														}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
													>
														<NutriIcon
															className="w-[3rem] aspect-square sm:hidden"
															type="alcohol"
															color={highlight?.color.hex || color?.hex}
														/>
														<div className="flex flex-col  gap-1">
															<Paragraph
																color={color}
																className="text-base py-0 font-bold "
																copy={t("alcohol")}
															/>
															<Paragraph
																color={color}
																className="text-base py-0 "
																copy={specificNutritional[5].replace(
																	/30mL|\/|/g,
																	""
																)}
															/>
														</div>
													</div>
													<div
														style={style as React.CSSProperties}
														className={` ${
															highlight
																? `${styles.bgColumnAlt}`
																: `${styles.bgColumn}`
														}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
													>
														<NutriIcon
															className="w-[3rem] aspect-square sm:hidden"
															type="energy"
															color={highlight?.color.hex || color?.hex}
														/>
														<div className="flex flex-col  gap-1">
															<Paragraph
																color={color}
																className="text-base py-0 font-bold "
																copy={t("energeticValue")}
															/>
															<Paragraph
																color={color}
																className="text-base py-0 "
																copy={`${specificNutritional[8].replace(
																	/30mL|\/|/g,
																	""
																)}/${specificNutritional[7].replace(
																	/30mL|\/|\./g,
																	""
																)}`}
															/>
														</div>
													</div>
													<div
														style={style as React.CSSProperties}
														className={` ${
															highlight
																? `${styles.bgColumnAlt}`
																: `${styles.bgColumn}`
														}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
													>
														<NutriIcon
															className="w-[3rem] aspect-square sm:hidden"
															type="sugar"
															color={highlight?.color.hex || color?.hex}
														/>
														<div className="flex flex-col gap-1">
															<Paragraph
																color={color}
																className=" py-0 font-bold "
																copy={t("sugar")}
															/>
															<Paragraph
																color={color}
																className=" py-0 "
																copy={specificNutritional[6].replace(
																	/30mL|\/|/g,
																	""
																)}
															/>
														</div>
													</div>
												</div>
											</div>
										)}
									</>
								)}
							</div>
						)}
						<div className=" flex flex-col">
							<div className="flex flex-col w-fit mb-2  pl-[4.5rem]">
								<ImageLabel
									copy={t("other")}
									color={highlight?.color || color}
								/>
							</div>
							<div className=" grid grid-cols-1 sm:grid-cols-2 gap-y-4">
								{content[0] && (
									<div
										style={style as React.CSSProperties}
										className={` ${
											highlight ? `${styles.bgColumnAlt}` : `${styles.bgColumn}`
										}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
									>
										<NutriIcon
											className="w-[3rem] aspect-square"
											type="vegetarian"
											color={highlight?.color.hex || color?.hex}
										/>
										<div className="flex flex-col  gap-1">
											<Paragraph
												color={color}
												className="text-base py-0 font-bold "
												copy={t(`suitableVegetarians`)}
											/>
										</div>
									</div>
								)}
								{content[1] && (
									<div
										style={style as React.CSSProperties}
										className={` ${
											highlight ? `${styles.bgColumnAlt}` : `${styles.bgColumn}`
										}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
									>
										<NutriIcon
											className="w-[3rem] aspect-square"
											type="vegan"
											color={highlight?.color.hex || color?.hex}
										/>
										<div className="flex flex-col  gap-1">
											<Paragraph
												color={color}
												className="text-base py-0 font-bold "
												copy={t(`suitableVegans`)}
											/>
										</div>
									</div>
								)}

								{content[2] && (
									<div
										style={style as React.CSSProperties}
										className={` ${
											highlight ? `${styles.bgColumnAlt}` : `${styles.bgColumn}`
										}   col-span-1 flex flex-row items-center gap-x-6 lg:gap-x-4`}
									>
										<NutriIcon
											className="w-[3rem] aspect-square"
											type="allergens"
											color={highlight?.color.hex || color?.hex}
										/>
										<div className="flex flex-col  gap-1">
											<Paragraph
												color={color}
												className="text-base py-0 font-bold "
												copy={t(`allergens`)}
											/>
											<Paragraph
												color={color}
												className="text-base py-0 "
												copy={
													content[2] ? `${t("containsSulfites")}` : `${t("no")}`
												}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
