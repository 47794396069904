import { ImageLabel, Label } from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";
import { useTranslation } from "next-i18next";

interface Props {
  data: any;
  config: any;
}

export default function IngredientsBlock({ data, config }: Props) {
  const { t } = useTranslation();
  return (
    <div className=" flex flex-col gap-4 pb-12">
      <Title
        tag={"h3"}
        data={t("ingredients")}
        color={config?.headlineTextColor.color}
      />
      {data.ingredients.map((main: any, i: number) => (
        <div key={i}>
          {main.headline && <Label
            copy={main.headline.label}
            color={config?.highlightTextColor.color}
          />}
          <div className="flex flex-col gap-2">
            {main.ingredient.map((i: any, index: number) => (
              <div
                style={{
                  borderColor: config?.highlightTextColor.color?.hex,
                  borderBottom: `1px solid ${config?.highlightTextColor.color?.hex}`,
                }}
                className="border-b flex flex-row justify-between"
                key={index}
              >
                <ImageLabel
                  copy={`&#x2022; ${i.ingredient?.ingredientName}`}
                  color={config?.highlightTextColor.color}
                />
                <ImageLabel
                  copy={i.measures.label}
                  color={config?.highlightTextColor.color}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
