import dynamic from "next/dynamic";
import { BrandGrid } from "@/components/Molecules/BrandsGrid";
import { cva } from "class-variance-authority";

const LogotypeCard = dynamic(() =>
	import("@/components/Molecules/LogotypeCard").then((c) => c.LogotypeCard)
);

const SubSectionHeader = dynamic(() =>
	import("@/components/Molecules/SubSectionHeader").then(
		(c) => c.SubSectionHeader
	)
);

const BrandImagesVariants = cva(`relative mx-auto w-full `, {
	variants: {
		layout: {
			fullWidth: "",
			container: "container",
		},
		useSectionMargins: {
			true: "py-6 md:py-12",
			false: "py-0",
		},
		useExtraTopMargins: {
			true: "pt-12 pb-12 md:pt-24 md:pb-24",
			false: "",
		},
		useExtraPadding: {
			true: "pb-32 lg:pb-32",
			false: "",
		},
	},
	defaultVariants: {
		useSectionMargins: false,
		useExtraTopMargins: false,
		useExtraPadding: false,
	},
});

export default function SectionLogotype(data) {
	return (
		<div
			className="relative"
			style={{ backgroundColor: data?.bgColor?.color?.hex }}
		>
			{data?.logotype?.lenght == 0 ? (
				<div className="my-24">
					<SubSectionHeader data={data} />
					<div className="sm:flex flex-wrap justify-center items-top text-center gap-x-10 gap-y-5">
						{data.products &&
							data.products.map((entry) => (
								<LogotypeCard key={entry.id} data={entry} />
							))}
					</div>
				</div>
			) : (
				<div
					className={`${BrandImagesVariants({
						layout: data?.layout,
						useSectionMargins: data?.useSectionMargins,
						useExtraTopMargins: data?.useExtraTopMargins,
						useExtraPadding: data?.useExtraPadding,
					})} `}
				>
					<BrandGrid brands={data.logotype} iconColor={data.iconsColor?.hex} />
				</div>
			)}
		</div>
	);
}

export const fragment = `fragment LogotypeFragment on SectionLogotypeRecord{
    id
     bgColor{
      color{hex}
    }
    iconsColor{
      color{hex}
    }
    title
    layout
    useExtraPadding
    useExtraTopMargins
    useSectionMargins
    logotype {
      ... on ProductBrandRecord {
        id
        primaryLogotypeProductBrand {
          url
          width
          height
          alt
        }
      }
    }
  }
`;

/* products {
        id
        description
        logo {
          id
          url
          width
          height
        }
      } */
