import Head from "next/head";

export default function HrefLangs({
  links,
}: {
  links: { href: string; hrefLang: string; rel: string }[];
}) {
  return (
    <Head>
      {links?.map((link, i) => (
        <link
          key={i}
          rel={link.rel}
          href={link.href}
          hrefLang={link.hrefLang}
        />
      ))}
    </Head>
  );
}
