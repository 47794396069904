import { TimelineRange } from "@/components/Molecules/TimelineRange";
import { TimelineVertical } from "@/components/Molecules/TimelineVertical";
import { cva } from "class-variance-authority";

const TimelineVariants = cva(`relative mx-auto w-full `, {
	variants: {
		layout: {
			full: "",
			container: "container",
		},
		useSectionMargins: {
			true: "py-6 md:py-12",
			false: "py-0",
		},
		useExtraTopMargins: {
			true: "pt-12 pb-6 md:pt-24 md:pb-12",
			false: "",
		},
		useExtraPadding: {
			true: "pb-32 lg:pb-32",
			false: "",
		},
	},
	defaultVariants: {
		useSectionMargins: false,
		useExtraTopMargins: false,
		useExtraPadding: false,
	},
});

export default function SectionTimeline(data: any) {
	return (
		<div
			className="relative"
			style={{ backgroundColor: data?.blockBackgroundColor?.color?.hex }}
		>
			<div
				className={`${TimelineVariants({
					layout: data?.layout,
					useSectionMargins: data?.useSectionMargins,
					useExtraTopMargins: data?.useExtraTopMargins,
					useExtraPadding: data?.useExtraPadding,
				})} `}
			>
				{data.timelineType === "default" ? (
					<TimelineRange
						historyMoments={data.historyMoments}
						textColor={data?.textColor?.color}
						title={data.title}
						titleColor={data.titleColor?.color}
						yearColor={data?.yearColor?.color}
					/>
				) : (
					<TimelineVertical
						historyMoments={data.historyMoments}
						textColor={data?.textColor?.color}
						title={data.title}
						titleColor={data.titleColor?.color}
						yearColor={data?.yearColor?.color}
						titleHistoryColor={data.titleHistoryColor?.color}
					/>
				)}
			</div>
		</div>
	);
}

export const fragment = `
fragment SectionTimelineFragment on SectionTimelineRecord {
	id
    title
	layout
	timelineType
    useExtraBottomMargins
    useExtraTopMargins
    useSectionMargins
    historyMoments {
      id
      year
	  title
	  addRangeOfYears
	  rangeYear
      description
      image {
        responsiveImage {
          alt
          src
          sizes
          height
          width
        }
      }
    }
	titleColor {
		color {
		  hex
		}
	  }
	titleHistoryColor {
		color {
		  hex
		}
	  }
	yearColor {
		color {
		  hex
		}
	  }
	textColor {
		color {
		  hex
		}
	  }
	blockBackgroundColor {
		color {
		  hex
		}
	  }
  }
  `;
