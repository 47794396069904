import { VariantProps, cva } from "class-variance-authority";
import { OverlayShadow } from "@/components/Molecules/OverlayShadow/Overlay";
import { FullBackgroundImageHero } from "@/components/Molecules/ImageCard";
import { ImageCopyCard } from "@/components/Molecules/HeroCopyCard";
import { useRef } from "react";
import { PageScroll } from "@/components/Atoms/PageScroll";
import { HeroAnchors } from "@/components/Molecules/Anchors";
import { Shape } from "@/components/Atoms/Spacer";
import SectionText from "../../Text";
import GeoWrapper, {
	GeoHandler,
} from "@/components/Templates/Main/GeoConditional";

export type HeroImageVariantProps = VariantProps<typeof HeroImageVariants>;
const HeroImageVariants = cva(` relative flex flex-col  `, {
	variants: {
		heroHeight: {
			small: "min-h-[200px] lg:min-h-[350px] pt-[50px]",
			tall: `flex flex-row items-center min-h-[350px] md:h-[650px]`,
			content: `pt-24`,
			default: "min-h-[60vh] md:min-h-[90vh] lg:min-h-[100vh] flex flex-col",
		},
		textFlexPosition: {
			start: "justify-start",
			end: "justify-end",
			center: "justify-center",
		},
	},
	defaultVariants: {
		heroHeight: "default",
		textFlexPosition: "center",
	},
});

interface Props {
	id: string;
	bgColor: any;
	heroHeight: "small" | "tall" | "content" | "default";
	textFlexPosition: "start" | "end" | "center";
	textAlignment: string;
	imageOverlayBaseColor: any;
	overlay: string;
	swapBgForColor: boolean;
	swapTitleForImage: boolean;
	imageReplacerPosition: string;
	image: any;
	imageReplacer: any;
	title: string;
	description: string;
	label: string;
	textColor: any;
	labelColor: any;
	textWidth: string;
	useLabelBeforeTitle: boolean;
	underline: any;
	textAlign: string;
	addScrollIcon: boolean;
	scrollIcon: any;
	scrollIconType: string;
	iconColor: any;
	addTitleUnderline: boolean;
	customUnderlineAsset: any;
	underlineColor: any;
	addCustomIconBackgroundElement: boolean;
	iconBackgroundAsset: any;
	iconBackgroundColor: any;
	customScrollIcon: any;
	anchors: any;
	anchorsLabelColor: any;
	anchorsType: string;
	anchorsStyleColor: any;
	addSpacer: boolean;
	customShape: any;
	spacerDirection: "inner" | "outer" | "merge";
	spacerConfiguration: any;
	addAdditionalContent: boolean;
	additionalContent: any;
	imageGeo: any;
	alternativeImage: any;
}

export default function HeroImage({
	textFlexPosition,
	heroHeight,
	spacerDirection,
	...props
}: Props) {
	const scrollRef: any = useRef(null);
	const executeScroll = () =>
		scrollRef.current.scrollIntoView({
			block: "start",
			behavior: "smooth",
			inline: "start",
		});

	function heroImage(image: any) {
		if (!props.image) return null;

		const geo = props.imageGeo[0];
		const isToShow = GeoHandler({
			list: geo?.listOfCountries,
			country: geo?.countries,
			initial: geo?.countryAvailability,
		});
		const heroImage = isToShow ? props.image : props.alternativeImage;

		return <FullBackgroundImageHero padding="90px" data={heroImage} />;
	}

	return (
		<>
			<div
				id={props.id}
				style={{ backgroundColor: props?.bgColor?.color.hex }}
				className={` ${HeroImageVariants({
					heroHeight,
					textFlexPosition,
				})} relative`}
			>
				{props.overlay && (
					<OverlayShadow
						format={props.textAlignment}
						data={props.overlay}
						color={props.imageOverlayBaseColor}
						layout={props.textAlignment}
					/>
				)}
				{props.swapBgForColor ? (
					<></>
				) : (
					<>
						{heroImage(props.image)}
						{/* {props.image && (
							<FullBackgroundImageHero padding="90px" data={props.image} />
						)} */}
					</>
				)}

				<ImageCopyCard
					className={`${
						props.spacerConfiguration?.spacerDirection == "merge"
							? " mn-12 lg:mb-20"
							: ""
					} `}
					flex={textFlexPosition}
					title={props.title}
					description={props.description}
					label={props.label}
					swapTitle={props.swapTitleForImage}
					imageReplacer={props.imageReplacer}
					imagePosition={props.imageReplacerPosition}
					color={props.textColor?.color}
					labelColor={props.labelColor?.color}
					layout={props.textAlignment}
					width={props.textWidth}
					labelPosition={props.useLabelBeforeTitle}
					underline={[
						props.addTitleUnderline,
						props.customUnderlineAsset,
						props.underlineColor,
					]}
				/>

				{props.addSpacer ? (
					<>
						{props.addScrollIcon && (
							<div className={``} ref={scrollRef}>
								<PageScroll
									onClick={() => executeScroll()}
									spacer={props.addSpacer}
									type={props.scrollIcon?.scrollIconType}
									color={props.scrollIcon?.iconColor?.color}
									bg={[
										props.scrollIcon?.addCustomIconBackgroundElement,
										props.scrollIcon?.iconBackgroundAsset,
										props.scrollIcon?.iconBackgroundColor?.color,
									]}
									custom={props.scrollIcon?.customScrollIcon.img}
									featured={props.scrollIcon?.iconFeatured}
								/>
							</div>
						)}
						<Shape
							shape={props.spacerConfiguration?.customShape}
							color={props.bgColor}
							direction={props.spacerConfiguration?.spacerDirection}
							icon={[
								props.spacerConfiguration?.addIconElement,
								props?.spacerConfiguration?.spacerIcon,
							]}
							position={props.spacerConfiguration?.spacerPosition}
							invert={false}
						/>
					</>
				) : (
					<>
						{props.addScrollIcon && (
							<div
								className={`absolute bottom-0 h-[45px] z-10 w-full left-0 right-0`}
								ref={scrollRef}
							>
								<PageScroll
									onClick={() => executeScroll()}
									spacer={props.addSpacer}
									type={props.scrollIcon?.scrollIconType}
									color={props.scrollIcon?.iconColor?.color}
									bg={[
										props.scrollIcon?.addCustomIconBackgroundElement,
										props.scrollIcon?.iconBackgroundAsset,
										props.scrollIcon?.iconBackgroundColor?.color,
									]}
									custom={props.scrollIcon?.customScrollIcon.img}
									featured={props.scrollIcon?.iconFeatured}
								/>
							</div>
						)}
					</>
				)}
			</div>
			{props.anchors && (
				<HeroAnchors
					data={props.anchors}
					color={props.anchorsLabelColor?.color}
					type={props.anchorsType}
					styleColor={props.anchorsStyleColor}
					bg={props.bgColor?.color}
				/>
			)}
			{props.addAdditionalContent && (
				<SectionText data={props.additionalContent} />
			)}
		</>
	);
}

export const fragment = `
    fragment HeroImageFragment on SectionHeroImageRecord{
      __typename
      id
      name
      title
      description
      addAdditionalContent
      additionalContent {
      __typename
      id
      addTitle
      title
      addTitleUnderline
      customUnderlineAsset {
        img {
          url
          width
          height
        }
      }
      underlineColor {
        color {
          hex
        }
        name
      }
      text(markdown: true)
      layout
      textAlignment
      textColumnLayout
      textColumnDisplay
      useSectionMargins
      useExtraSectionMargins
	  useExtraBottomSectionMargins
      textColor{
        color{
          hex
        }
      }
    bgColor{
      color{
        hex
      }
    }
      }
      textColor{
        color{
          hex
        }
      }
      labelColor{
        color{
          hex
        }
      }
      heroHeight
      showQuote
      label
        overlay
        imageOverlayBaseColor {
          hex
        }
        textWidth
        textAlignment
        textFlexPosition
        useLabelBeforeTitle
        swapTitleForImage
        imageReplacerPosition
        bgColor{
          color{
            hex
          }
        }
        swapBgForColor
        addSpacer
        spacerConfiguration {
          id
          spacerDirection
          spacerPosition
          customShape {
            img {
              alt
              url
              width
              height
            }
          }
          addIconElement
          spacerIcon {
            img {
              alt
              url
              width
              height
            }
          }
        }
    addScrollIcon
    scrollIcon {
      scrollIconType
      iconColor {
        color {
          hex
        }
      }
      iconBackgroundColor{
        color{
          hex
        }
      }
      addCustomIconBackgroundElement
      iconBackgroundAsset{
        img{
          url
        }
      }
      customScrollIcon{
        img{
          url
        }
      }
	  iconFeatured
    }
        addTitleUnderline
        underlineColor{
          color{
            hex
          }
        }
        customUnderlineAsset{
          img{
            url
            width
            height
          }
        }
        anchors {
          ... on SectionImageRecord {
            id
            title
          }
          ... on SectionTextGalleryRecord {
            id
            title
          }
          ... on SectionTextImageRecord {
            id
            title
          }
          ... on SectionBrandArticleRecord{
            id
            title          
          }
        }
        anchorsType
        anchorsLabelColor{
          color{
            hex
          }
        }
        anchorsStyleColor{
          color{
            hex
          }
        }
        useSpacer
        imageReplacer {
          title
          alt
          url
          format
          responsiveImage {
            ... responsiveImageFragment
          }
        }
      image {
        id
        title
        alt
        tags
        format
        focalPoint {
          y
          x
        }
        responsiveImage (imgixParams: { fit: crop, minW: "1500", crop: focalpoint, ar: auto, auto:format, q:80 }) {
          ... responsiveImageFragment
        }
      }
      imageGeo {
        countryAvailability
        listOfCountries
        countries {
          countryCode
        }
      }
      alternativeImage {
        id
        title
        alt
        tags
        format
        focalPoint {
          y
          x
        }
        responsiveImage (imgixParams: { fit: crop, minW: "1500", crop: focalpoint, ar: auto, auto:format, q:80 }) {
          ... responsiveImageFragment
        }
      }
    }
`;
