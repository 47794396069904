import { MultiTextComponent } from "@/components/Atoms/MultiText";
import styles from "./index.module.scss";
import { MultiPurposeTitle } from "@/components/Atoms/Title";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import { VariantProps, cva } from "class-variance-authority";
import { Shape } from "@/components/Atoms/Spacer";
import Image from "@/components/Atoms/Image";

export type SectionTextVariantProps = VariantProps<typeof SectionTextVariants>;

const SectionTextVariants = cva(``, {
	variants: {
		textColumnDisplay: {
			default: "items-start",
			center: "items-center",
			right: "items-end",
		},
		layout: {
			fullWidth: "",
			container: "max-w-[1360px]",
			smallContainer: "lg:max-w-[75%]",
		},
		textAlignment: {
			center: "lg:items-center",
			fixedCenter: "items-center",
			left: "items-start",
			rigth: "items-end",
		},
		textColumnLayout: {
			single: "",
			double: "",
		},
		sectionMargins: {
			true: "py-6 md:py-12",
			false: "py-0",
		},
		bottomMargins: {
			true: "pb-32",
			false: "",
		},
		extraSectionMargins: {
			true: "py-6 md:py-20 lg:py-24",
			false: "",
		},
	},
	compoundVariants: [],
	defaultVariants: {},
});

export default function SectionText(data: any) {
	if (data.data) {
		data = data.data;
	}

	const columnLayout = data.textColumnLayout?.toLowerCase();
	const RenderTag: any = `${data.layout}${columnLayout}`;

	return (
		<RenderTag is="x3d" customAttr="0.3">
			<div
				style={{ backgroundColor: data.bgColor?.color.hex }}
				className="w-full relative"
			>
				{data.addSpacer && (
					<Shape
						shape={data.spacerConfiguration?.customShape}
						color={data.bgColor}
						direction={data.spacerConfiguration?.spacerDirection}
						position={data.spacerConfiguration?.spacerPosition}
						icon={[
							data.spacerConfiguration?.addIconElement,
							data?.spacerConfiguration?.spacerIcon,
						]}
						invert={false}
					/>
				)}
				<div
					className={`container flex gap-12 relative mx-auto w-full px-6 lg:px-2   flex-col ${SectionTextVariants(
						{
							sectionMargins: data.useSectionMargins,
							textColumnLayout: data.textColumnLayout,
							extraSectionMargins: data.useExtraSectionMargins,
							textColumnDisplay: data.textColumnDisplay,
							bottomMargins: data.useExtraBottomSectionMargins,
						}
					)} `}
				>
					{data.addIcon && (
						<Image
							className={`hidden md:block absolute m-auto left-0 bottom-0 md:h-[100px] md:w-[96px] lg:h-auto lg:w-auto z-10`}
							data={data.customIcon?.img}
						/>
					)}

					<div
						className={`flex flex-col gap-4 md:gap-8 ${SectionTextVariants({
							layout: data.layout,
							textAlignment: data.textAlignment,
						})} `}
					>
						{data.addTitle && (
							<div className="flex flex-col w-fit gap-4">
								<MultiPurposeTitle
									type="excerpt"
									tag="h2"
									data={data.title}
									color={data.textColor?.color}
								/>
								{data.addTitleUnderline && (
									<>
										{data.customUnderlineAsset?.img ? (
											<CustomUnderline
												shape={data.customUnderlineAsset?.img}
												color={data.underlineColor?.color}
											/>
										) : (
											<Underline color={data.underlineColor?.color} />
										)}
									</>
								)}
							</div>
						)}
						<div
							className={`${
								data.textColumnLayout !== "single"
									? `${styles.doubleColumn}`
									: `${styles.singleColumn}`
							}`}
						>
							<MultiTextComponent
								data={data.text}
								color={data.textColor?.color}
								layout={data?.textAlignment}
								columns={data?.textColumnLayout}
							/>
						</div>
					</div>
				</div>
			</div>
		</RenderTag>
	);
}

export const fragment = `
    fragment TextFragment on SectionTextRecord{
      __typename
      id
      addTitle
      title
      addTitleUnderline
      customUnderlineAsset {
        img {
          url
          width
          height
        }
      }
      underlineColor {
        color {
          hex
        }
        name
      }
	  addIcon
	  customIcon {
		img {
		  alt
		  url
		  width
		  height
		}
	  }
	  customIconColor{
		color{
		  hex
		}
	  }
      addSpacer
      spacerConfiguration {
        id
        spacerDirection
        spacerPosition
        customShape {
          img {
            alt
            url
            width
            height
          }
        }
        addIconElement
        spacerIcon {
          img {
            alt
            url
            width
            height
          }
        }
      }
      text(markdown: true)
      layout
      textAlignment
      textColumnLayout
      textColumnDisplay
      useSectionMargins
      useExtraSectionMargins
	  useExtraBottomSectionMargins
      textColor{
        color{
          hex
        }
      }
    bgColor{
      color{
        hex
      }
    }
  }
`;
