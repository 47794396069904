import { ButtonMaster } from "@/components/Atoms/Buttons";
import { ImageLabel } from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";
import { useTranslation } from "next-i18next";
import { useContext, useState } from "react";
import router from "next/router";
import styles from "./index.module.scss";
import { MultiTextComponent } from "@/components/Atoms/MultiText";
import Notification from "@/organisms/Notification";
import NotificationContext from "@/lib/functions";

interface Props {
  data: any;
}

export function HubSpotForm({ data }: Props) {
  const { t } = useTranslation();
  const today = Date.now();

  const [submit, setSubmit] = useState(false);
  const [fieldState, setFieldState] = useState({});
  const [errors, setErrorsState] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const notificationCtx: any = useContext(NotificationContext);

  const activeNotification: any = notificationCtx.Notification;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setSubmit(true);
    const formData = new FormData(e.target);
    const stateValue = formData.get("state");
    console.log('stateValue', stateValue);

    let response = await fetch("/api/contest", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        id: data.id,
        language: router.locale,
        hubspotPortalId: data.hubspotPortalId,
        hubspotFormGuid: data.hubspotFormGuid,
        ...fieldState,
        // get field state from form
        "state": stateValue,
      }),
    });
    const json = await response.json();

    if (response.status === 422) {
      setErrorsState(json);
      setSubmit(false);
    }

    if (response.status === 403) {
      setSubmit(false);
      console.log(json, today);
      notificationCtx.showNotification({
        title: `${t("Thank you for participating")}`,
        message: `We already received your participation successfully. Please wait until the next day to submitting again.`,
        status: "wait",
      });
    }

    if (response.status === 200) {
      setSubmitted(true);
      setSubmit(false);
      notificationCtx.showNotification({
        title: `${data.successTitle?.label}`,
        message: `${data.successLabel?.label}`,
        status: "success",
      });
    }
    if (response.status === 404) {
      setSubmit(false);
      notificationCtx.showNotification({
        title: `${data.errorTitle?.label}`,
        message: `${data.errorLabel?.label}`,
        status: "error",
      });
    }
    if (response.status === 500) {
      setSubmit(false);
      notificationCtx.showNotification({
        title: `${data.errorTitle?.label}`,
        message: `${data.errorLabel?.label}`,
        status: "error",
      });
    }
  };

  const handleChange = (e: any) => {
    const newFieldState: any = fieldState;
    
    if (e.target.multiple) {
      const values = Array.from(
        e.target.selectedOptions,
        (option: any) => option.value
      );
      newFieldState[e.target.name] = values;
    } else {
      newFieldState[e.target.name] = e.target.value;
    }
    console.log(newFieldState);

    setFieldState(newFieldState);
  };

  const handleCheckboxChange = (e: any) => {
    const newFieldState: any = fieldState;
    if (e.target.checked) {
      newFieldState[e.target.name] = e.target.checked;
    } else {
      delete newFieldState[e.target.name];
    }

    setFieldState(newFieldState);
  };

  const asteriskToLabel = (field: any) => {
    return t(field.label) + (field.required ? "*" : "");
  };

  const style = {
    "--color": data.textColor?.color.hex,
    "--bg": data.bgColor?.color.hex,
    "--style": data.typeStyleColor?.color.hex,
    "--img": `url(${data.backgroundImage?.url})`,
  } as React.CSSProperties;

  return (
    <form
      action="/api/contest"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
      className="flex flex-col gap-2 w-[80%] md:w-[60%] mx-auto"
    >
      {!activeNotification && (
        <>
          <div className="mb-6">
            <Title
              tag="h3"
              data={data.title}
              color={data.textColor?.color}
              layout={`${data.sidebar ? "left" : "fixedCenter"}`}
            />
            <MultiTextComponent
              data={data.description}
              color={data?.textColor?.color}
            />
          </div>

          {data.field &&
            data.field.map((entry: any, i: number) => {
              let control = <div key={i} className="relative"></div>;
              let formGroupClass = null;
              if (
                entry.__typename === "TextInputRecord" ||
                entry.__typename === "EmailInputRecord"
              ) {
                return (
                  <div key={i} className="relative mb-6">
                    <label
                      style={style}
                      className={styles.label}
                      htmlFor={entry.id}
                    >
                      {entry.placeholder}
                    </label>
                    <input
                      style={style}
                      id={entry.id}
                      className={`${styles.formControl}`}
                      name={entry.attributecode}
                      required={entry.required}
                      onChange={handleChange}
                      type={
                        entry.__typename === "TextInputRecord"
                          ? "text"
                          : "email"
                      }
                    />
                  </div>
                );
              } else if (entry.__typename === "TextareaInputRecord") {
                control = (
                  <textarea
                    rows={6}
                    name={entry.attributecode}
                    onChange={handleChange}
                  ></textarea>
                );
              } else if (entry.__typename === "CheckboxInputRecord") {
                control = (
                  <div>
                    <label
                      style={style}
                      className={`${styles.formCheck} flex flex-row text-left align-center gap-2`}
                    >
                      <input
                        value={entry.attributecode}
                        onChange={handleCheckboxChange}
                        name={entry.attributecode}
                        required={entry.required}
                        type="checkbox"
                      />
                      <span style={style} className={styles.check}></span>
                      <ImageLabel
                        copy={asteriskToLabel(entry)}
                        color={data.textColor?.color}
                      />
                    </label>
                  </div>
                );
              } else if (entry.__typename === "DropdownInputRecord") {
                let options = entry.options.split(",");
                let values = entry.value.split(",");
                control = (
                  <select
                    style={style}
                    className={`${styles.formControlDrop}`}
                    name={entry.attributecode}
                    required={entry.required}
                    onChange={handleChange}
                  >
                    <option value="">{asteriskToLabel(entry)}</option>
                    {options &&
                      options.map((option: any, i: number) => {
                        return (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        );
                      })}
                  </select>
                );
              }

              return (
                <div key={i} className={"form-group"}>
                  {control}
                </div>
              );
            })}
          <ImageLabel
            className="text-left"
            copy={data.disclaimer}
            color={data.textColor?.color}
          />
          <div className="flex justify-start mt-4 lg:pb-12">
            <ButtonMaster
              submit={submit}
              type={data.submitButtonType}
              color={data.labelColor?.color}
              cta={data?.typeStyleColor?.color}
              link={undefined}
              customIcon={data.icon?.img}
              customColor={data.iconColor?.color}
            >
              {!submit ? t(data.submitLabel) : t("sending")}
            </ButtonMaster>
          </div>
        </>
      )}
      {activeNotification && (
        <Notification
          title={activeNotification.title}
          message={activeNotification.message}
          status={activeNotification.status}
          color={data.fontColor}
        />
      )}
    </form>
  );
}
