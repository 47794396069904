import { Paragraph, ParagraphNumber } from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";
import { ButtonMaster } from "@/components/Atoms/Buttons";

interface ArticleCardProps {
	layout: string;
	post: any;
	cta: any;
	color: any;
	excerpt: any;
	className?: string;
}

export default function ArticleCard({
	layout,
	post,
	color,
	excerpt,
	cta,
	className,
}: ArticleCardProps) {
	switch (layout) {
		case "card":
			return (
				<article className="col-span-2 bg-white p-4 px-6 gap-2 flex flex-col">
					<Title
						className={className}
						tag={"h3"}
						data={post.title}
						color={color?.color}
					/>
					<Paragraph copy={post.excerpt} color={excerpt?.color} />
					<ButtonMaster
						type={cta?.buttonStyle}
						cta={cta?.styleColor?.color}
						color={cta?.labelColor?.color}
						customIcon={cta?.icon?.img ?? cta?.icon}
						customColor={cta?.iconColor?.color}
						link={`news/${post.slug}
				`}
					>
						{cta?.buttonLabel?.label}
					</ButtonMaster>
				</article>
			);
			break;
		case "default":
			return (
				<article className="col-span-2 p-4 px-6 gap-2 flex flex-col">
					<Title
						className={className}
						tag={"h4"}
						data={post.title}
						color={color?.color}
					/>
					<ParagraphNumber
						copy={post.excerpt}
						color={excerpt?.color}
						className="md:overflow-hidden md:h-32"
					/>
					<ButtonMaster
						type={cta?.buttonStyle}
						cta={cta?.styleColor?.color}
						color={cta?.labelColor?.color}
						customIcon={cta?.icon?.img ?? cta?.icon}
						customColor={cta?.iconColor?.color}
						link={`news/${post.slug}
				`}
					>
						{cta?.buttonLabel?.label}
					</ButtonMaster>
				</article>
			);
			break;
		default:
			return <></>;
			break;
	}
}
