import {
  TestimonialMain,
  TestimonialList,
} from "@/components/Molecules/TestimonialCard";

export default function SectionTestimonial(data: any) {
  if (!data.layout) {
    return (
      <div
        style={{ backgroundColor: data.blockBackgroundColor?.color.hex }}
        className={` relative `}
      >
        <TestimonialMain
          description={data.description}
          shape={data.customShape}
          bgColor={data.descriptionBackgroundColor}
          color={data.descriptionTextColor}
          subColor={data.testimonialTextColor}
          layout={data.layout}
          asset={data.asset}
        />
        <TestimonialList
          testimonials={data.testimonials}
          color={data.testimonialTextColor}
          bgColor={data.testimonialBackgroundColor}
          layout={data.layout}
          shape={undefined}
        />
      </div>
    );
  } else {
    return <div className="section pt-6 lg:pt-12 pb-12 lg:pb-32"></div>;
  }
}

export const fragment = `
    fragment TestimonialSectionFragment on TestimonialSectionRecord{
      __typename
      id
      title
      description
      showHeader
      layout
      useSectionMargins
      useExtraBottomMargins
      customShape {
        img {
          url
          width
          height
        }
      }
      blockBackgroundColor {
        color {
          hex
        }
      }
      descriptionBackgroundColor {
        color {
          hex
        }
      }
      testimonialBackgroundColor {
        color {
          hex
        }
      }
      testimonialTextColor {
        color {
          hex
        }
      }
      descriptionTextColor {
        color {
          hex
        }
      }
      testimonials {
        id
        title
        statement
      }
      asset {
        responsiveImage (imgixParams: { fit: crop, w: 250, crop: focalpoint, auto:format, q:60}) {
          ... responsiveImageFragment
        }
        title
      }
  }
`;
