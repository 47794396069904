import { createContext, useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { Locales } from "./types";
import { LocaleRouteTypes, dynamicPagesConfig } from "./dynamic-pages-config";
import Script from "next/script";

export function formatDate(dateString: any) {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
	const year = date.getFullYear();

	return `${day}/${month}/${year}`;
}

// USESCRIPT

export const useScript = (url: any, ref: any, params: any) => {
	useEffect(() => {
		const script = document.createElement("script");

		script.src = url;
		script.async = true;

		// Add parameters to the script
		Object.keys(params).forEach((key) => {
			script.setAttribute(key, params[key]);
		});

		if (ref.current) {
			ref.current.appendChild(script);
		}

		return () => {
			ref?.current?.removeChild(script);
		};
	}, [url, ref]); // Add params to the dependency array
};

interface PropsBot {
	cookieBotId: any;
}

export function CookieList({ cookieBotId }: PropsBot) {
	{
		const ref: any = useRef(null);
		const router: any = useRouter();
		console.log("router locale", router.locale);
		useScript(`https://consent.cookiebot.com/${cookieBotId}/cd.js`, ref, {
			id: "CookieDeclaration",
			"data-culture": router.locale,
		});
		return <div ref={ref}></div>;
	}

	return <></>;
}

//NOTIFICATION PROVIDER FUNCTION

const NotificationContext = createContext({
	Notification: null,
	showNotification: function (notificationData: any) {},
	hideNotification: function () {},
});

export function NotificationContextProvider(props: any) {
	const [activeNotification, setActiveNotification]: any = useState();

	useEffect(() => {
		if (
			activeNotification &&
			(activeNotification.status === "success" ||
				activeNotification.status === "error")
		) {
			const timer = setTimeout(() => {
				setActiveNotification(null);
			}, 5000);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [activeNotification]);

	function showNotificationHandler(notificationData: any) {
		setActiveNotification(notificationData);
	}
	function hideNotificationHandler() {
		setActiveNotification(null);
	}
	const context = {
		Notification: activeNotification,
		showNotification: showNotificationHandler,
		hideNotification: hideNotificationHandler,
	};
	return (
		<NotificationContext.Provider value={context}>
			{props.children}
		</NotificationContext.Provider>
	);
}

export default NotificationContext;

// FRAMER CONSTS VARIANTS //

export function heroSlider() {
	var viewPortWidth: number = 0;
	if (typeof window !== "undefined") {
		var viewPortWidth: number = window.innerWidth;
	}

	if (viewPortWidth < 768) {
		var variants = {
			hidden: {
				rotate: 0.001,
				x: `0px`,
				transition: { duration: 0.5, ease: "easeOut" },
			},
			visible: {
				rotate: `0.01deg`,
				x: `0px`,
				transition: { duration: 0.5, ease: "easeOut", delay: 0.1 },
			},
		};
	} else {
		var variants = {
			hidden: {
				rotate: 0,
				x: `500px`,
				transition: { duration: 0, ease: "easeOut" },
			},
			visible: {
				rotate: `0.01deg`,
				x: `0px`,
				transition: { duration: 0.8, ease: "easeOut", delay: 0.2 },
			},
		};
	}
	return variants;
}

export function slideUp() {
	const variants = {
		hidden: {
			opacity: 0,
			y: `2rem`,
			transition: { duration: 1, ease: "easeOut" },
		},
		visible: {
			opacity: 1,
			y: 0,
			transition: { duration: 1, ease: "easeOut" },
		},
		exit: {
			opacity: 0,
			y: `2rem`,
			transition: { duration: 1, ease: "easeOut" },
		},
	};
	return variants;
}

export function slideIn() {
	const variants = {
		hidden: {
			opacity: 0,
			x: `-2rem`,
			transition: { duration: 1, ease: "easeOut" },
		},
		visible: {
			opacity: 1,
			x: 0,
			transition: { duration: 1, ease: "easeOut" },
		},
		exit: {
			opacity: 0,
			x: `-2rem`,
			transition: { duration: 1, ease: "easeOut" },
		},
	};
	return variants;
}

export function resize() {
	var viewPortWidth: number = 0;
	if (typeof window !== "undefined") {
		var viewPortWidth = window.innerWidth;
	}
	if (viewPortWidth > 1024) {
		const variants = {
			hidden: {
				height: ``,
				transition: { duration: 1, ease: "easeOut" },
			},
			visible: {
				height: `100vh`,
				transition: { duration: 1, ease: "easeOut" },
			},
			exit: {
				height: ``,
				transition: { duration: 1, ease: "easeOut" },
			},
		};
		return variants;
	}
}

export function resizeHeroSlider() {
	const variants = {
		hidden: {
			objectFit: `cover`,
			transition: { objectFit: { duration: 0, ease: "easeOut" } },
		},
		visible: {
			objectFit: `contain`,
			transition: { objectFit: { duration: 1, ease: "easeOut" } },
		},
		exit: {
			objectFit: `cover`,
			transition: { objectFit: { duration: 1, ease: "easeOut" } },
		},
	};
	return variants;
}

export function buttonSliders(data: any) {
	if (data === "none" || data === undefined) {
		const variants = {
			hidden: {
				opacity: 0,
				transition: { duration: 1, ease: "easeOut" },
			},
			visible: {
				opacity: 1,
				transition: { duration: 1, ease: "easeOut", delay: 0.5 },
			},
			exit: {
				opacity: 0,
				transition: { duration: 1, ease: "easeOut" },
			},
		};
		return variants;
	} else if (data === "slideLeft") {
		const variants = {
			hidden: {
				opacity: 0,
				x: `-1rem`,
				transition: { duration: 1, ease: "easeOut" },
			},
			visible: {
				opacity: 1,
				x: 0,
				transition: { duration: 1, ease: "easeOut", delay: 0.5 },
			},
			exit: {
				opacity: 0,
				x: `-1rem`,
				transition: { duration: 1, ease: "easeOut" },
			},
		};
		return variants;
	} else if (data === "slideRight") {
		const variants = {
			hidden: {
				opacity: 0,
				x: `1rem`,
				transition: { duration: 1, ease: "easeOut" },
			},
			visible: {
				opacity: 1,
				x: 0,
				transition: { duration: 1, ease: "easeOut", delay: 0.5 },
			},
			exit: {
				opacity: 0,
				x: `1rem`,
				transition: { duration: 1, ease: "easeOut" },
			},
		};
		return variants;
	} else {
		const variants = {
			hidden: {
				opacity: 0,
				y: `-1rem`,
				transition: { duration: 1, ease: "easeOut" },
			},
			visible: {
				opacity: 1,
				y: 0,
				transition: { duration: 1, ease: "easeOut", delay: 0.5 },
			},
			exit: {
				opacity: 0,
				y: `-1rem`,
				transition: { duration: 1, ease: "easeOut" },
			},
		};
		return variants;
	}
}

export function lazy() {
	const variants = {
		hidden: {
			opacity: 0,
			transition: { duration: 1, ease: "easeOut" },
		},
		visible: {
			opacity: 1,
			transition: { duration: 1, ease: "easeOut" },
		},
		exit: {
			opacity: 0,
			transition: { duration: 1, ease: "easeOut" },
		},
	};
	return variants;
}

export function variants(data: any) {
	const variants = {
		hidden: {
			opacity: 0,
			x: `${data == "Left" ? -100 : 100}`,
			transition: { duration: 1, ease: "easeOut" },
		},
		visible: {
			opacity: 1,
			x: 0,
			transition: { duration: 1, ease: "easeOut" },
		},
		exit: {
			opacity: 0,
			x: `${data == "Left" ? -100 : 100}`,
			transition: { duration: 1, ease: "easeOut" },
		},
	};
	return variants;
}

export function variantsInvert(data: any) {
	const variants = {
		hidden: {
			opacity: 0,
			scale: 1.1,
			x: `${data == "Left" ? 100 : -100}`,
			transition: { duration: 0.5, ease: "easeOut" },
		},
		visible: {
			opacity: 1,
			x: 0,
			scale: 1.1,
			transition: { duration: 0.5, ease: "easeOut" },
		},
		exit: {
			opacity: 0,
			x: `${data == "Left" ? 100 : -100}`,
			transition: { duration: 0.5, ease: "easeOut" },
		},
	};
	return variants;
}

export function variantsFull() {
	const variantsFull = {
		hidden: {
			opacity: 0,
			y: 100,
			transition: { duration: 1, ease: "easeOut" },
		},
		visible: {
			opacity: 1,
			y: 0,
			transition: { duration: 1, ease: "easeOut" },
		},
		exit: {
			opacity: 0,
			y: 0,
			transition: { duration: 1, ease: "easeOut" },
		},
	};
	return variantsFull;
}

export function ageGate() {
	const variantsFull = {
		hidden: {
			opacity: 0,
			transition: { duration: 1, ease: "easeOut" },
		},
		visible: {
			opacity: 1,
			transition: {
				duration: 2,
				ease: "easeOut",
				when: "beforeChildren",
				staggerChildren: 0.5,
				delayChildren: 4,
			},
		},
	};
	return variantsFull;
}

export function scale() {
	const variantsFull = {
		hidden: {
			opacity: 0,
			scale: 1,
			transition: {
				duration: 1,
				ease: "easeOut",
				when: "afterChildren",
				delay: 2,
			},
		},
		visible: {
			opacity: 1,
			scale: 0.4,
			transition: {
				duration: 2,
				delay: 2,
				ease: "easeOut",
				when: "afterChildren",
				staggerChildren: 0.5,
				delayChildren: 3,
			},
		},
	};
	return variantsFull;
}

export function show() {
	const variantsFull = {
		hidden: {
			opacity: 0,
			y: 50,
			transition: { duration: 0.8, ease: "easeOut" },
		},
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.8,
				ease: "easeOut",
				when: "beforeChildren",
				staggerChildren: 1,
				delayChildren: 2,
			},
		},
	};
	return variantsFull;
}

export function slideReveal(data: string) {
	const variants = {
		hidden: {
			opacity: 0,
			x: `${data === "right" ? 50 : -50}`,
			transition: { duration: 0.8, ease: "easeOut" },
		},
		visible: {
			opacity: 1,
			x: 0,
			transition: { duration: 0.8, ease: "easeOut" },
		},
		exit: {
			opacity: 0,
			x: `${data === "right" ? 50 : -50}`,
			transition: { duration: 0.8, ease: "easeOut" },
		},
	};
	return variants;
}

export const siteName = process.env.NEXT_PUBLIC_WEBSITE_CONFIG_ID;

interface SlugType {
	locale: string;
	value: string;
}

interface PortfolioSlugsMapperTypes {
	wines: Locales;
	"wines-spirits": Locales;
	portfolio: Locales;
	products: Locales;
	sangria: Locales;
	news: Locales;
}

export const portfolioMapper: PortfolioSlugsMapperTypes = {
	wines: {
		en: "wines",
		es: "vinos",
		pt: "vinhos",
	},
	"wines-spirits": {
		en: "wines-spirits",
		es: "vinos-licores",
		pt: "vinhos-espirituosos",
	},
	portfolio: {
		en: "portfolio",
		es: "portafolio",
		pt: "portfolio",
	},
	products: {
		en: "products",
		es: "productos",
		pt: "produtos",
	},
	sangria: {
		en: "sangria",
		es: "sangria",
		pt: "sangria",
	},
	news: {
		en: "news",
		es: "noticias",
		pt: "noticias",
	},
};

export function buildPortfolioSlug(slug: string, typename: string) {
	let slugs = [];

	const slugValue = portfolioMapper[slug as keyof PortfolioSlugsMapperTypes];
	let _allSlugLocales = [
		{
			locale: "en",
			value: slugValue?.en,
		},
		{
			locale: "es",
			value: slugValue?.es,
		},
		{
			locale: "pt",
			value: slugValue?.pt,
		},
	];

	slugs.push({ __typename: typename, _allSlugLocales: _allSlugLocales });

	return slugs;
}

export function getRealPortfolioSlug(currentSlug: string, locale: string) {
	let portfolioRealSlug;

	for (const [key, value] of Object.entries(portfolioMapper)) {
		//console.log(`${key}: ${value}`);
		for (const [currentLocale, value2] of Object.entries(value)) {
			//console.log(`${currentLocale}: ${value2}`);
			if (currentLocale === locale && value2 === currentSlug) {
				portfolioRealSlug = key;
			}
		}
	}

	return portfolioRealSlug;
}

export function getPortfolioSlug(
	brandsProduct: any,
	locale: string | undefined
): string {
	const portfolioSlugs = buildPortfolioSlug(
		brandsProduct?.slug,
		brandsProduct?.__typename
	);
	return portfolioSlugs?.[0]?._allSlugLocales.filter(
		(slug) => slug.locale === locale
	)?.[0]?.value;
}

export function buildPortfolioCollectionsSlugs(
	portfolioSlugs: any[any],
	collections: any[any]
) {
	let slugs: any[] = [];
	collections?.productsCollection?.map(
		(product: { title: { _allSlugLocales: any } }): any => {
			product.title._allSlugLocales.map(
				(collectionSlug: { locale: string; value: string }) => {
					portfolioSlugs[0]?._allSlugLocales.map(
						(portfolioSlug: { locale: string; value: string }) => {
							if (portfolioSlug.locale === collectionSlug.locale) {
								collectionSlug.value = `${portfolioSlug.value}/${collectionSlug.value}`;
							}
						}
					);
				}
			);
			slugs.push(product.title);
		}
	);

	return slugs;
}

export function buildProductTypeSpecificSlugs(
	portfolioSlugs: any[any],
	typeSpecific: any
): any[] {
	let slugs: any[] = [];

	let productsType: any[] = [];
	let added: any[] = [];

	typeSpecific.productsCollection.map(
		(products: { productsCollection: any[] }) =>
			products.productsCollection.map((collection) => {
				collection.productTypeSpecific;
				if (added.indexOf(collection.productTypeSpecific.id) === -1) {
					added.push(collection.productTypeSpecific.id);
					productsType.push(collection.productTypeSpecific);
				}
			})
	);

	productsType.map((type) => {
		type._allSlugLocales.map((typeSlug: { locale: string; value: string }) => {
			portfolioSlugs[0]?._allSlugLocales.map(
				(portfolioSlug: { locale: string; value: string }) => {
					if (portfolioSlug.locale === typeSlug.locale) {
						typeSlug.value = `${portfolioSlug.value}/${typeSlug.value}`;
					}
				}
			);
		});
	});

	return productsType;
}

export function buildProductsSlugs(
	portfolioSlugs: any[any],
	typeSpecific: any
): any[] {
	let productsCollection: any[] = [];

	typeSpecific.productsCollection.map(
		(products: { productsCollection: any[] }) =>
			products.productsCollection.map((collection) => {
				collection._allSlugLocales.map(
					(productSlug: { value: string; locale: string }) => {
						const portfolioSlug = portfolioSlugs[0]._allSlugLocales.filter(
							(s: { locale: any }) => s.locale === productSlug.locale
						)?.[0];
						const typeSpecificSlug =
							collection.productTypeSpecific._allSlugLocales.filter(
								(s: { locale: any }) => s.locale === productSlug.locale
							)?.[0];
						productSlug.value = `${portfolioSlug.value}/${typeSpecificSlug.value}/${productSlug.value}`;
					}
				);
				productsCollection.push(collection);
			})
	);

	return productsCollection;
}

export function pageRelatedLink(
	pageRelated: any,
	locale: string,
	anchor?: string | undefined
) {
	if (pageRelated?.slug && pageRelated?.__typename !== "BrandsProductRecord") {
		const _anchor = anchor ? string_to_slug(anchor) : "";
		return `${pageRelated.slug}${
			anchor !== undefined && _anchor.length > 0 ? "#" + _anchor : ""
		}`;
	} else {
		return getPortfolioSlug(pageRelated, locale);
	}
	//pageRelated[0]?.page.slug && pageRelated[0]?.page.__typename !== 'BrandsProductRecord' ? pageRelated[0]?.page.slug : [portfolioSlug]
}

export function string_to_slug(str: string) {
	if (typeof str !== "string" || str === "") return "";

	str = str.replace(/^\s+|\s+$/g, ""); // trim
	str = str.toLowerCase();
	var from = "âãàáäâèéëêìíïîòóöõôùúüûñç·/_,:;";
	var to = "aaaaaaeeeeiiiiooooouuuunc------";
	for (var i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
	}
	str = str
		.replace(/[^a-z0-9 -]/g, "")
		.replace(/\s+/g, "-")
		.replace(/-+/g, "-");

	return str;
}

export function buildHrefLangsList(allSlugLocales: any[], pageType = "") {
	const links: never[] = [];
	const SITE_URL = process.env.WEBSITE_URL?.endsWith("/")
		? process.env.WEBSITE_URL
		: `${process.env.WEBSITE_URL}/`;
	const defaultLocale = process.env.DEFAULT_SITE_LOCALE?.replace("_", "-");

	allSlugLocales?.map((slug: { locale: string; value: any }) => {
		const locale = slug.locale.replace("_", "-");
		const _typename = pageType as keyof typeof dynamicPagesConfig;
		const _locale = locale as keyof LocaleRouteTypes;
		const basePath =
			pageType !== ""
				? dynamicPagesConfig[_typename]?.routers[_locale]?.[0]
				: "";

		const href = `${SITE_URL}${`${
			locale !== defaultLocale ? `${locale}/` : ""
		}`}${basePath !== undefined && basePath !== "" ? `${basePath}/` : ""}${
			slug.value
		}`;

		handleHrefLangsLinks(locale, links, removeLastSlashFromUrl(href));
		handleCanonicalLink(locale, links, removeLastSlashFromUrl(href));
		console.log("href", href);
	});

	return links;
}

export function handleCanonicalLink(
	locale: string,
	links: any[],
	href: string
) {
	const defaultLocale = process.env.DEFAULT_SITE_LOCALE?.replace("_", "-");
	if (locale === defaultLocale) {
		links.push({
			rel: "canonical",
			href: href,
		});
	}
}

function handleHrefLangsLinks(locale: string, links: any[], href: string) {
	const defaultLocale = process.env.DEFAULT_SITE_LOCALE?.replace("_", "-");
	if (locale === defaultLocale) {
		links.push({
			rel: "alternate",
			hrefLang: locale,
			href: href,
		});
		links.push({
			rel: "alternate",
			hrefLang: "x-default",
			href: href,
		});
	} else {
		links.push({
			rel: "alternate",
			hrefLang: locale,
			href: href,
		});
	}
}

export function buildHrefLangsForHomepage() {
	const locales = process.env.SITE_LOCALES;
	const localesToArr = locales?.split(",");
	const links: any[] = [];
	const defaultLocale = process.env.DEFAULT_SITE_LOCALE?.replace("_", "-");
	const SITE_URL = process.env.WEBSITE_URL?.endsWith("/")
		? process.env.WEBSITE_URL
		: `${process.env.WEBSITE_URL}/`;

	localesToArr?.map((locale) => {
		const href = `${SITE_URL}${`${locale !== defaultLocale ? locale : ""}`}`;

		handleHrefLangsLinks(locale, links, removeLastSlashFromUrl(href));
		handleCanonicalLink(locale, links, removeLastSlashFromUrl(href));
	});
	return links;
}

export function removeLastSlashFromUrl(url: string) {
	return url.replace(/\/$/, "");
}
