import styles from "./index.module.scss";

interface RangeSliderProps {
	value: number;
	min: string;
	max: string;
	onChange: (e: any) => void;
	className?: string;
}

export default function RangeSlider({
	value,
	min,
	max,
	onChange,
	className,
}: RangeSliderProps) {
	return (
		<input
			type="range"
			min={min}
			max={max}
			defaultValue="0"
			value={value}
			onChange={onChange}
			className={`${styles.range} ${className}`}
		/>
	);
}
