import { Title } from "@/components/Atoms/Title";
import VideoPlayerBlock from "@/components/Atoms/Video";
import { GalleryImage } from "@/components/Molecules/ImageCard";

interface Props {
  data: any;
  config: any;
}

export default function RecipeGallery({ data, config }: Props) {
  return (
    <div className=" flex flex-row gap-4 pb-12">
      {data.media && (
        <div className="flex flex-col gap-4">
          <Title
            tag={"h3"}
            data={data?.mediaGalleryTitle}
            color={config?.headlineTextColor.color}
          />
          {data.media.map((asset: any, i: number) => {
            if (asset.__typename == "ImageRecord") {
              return <GalleryImage key={i} data={asset?.image} />;
            } else {
              return (
                <VideoPlayerBlock
                  videoUrl={data.prepVideo.video.url}
                  loop={data.prepVideo.loop}
                  autoplay={data.prepVideo.autoplay}
                  textColor={"#fff"}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
