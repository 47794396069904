import { groupBy } from "lodash";
import { useState } from "react";
import styles from "./index.module.scss";

interface SidebarProps {
	icon: string;
	iconHover: string;
	markers: any;
	clickListItem: (id: string) => void;
	currentActive: boolean;
	location: any;
	onCloseSidebar: () => void;
	showSidebarOverlay: boolean;
	clickOnRegion: (region: any) => void;
	clickOnCountry: (country: any) => void;
	color: any;
}

export function Sidebar({
	icon,
	iconHover,
	markers,
	clickListItem,
	currentActive,
	location,
	onCloseSidebar,
	showSidebarOverlay,
	clickOnRegion,
	clickOnCountry,
	color,
}: SidebarProps) {
	let locations = groupBy(markers, (item: any) => item.data.country.title);
	// let regions = groupBy(markers, (item) => item.data.region.name)
	const [activeCountry, setActiveCountry] = useState("");
	const [activeRegion, setActiveRegion] = useState("");

	const onCountryClick = (name: any, country: any) => {
		if (name === activeCountry) setActiveCountry("");
		else setActiveCountry(name);

		/* onCloseSidebar(); */
		clickOnCountry(country);
	};

	const onRegionClick = (name: any, region: any) => {
		if (name === activeRegion) setActiveRegion("");
		else setActiveRegion(name);

		/* onCloseSidebar(); */
		clickOnRegion(region);
	};

	return (
		<div
			className={`z-10 h-3/4 md:h-[600px] ${
				showSidebarOverlay
					? "md:transform md:duration-300 md:w-2/4"
					: "md:transform md:duration-100 md:w-1/4"
			}`}
		>
			<>
				<div
					className={`${styles.contentSidebar} relative h-full overflow-hidden`}
				>
					<div
						className={`absolute overflow-y-auto top-0 z-20 p-6 md:pl-16 md:pt-10 h-full ${
							!showSidebarOverlay
								? "w-full md:transform md:duration-300 md:translate-x-0"
								: "md:transform md:duration-300"
						}`}
					>
						{Object.entries(locations).map(([name, markers], key) => {
							let regions = groupBy(
								markers,
								(item: any) => item.data.region.name
							);
							let countries = groupBy(markers, (item: any) => item.country.id);
							return (
								<div key={key} className={`${styles.countryLocations}`}>
									{/* Just Shows if markers have more than one country */}
									{Object.entries(countries).length > 1 && (
										<h3
											className={`${styles.countryName} cursor-pointer mb-2 ${
												activeCountry === name ||
												Object.entries(locations).length == 1
													? styles.active
													: ""
											}`}
											onClick={() =>
												onCountryClick(name, markers[0].data.country)
											}
										>
											{name}
										</h3>
									)}
									<ul
										key={`ul-${key}`}
										className={`${styles.list} ${
											activeCountry === name ||
											Object.entries(locations).length == 1
												? "block"
												: "hidden"
										}`}
									>
										{Object.entries(regions).map(([name, region], key) => (
											<div key={key}>
												<h3
													className={`pl-4 cursor-pointer mapRegionTitle text-lg lg:text-regionTitle xl:text-regionTitle_lg ${
														activeRegion === name ||
														Object.entries(regions).length == 1
															? `${styles.active} uppercase pb-6`
															: "pb-6"
													}`}
													onClick={() => onRegionClick(name, region)}
												>
													{name}
												</h3>
												<ul
													key={`ul-2-${key}`}
													className={`${
														activeRegion === name ||
														Object.entries(regions).length == 1
															? "block"
															: "hidden"
													}`}
												>
													{region &&
														region.map((location: any, key: number) => (
															<li
																key={`li-${key}`}
																className={`pl-6 mapLocationsText text-sm lg:text-base ${
																	location.id === currentActive
																		? styles.active
																		: ""
																}`}
																onClick={() => clickListItem(location.id)}
															>
																<img
																	src={
																		location.id === currentActive
																			? iconHover
																			: icon
																	}
																	alt={location.name}
																/>
																{location.name}
															</li>
														))}
												</ul>
											</div>
										))}
									</ul>
								</div>
							);
						})}
					</div>
					{location.addExtraSidebarPages &&
						{
							/* <SidebarOverlay
							location={location}
							onCloseSidebar={onCloseSidebar}
							expanded={showSidebarOverlay}
						/> */
						}}
				</div>
			</>
		</div>
	);
}
