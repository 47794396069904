import { fetchAPI } from "@/lib/api";
import { gql } from "@apollo/client";
import { responsiveImageFragment, pageRelatedFragment } from "@/lib/fragments";

export async function getAwards(locale) {
  const data = await fetchAPI(
    gql`
        query GetAllAwards($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          allAwards(orderBy: contestYear_DESC locale: $locale) {
            __typename
            id
            product {
            productName
            slug
            h3SapCode
            }
            contestYear
            wineContest {
              title
              image{
                url
                width
                height
              }
            }
            award {
              id
              title
              awardTypes {
                awardCategory
              }
              awardImage {
                responsiveImage {
                  src
                }
              }
            }
            hasRating
            rating {
              ratingImage {
                responsiveImage {
                  src
                }
              }
            }
          }
        }
       `,
    {
      variables: {
        locale,
      },
    }
  );
  return data;
}

export async function getAwardsByProductsIdsList(productsIdsList) {
  const data = await fetchAPI(
    gql`
      query GetAllAwardsByProductsIdsList($productsIdsList: [ItemId!]) {
        allAwards(
          orderBy: contestYear_DESC
          filter: { product: { in: $productsIdsList } }
        ) {
          __typename
          id
          product {
            id
            productName
            slug
            h3SapCode
            productBrand {
              image {
                image {
                  responsiveImage {
                    ...responsiveImageFragment
                  }
                }
              }
            }
            _allSlugLocales {
              locale
              value
            }
          }
          contestYear
          award {
            id
            title
            awardTypes {
              awardCategory
            }
          }
          hasRating
          rating {
            ratingValue
          }
        }
      }
      ${responsiveImageFragment}
    `,

    {
      variables: {
        productsIdsList,
      },
    }
  );
  return data;
}

export async function getAwardsById(locale, preview, productId) {
  const data = await fetchAPI(
    gql`
        query GetAllAwardsByProductsIdsList($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}, $productId: ItemId) {
          allAwards(orderBy: contestYear_DESC filter: {product: {eq: $productId}}, locale: $locale) {
            __typename
            id
            contestYear
            wineContest {
              title
              image{
               responsiveImage {
                ...responsiveImageFragment
               }
              }
            }
            country {
              title
            }
            award {
              id
              title
              awardTypes {
                awardCategory
              }
              awardImage {
                responsiveImage {
                  ...responsiveImageFragment
                }
              }
            }
            hasRating
            rating {
              ratingImage {
                responsiveImage {
                  ...responsiveImageFragment
                }
              }
            }
            product {
              id
              productName
              productBrand{
                image{
                  image{
                    responsiveImage {
                      ...responsiveImageFragment
                    }
                  }
                }
              }
              factSheetImage {
              responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                ... responsiveImageFragment
              }
            }
              sku {
                  skuImageGallery {
                    id
                    responsiveImage {
                      ...responsiveImageFragment
                    }
                  }
                }
              slug
              h3SapCode
              _allSlugLocales {
                locale
                value
              }
            }
          
          }
        }
        ${responsiveImageFragment}
       `,
    {
      variables: {
        preview,
        productId,
        locale,
      },
    }
  );
  return data;
}

export async function getReferencingPortfolioByProductId(
  locale,
  preview,
  productId,
  siteConfigId
) {
  const data = await fetchAPI(
    gql`
        query GetReferencingPortfolioByProductId($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}, $productId: ItemId, $siteConfigId: ItemId) {
          productPortfolio: product(filter: {id: {eq: $productId}}, locale: $locale) {
            __typename
            id
            
            portfolioConf: _allReferencingBrandsProducts(filter: {siteConfig: {eq: $siteConfigId}}) {
              showMediaPublicationLogo
              accoladesShowAwards
              accoladesShowRatings
              accoladesAwardsType {
                __typename
                id
                title
              }
              accoladesRatings100 {
                __typename
                id
                ratingValue
              }
              accoladesRatings20 {
                __typename
                id
                ratingValue
              }
              accoladesSlug
              awardsYear
   
              slug
              productsList {
                productsDefinition {
                  relatedProducts {
                  id
                  __typename
                  productName
                  slug
                  factSheetImage {
                    responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                      ... responsiveImageFragment
                          }
                        }
                  sku {
                    slug
                    skuName
                    skuImageGallery {
                      responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                        ... responsiveImageFragment
                      }
                    }
                  }
                  productTypeSpecific {
                    slug
                  }
                }
                  id
                  label
                  customLabel
                  media {
                    typeofimage
                    image {
                      responsiveImage {
                        ...responsiveImageFragment
                      }
                    }
                  }
                  product {
                    __typename
                    id
                    productTypeSpecific{
                      slug
                    }
                    factSheetImage {
                    responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                      ... responsiveImageFragment
                      }
                    }
                    sku {
                      skuImageGallery {
                        responsiveImage {
                          ...responsiveImageFragment
                        }
                      }
                    }
                  }
                }
              }

              productsCollection {
                id
                productsCollection {
                  __typename
                  id
                  productTypeSpecific {
                    slug
                  }
                }
              }
              
              showImage
              follow
              mobileProductAlignment
              addTitleUnderline
              customUnderlineAsset{
                img{
                  url
                  width
                height
                }
              }
              underlineColor{
                color{
                  hex
                }
              }
              heroTextColor{
                color{
                  hex
                }
              }
              productDetailTextColor{
                color{
                  hex
                }
              }
              nutritionalTableBackground{
                color {
                  red,
                  blue,
                  green
                }
              }
              mobileProductDetailColor{
                color{
                  hex
                }
              }
            buyButtonIcon {
              img {
                url
              }
            }
            technicalSheetButtonIcon {
              img {
                url
              }
            }
            veganCertificateButtonIcon {
              url
            }
            productDetailTextColor {
              color {
                hex
              }
            }

              detailBgColor{
                color{
                  hex
     
                }
              }
              
              addShadowOverlay
              overlayShadowColor {
                id
                color{
                  hex
                }
              }
              productDetailButtonConfig{
              ...pageRelatedFragment
              }
            }
          
          }
        }
        ${responsiveImageFragment}
        ${pageRelatedFragment}
       `,
    {
      variables: {
        preview,
        productId,
        locale,
        siteConfigId,
      },
    }
  );
  return data;
}
