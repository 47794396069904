import { MultiTextComponent } from "@/components/Atoms/MultiText";
import { ImageLabel, Paragraph } from "@/components/Atoms/Paragraph";
import { GalleryImage } from "@/components/Molecules/ImageCard";
import { formatDate } from "@/lib/functions";
import { useTranslation } from "next-i18next";
import TextImageHalf from "../TextImage/TextImageHalf";
import SectionText from "../Text";

export default function ArticleContent({
	data,
	sections,
	config,
	date,
	author,
	hideDate,
}: any) {
	const formatDateToShow = formatDate(date);
	const { t } = useTranslation();
	return (
		<div className="container mx-auto flex flex-col gap-10 pb-12 max-w-[950px] px-6 lg:px-4 xl:px-2 2xl:px-0 py-6 lg:py-4 xl:py-2 2xl:py-0">
			{!hideDate && (
				<div>
					<ImageLabel
						copy={formatDateToShow}
						color={config?.articleTextColor?.color}
					/>
					{author && (
						<ImageLabel
							className="font-bold"
							copy={`${t("by")}: ${author.title}`}
							color={config?.articleTextColor?.color}
						/>
					)}
				</div>
			)}
			{/* CONTENT */}
			{data.map((block: any, i: number) => {
				if (block.__typename == "TextRecord") {
					return (
						<MultiTextComponent
							key={i}
							data={block.text}
							color={config?.articleTextColor?.color}
						/>
					);
				} else if (block.__typename == "ImageRecord") {
					return <GalleryImage key={i} data={block?.image} />;
				} else if (block.__typename == "SectionTextImageRecord") {
					return (
						<div
							key={i}
							className={`flex gap-6  ${
								block.textAlignment == "left"
									? "flex-col lg:flex-row"
									: "flex-col lg:flex-row-reverse"
							}`}
						>
							<div className="flex-1">
								<Paragraph
									copy={block.text}
									color={config?.articleTextColor?.color}
								/>
							</div>
							<div className="flex-1">
								<GalleryImage data={block?.image} />
							</div>
						</div>
					);
				}
			})}
			{/* SECTIONS */}
			{sections?.map((section: any, i: number) => {
				if (section.__typename == "SectionTextImageRecord") {
					return (
						<TextImageHalf
							contentHeight={section?.contentHeight}
							useSectionMargins={section?.useSectionMargins}
							useExtraTopMargins={section?.useExtraTopMargins}
							useExtraPadding={section?.useExtraPadding}
							radius={section?.contentBorderRadius}
							data={section}
						/>
					);
				} else if (section.__typename == "SectionTextRecord") {
					return <SectionText key={i} data={section} />;
				}
			})}
		</div>
	);
}
