import { useTranslation } from "next-i18next";
import styles from "./index.module.scss";
import { ArrowDown } from "@/atoms/Vectors";

interface Props {
	type: string;
	color: any[any];
	onClick: any;
	custom: any;
	bg?: any;
	ref?: any;
	spacer: boolean;
	featured?: boolean;
}

export const PageScroll = ({
	onClick,
	type,
	color,
	custom,
	bg,
	spacer,
	ref,
	featured,
}: Props) => {
	const { t } = useTranslation();
	const style = {
		"--color": color?.hex,
		"--svg": `url(${custom?.url})`,
		"--svgBg": `url(${bg[1]?.img?.url})`,
		"--bg": bg[2]?.hex,
	} as React.CSSProperties;
	switch (type) {
		case "arrow":
			return (
				<>
					<button
						aria-label={"Page scroll button"}
						ref={ref}
						onClick={onClick}
						className="absolute w-fit scrollLabel text-white z-50 left-0 right-0 mx-auto  -bottom-16 lg:-bottom-12  flex-col justify-center items-center gap-y-4 italic text-xl hidden md:flex"
					>
						{t("seeMore")}
						<ArrowDown color={color?.hex} />
					</button>
				</>
			);
			break;
		case "line":
			return (
				<>
					<button
						aria-label={"Page scroll button"}
						style={style as React.CSSProperties}
						onClick={onClick}
						className={`${styles.lineButton} absolute w-fit  scrollLabel z-30 left-0 right-0 mx-auto  -bottom-16 lg:bottom-[-3rem]  flex-col justify-center items-center gap-y-4 italic text-xl hidden md:flex`}
					>
						{t("seeMore")}
						<span className={`${styles.lineSpan} h-16 w-[2px]`}></span>
					</button>
				</>
			);
			break;
		case "custom":
			return (
				<>
					{bg[0] ? (
						<div
							className={`${styles.customButtonBg} ${spacer ? `translate-y-[-65px] ` : ` translate-y-[0px]`
								}   `}
							style={style as React.CSSProperties}
						>
							<button
								aria-label={"Page scroll button"}
								style={style as React.CSSProperties}
								onClick={onClick}
								className={`${styles.customButton}  absolute w-fit scrollLabel left-0 right-0 mx-auto  top-8 md:top-6 lg:top-2 flex-col justify-center items-center gap-y-4 italic text-xl  p-2 flex`}
							>
								<div className={styles.iconElementBg}></div>
							</button>
						</div>
					) : (
						<>
							<button
								aria-label={"Page scroll button"}
								style={style as React.CSSProperties}
								onClick={onClick}
								className={`${styles.customButton
									} absolute w-fit left-0 right-0 ${featured ? "-bottom-10 md:-bottom-20" : "-bottom-12"
									} mx-auto flex-col scrollLabel justify-center items-center gap-y-4 italic text-xl p-2 rounded-full flex`}
							>
								<div
									className={`${featured ? styles.iconSvgElement : styles.iconElement
										}`}
								></div>
							</button>
						</>
					)}
				</>
			);
			break;
		default:
			return <></>;
			break;
	}
};
