import React from "react";
import Carousel from "react-multi-carousel";
import { ButtonGroupProps } from "react-multi-carousel/lib/types";
import "react-multi-carousel/lib/styles.css";
import styles from "./index.module.scss";
import { Arrow } from "@/components/Atoms/Vectors";
import { ImageLabel, Label, Paragraph } from "@/components/Atoms/Paragraph";

interface Props {
  data: any;
  color: any;
  next?: Function;
  previous?: Function;
  goToSlide?: Function;
  carouselState?: any;
  rest?: any;
}

interface SliderNav {
  next: Function;
  previous: Function;
  goToSlide: Function;
  carouselState: any;
  rest?: any;
}

export default function QuoteSlider({
  data,
  color,
  next,
  previous,
  goToSlide,
  carouselState,
  ...rest
}: Props) {
  const responsiveSlider = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,

    },
    tablet: {
      breakpoint: { max: 1023, min: 464 },
      items: 1,

    },
    mobile: {
      breakpoint: { max: 463, min: 0 },
      items: 1,

    },
  };
  const HeroSliderNav = ({ next, previous, goToSlide, ...rest }: SliderNav) => {
    const {
      carouselState: { currentSlide },
    } = rest;

    return (
      <div className=" absolute justify-between mx-auto hidden  w-[90%] left-0 right-0  md:flex flex-row gap-12">
        <button
          className={` flex items-center justify-center scale-125`}
          onClick={() => previous()}
        >
          <Arrow
            style="default"
            direction="left"
            color={data.sliderNavigationColor?.color}
          />
        </button>
        <button
          className={`flex items-center justify-center scale-125 `}
          onClick={() => goToSlide(currentSlide + 1)}
        >
          <Arrow
            style="default"
            direction=""
            color={data.sliderNavigationColor?.color}
          />
        </button>
      </div>
    );
  };

  interface CarouselButtonGroupProps extends ButtonGroupProps {
    className?: string;
  }

  return (
    <div className="py-12 w-full">
      <Carousel
        containerClass={`relative w-full flex flex-row container mx-auto 2xl:max-w-[1360px] py-12`}
        responsive={responsiveSlider}
        sliderClass={styles.slider}
        draggable={false}
        swipeable={true}
        arrows={true}
        showDots={true}
        infinite={true}
        centerMode={false}
        minimumTouchDrag={10}
        itemClass={`${styles?.itemClass} h-full`}
      >
        {data.map((entry: any, i: number) => (
          <div
            key={i}
            className={`${styles.activeDiv} h-full relative text-center max-w-[35rem] mx-auto flex flex-col gap-6`}
          >
            <Paragraph copy={entry.content} color={color} />
            <ImageLabel
              className="font-bold"
              copy={entry?.additionalContent}
              color={color}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
