import { GrapeVarietyBlock } from "@/components/Molecules/GrapeVarietyBlock";

export default function SectionGrapeVariety(data: any) {
  if (!data.grapeVariety) return;
  return (
    <div
      style={{ backgroundColor: data?.bgColor?.color.hex }}
      className="w-full"
    >
      <div className="container mx-auto flex flex-col gap-6 lg:gap-16 lg:flex-row items-center justify-between py-10 px-6 lg:px-0 2xl:max-w-[1360px]">
        <GrapeVarietyBlock
          data={data}
          descriptionColor={data.grapeVarietyDescriptionColor.color}
          titleColor={data.grapeVarietyTitleColor.color}
        />
      </div>
    </div>
  );
}

export const fragment = `
    fragment GrapeVarietyFragment on SectionGrapeVarietyRecord{
      __typename
      grapeVariety {
        id
        title
        description
        image {
          id
        }
      }
      grapeVarietyTitleColor {
        color {
          hex
        }
      }
      grapeVarietyDescriptionColor {
        color {
          hex
        }
      }
      bgColor {
        color {
          hex
        }
      }
  }
`;
