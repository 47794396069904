import styles from "./index.module.scss";

interface Props {
  color: any[any];
}

export function Underline({ color }: Props): JSX.Element {
  const style = {
    "--line": color?.hex,
  } as React.CSSProperties;
  return (
    <span
      style={style as React.CSSProperties}
      className={`${styles.underline} underline w-full brandUnderline h-[2px]`}
    ></span>
  );
}

interface CustomProps {
  color: any[any];
  shape: any;
}

export function CustomUnderline({ color, shape }: CustomProps): JSX.Element {
  const style = {
    "--line": color?.hex,
    "--shape": `url(${shape?.url})`,
  } as React.CSSProperties;
  return (
    <span
      style={style as React.CSSProperties}
      className={`${styles.underlineCustom}   w-[100%] brandUnderline
       `}
    ></span>
  );
}

export function CustomUnderlineBolder({
  color,
  shape,
}: CustomProps): JSX.Element {
  const style = {
    "--line": color?.hex,
    "--shape": `url(${shape?.url})`,
  } as React.CSSProperties;
  return (
    <span
      style={style as React.CSSProperties}
      className={`${styles.underlineCustom}   md:w-[100%]  min-w-[3rem] lg:min-w-[7rem] translate-y-[-1rem] lg:translate-x-4 z-[0] relative  h-[2rem]  max-w-[25rem]  
       `}
    ></span>
  );
}
