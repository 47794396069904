import { VariantProps, cva } from "class-variance-authority";
import { Label } from "../Paragraph";

export type TagLabelVariantProps = VariantProps<typeof TagLabelVariants>;
const TagLabelVariants = cva(`  `, {
  variants: {
    layout: {
      default: `absolute z-10 bg-white px-4 py-2 top-6 h-fit`,
      gridRounded: `absolute z-10 bg-white px-4  top-0 left-0 right-0 w-[65%] m-auto h-fit rounded-b-3xl text-center`,
      featured: "",
    },
  },
  defaultVariants: {
    layout: "default",
  },
});

export interface TagLabelProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    TagLabelVariantProps {
  highlight: any;
  label: any;
  color: any;
}

export function TagLabel({ highlight, label, color, layout }: TagLabelProps) {
  const transformLabel = label === "default" ? "recipe" : label;
  console.log
  return (
    <div
      style={{ backgroundColor: `${highlight?.color.hex}` }}
      className={`${TagLabelVariants({ layout })}`}
    >
      <Label copy={transformLabel} color={color?.color} />
    </div>
  );
}
