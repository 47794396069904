import { AboveParallaxImage } from "@/components/Atoms/ParallaxImage";
import { CopyCard } from "@/components/Molecules/CopyCard";
import { FeaturedBannersGallery } from "@/components/Molecules/FeaturedBannersGallery";
import React from "react";

interface Props {
  data: any;
  locale: string;
  portfolioSlug: any;
}

export default function PillGallery({ data, locale, portfolioSlug }: Props) {
  return (
    <>
      {" "}
      {!data.hideCard && (
        <div
          className={` ${
            data.layout == "fullRow"
              ? "col-span-3 py-12"
              : "col-span-3 lg:col-span-1"
          } flex flex-col gap-y-4 relative z-1 `}
        >
          <CopyCard
            title={data.title}
            description={data.description}
            color={data.textColor?.color}
            underline={data.addTitleUnderline}
            underlineColor={data.underlineColor?.color}
            customUnderlineAsset={data.customUnderlineAsset}
            layout={data.layout}
            card={data.textLayoutCard}
            cardColor={data.cardBackgroundColor?.color}
            align={data.textAlignment}
            tag="h2"
            label={[data.addLabel, data.titleLabel]}
            pageRelated={data.pageRelated}
            portfolioSlug={portfolioSlug}
            bg={data.background}
          />
          {data.addParallaxImage && (
            <AboveParallaxImage
              className="flex lg:hidden items-end"
              data={data.parallaxImage}
            />
          )}
        </div>
      )}
      <div
        className={` relative z-50  ${
          data.layout == "fullRow" ? "lg:py-6" : "lg:py-24"
        }  "w-full  ${
          data.layout == "fullRow"
            ? "col-span-3  "
            : "col-span-3 lg:col-span-2 py-12 lg:translate-y-[0]"
        }  overflow-x-auto lg:overflow-visible`}
      >
        <div
          className={`  ${
            data.hideCard ? "pt-12" : ""
          }  pb-24 lg:pb-4 flex flex-row gap-2 md:gap-4 flex-nowrap px-6  lg:px-0  `}
        >
          {data.featuredBannersGallery.map((entry: any, i: number) => (
            <FeaturedBannersGallery
              key={i}
              ctaConfig={[
                data?.addProductFeaturedBannerCallToAction,
                data?.galleryCtaConfiguration,
              ]}
              entry={entry}
              locale={locale}
              portfolioSlug={portfolioSlug}
              card={data.textLayoutCard}
              layout={data.layout}
              textColor={data.featuredBannerTextColor?.color}
              styleColor={data.featuredBannerStyleColor?.color.hex}
              buttonColor={data?.featuredBannerButtonColor?.color}
              buttonText={data?.featuredBannerButtonTextColor?.color}
              style={data.featuredBannerStyle}
              underline={undefined}
              labelLayout={"default"}
              underlineColor={undefined}
              customUnderlineAsset={undefined}
            />
          ))}
        </div>
        {data.addParallaxImage && (
          <AboveParallaxImage
            className="hidden lg:flex items-center translate-y-[-10%]"
            data={data.parallaxImage}
          />
        )}
      </div>
    </>
  );
}
