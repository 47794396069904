import { ButtonMaster } from "@/components/Atoms/Buttons";
import { useTranslation } from "next-i18next";
import Image from "@/components/Atoms/Image";
import { Paragraph } from "@/components/Atoms/Paragraph";
import { FeaturedTitle, FeaturedTitleNumber } from "@/components/Atoms/Title";
import WineFilter, {
  AccoladeSelectedFiltersProps,
} from "@/components/Molecules/AccoladesFilterBlock";
import { filterWines } from "@/lib/accolades/filters";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface Props {
  data: any;
  config: any;
  locale: any;
  portfolioSlug: any;
}

export default function AccoladesDisplayBlock({ data, config, locale }: Props) {
  const { t } = useTranslation();

  //remove products without awards//

  const productsWithAcollades = data.filter(
    (x: any) => x.product.awardsAndRatings.length > 0
  );

  // Flatten the data to retrieve only products

  const flattenedArrayWines = productsWithAcollades.flatMap(
    (item: any) => item.product
  );

  const router = useRouter();
  const [aSlug, setASlug] = useState("");
  const [selectedFilters, setSelectedFilters] =
    useState<AccoladeSelectedFiltersProps>({});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj: any = {};
    for (const [key, value] of params as any) {
      paramsObj[key] = value;
    }
    setSelectedFilters(paramsObj);
  }, []);

  //dynamic filtering toggle
  const [dynamicFilter, setDynamicFilter] = useState(
    // use type from url params if available to determine which filter to show
    selectedFilters.type || config?.dynamicFilters[0].__typename
  );

  const filteredWines = filterWines(productsWithAcollades, selectedFilters);

  const accoladeSlug = data.flatMap((x: any) =>
    x.product.awardsAndRatings.some((y: any) => y.__typename === "RatingRecord")
  );
  const matchAccoladeSlug = accoladeSlug.some((y: any) => y == true);

  if (matchAccoladeSlug && aSlug != "ratings") {
    setASlug("ratings");
  } else if (!matchAccoladeSlug && aSlug == "ratings") {
    setASlug("awards");
  }

  return (
    <>
      {config ? (
        <div className="w-full m-auto">
          <div className="flex w-full ">
            <WineFilter
              config={config}
              wines={flattenedArrayWines}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              filteredWines={filteredWines}
              setDynamicFilter={setDynamicFilter}
              dynamicFilter={dynamicFilter}
            />
          </div>

          <div className="container mx-auto grid grid-cols-2 md:flex md:flex-row md:flex-wrap justify-center items-center py-4 md:py-12   ">
            {filteredWines.length > 0 ? (
              <>
                {filteredWines.map((prodConf: any, i: number) => {
          
                  let total = prodConf?.product.awardsAndRatings.length;
                  if (
                    prodConf?.product.awardsAndRatings.length > 0 &&
                    Object.keys(selectedFilters).length > 0
                  ) {
                    if (dynamicFilter === "AwardsFilterAccolateRecord") {
                      let awardsAndRatings = [
                        ...prodConf?.product.awardsAndRatings,
                      ];
                      total = awardsAndRatings.length;

                      let filteredAwardsAndRatings = awardsAndRatings.filter(
                        (x: any) => {
                          let yearMatch =
                            selectedFilters.accoladeYear === "accoladeYear" ||
                            selectedFilters.accoladeYear === "all" ||
                            x.ratingYear.toString() ==
                              selectedFilters.accoladeYear;
                          let tasterMatch =
                            selectedFilters.taster === "taster" ||
                            selectedFilters.taster === "all" ||
                            x.taster.some(
                              (y: any) => y.title == selectedFilters.taster
                            );

                          let ratingMatch =
                            selectedFilters.ratings === "ratings" ||
                            selectedFilters.ratings === "all" ||
                            parseInt(selectedFilters.ratings) >=
                              parseInt(x.rating.ratingValue);

                          return yearMatch && ratingMatch && tasterMatch;
                        }
                      );

                      total = filteredAwardsAndRatings.length;
                    }
                  }

                  return (
                    <div
                      className="md:flex-1  md:max-w-[30%] lg:max-w-[20%]"
                      key={i}
                    >
                      <Image
                        data={prodConf?.product.sku[0]?.skuImageGallery[0]}
                        className={` max-w-[20rem] mx-auto group-hover:scale-105 lg:group-hover:translate-y-[-5%] transition-all ease-in-out duration-300`}
                      />
                      <div
                        className={`  min-h-[5rem] mx-auto  max-w-[90%] flex flex-col justify-center py-4 items-center gap-2 `}
                      >
                        <FeaturedTitleNumber
                          tag={"h3"}
                          data={`${total} Accolades`}
                          color={config.textColor?.color}
                        />
                        <FeaturedTitle
                          layout="featuredCenter"
                          tag="h4"
                          className=""
                          data={
                            prodConf?.customLabel
                              ? `${prodConf?.label}`
                              : `${prodConf?.product.productName}`
                          }
                          color={config.textColor?.color}
                        />

                        {config.addCallToAction && (
                          <div className="flex justify-center">
                            <ButtonMaster
                              anim={"slideTop"}
                              type={config.buttonType}
                              cta={config?.styleColor?.color}
                              color={config?.labelColor?.color}
                              customIcon={config?.icon}
                              customColor={config?.iconColor?.color}
                              link={`
                            ${routerHandler({
                              typename: "AwardRecord",
                              slug: [aSlug, prodConf?.product.slug],
                              locale: locale,
                            })}`}
                            >
                              {config?.buttonLabel?.label}
                            </ButtonMaster>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="text-center col-span-4 py-4 font-bold">
                <Paragraph
                  copy={t("noResults")}
                  color={config.textColor?.color}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
