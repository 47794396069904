import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import CustomLink from "../CustomLink";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { buttonSliders, variantsFull } from "@/lib/functions";
import React, { FC } from "react";

import { ArrowRight, Underline } from "@/components/Atoms/Vectors";
import { CustomUnderlineBolder } from "../Underline";
import { VariantProps, cva } from "class-variance-authority";

interface Props {
	toggleMenu: boolean;
	onClick: any;
	color: any;
	burger?: any;
	close?: any;
}

interface LinkProps {
	type: string;
	style?: any;
	color: any[any];
	cta?: any[any];
	link: any;
	children: any;
	props?: any[any];
	submit?: any;
	customIcon?: any;
	customColor?: any;
	anim?: string;
	locale?: any;
	target?: string;
	onClick?: any;
}

export function BurgerMenuButton({
	toggleMenu,
	onClick,
	color,
	burger,
	close,
}: Props): JSX.Element {
	const style = {
		"--color": color?.hex,
		"--burger": `url(${burger?.url})`,
		"--close": `url(${close?.url})`,
	} as React.CSSProperties;

	return (
		<button
			style={style as React.CSSProperties}
			data-active={toggleMenu}
			aria-label="menu button"
			className={`mobile-menu-button relative transition-all duration-150 ${
				!toggleMenu == true ? `${styles.burger}  ` : `${styles.close}  `
			}}`}
			onClick={onClick}
		></button>
	);
}

export function ButtonArrow(props: any) {
	const style = {
		"--color": props.color?.hex,
	} as React.CSSProperties;
	if (props.link) {
		return (
			<LazyMotion features={domAnimation}>
				<m.div
					className={` w-fit h-fit relative`}
					variants={buttonSliders("Left")}
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					exit="exit"
				>
					<div
						style={style as React.CSSProperties}
						className={`group w-sm relative brandParagraph  text-base sm:text-xl lg:text-2xl flex items-center space-x-2 cursor-pointer ${props.className} ${styles.default} `}
					>
						<CustomLink
							className={`${
								props.direction == "left" && "flex-row-reverse"
							} flex gap-2 `}
							href={props.link}
							external={props.external}
						>
							{`< ${props.children}`}
						</CustomLink>
					</div>
				</m.div>
			</LazyMotion>
		);
	} else {
		return (
			<div
				className={`group w-sm relative   brandParagraph text-lg sm:text-xl lg:text-2xl flex items-center space-x-2 cursor-pointer ${props.className} `}
			>
				<button>{props.children}</button>
			</div>
		);
	}
}

interface Button {
	onClick: any;
	data: any[any];
	type: string;
	cta: any[any];
	color: any[any];
	custom: any;
	className?: string;
}
// USED FOR AGE GATE //
export const Button: FC<Button> = ({
	onClick,
	data,
	color,
	cta,
	type,
	custom,
	className,
}): JSX.Element => {
	const { t } = useTranslation();
	const style = {
		"--color": color?.hex,
		"--cta": cta?.hex,
	} as React.CSSProperties;

	return (
		<>
			{type === "borderFull" ? (
				<button
					style={style as React.CSSProperties}
					className={`${styles.borderFull} ${className}  mx-4 my-2 lg:my-0 border px-24 py-[.3rem] font-light relative  hover:scale-110 transition-transform flex flex-col items-center
          lg:text-ctaSize`}
					onClick={onClick}
				>
					{t(data)}
				</button>
			) : type === "default" ? (
				<button
					style={style as React.CSSProperties}
					className={`  px-24 py-[.3rem] font-light relative hover:scale-110 transition-transform flex flex-col items-center  z-1 ${className}`}
					onClick={onClick}
				>
					<span className="z-10 relative">{t(data)}</span>
					{custom[0] && (
						<>
							{custom[2]?.img ? (
								<CustomUnderlineBolder
									shape={custom[2]?.img}
									color={custom[1]?.color}
								/>
							) : (
								<Underline color={custom[1]?.color} />
							)}
						</>
					)}
				</button>
			) : type === "bgFill" ? (
				<button
					style={style as React.CSSProperties}
					className={`  py-2 px-12 font-light relative  hover:scale-110 transition-transform flex flex-col items-center  z-1 ${styles.bgFill} ${className}`}
					onClick={onClick}
				>
					<span className="z-10 relative">{t(data)}</span>
					{custom[0] && (
						<>
							{custom[2]?.img ? (
								<CustomUnderlineBolder
									shape={custom[2]?.img}
									color={custom[1]?.color}
								/>
							) : (
								<Underline color={custom[1]?.color} />
							)}
						</>
					)}
				</button>
			) : type === "bgFillRound" ? (
				<button
					style={style as React.CSSProperties}
					className={`  py-2 px-12 font-light relative  hover:scale-110 transition-transform flex flex-col items-center rounded-full  z-1 ${styles.bgFill} ${className}`}
					onClick={onClick}
				>
					<span className="z-10 relative">{t(data)}</span>
					{custom[0] && (
						<>
							{custom[2]?.img ? (
								<CustomUnderlineBolder
									shape={custom[2]?.img}
									color={custom[1]?.color}
								/>
							) : (
								<Underline color={custom[1]?.color} />
							)}
						</>
					)}
				</button>
			) : (
				<button
					style={style as React.CSSProperties}
					className={` px-24 py-[.3rem] font-light relative  hover:scale-110 transition-transform ${className}`}
					onClick={onClick}
				>
					{t(data)}
				</button>
			)}
		</>
	);
};

// BUTTON COMPONENT //

export type ButtonMasterVariantProps = VariantProps<
	typeof ButtonMasterVariants
>;
const ButtonMasterVariants = cva(
	`flex flex-row items-center justify-center min-w-[100px] gap-4`,
	{
		variants: {
			type: {
				default: `${styles.default}  py-2  w-fit  group  brandCall text-ctaSizeSm md:text-ctaSize`,
				borderFull: `${styles.borderFull}  px-6 py-2 border w-fit group brandCall text-ctaSizeSm md:text-ctaSize`,
				borderFullRound: `${styles.borderFull}  px-6 py-2 border w-fit group brandCall text-ctaSizeSm md:text-ctaSize rounded-full border-2`,
				borderBottom: `${styles.bottom}   px-6 py-2   w-fit  group  brandCall text-ctaSizeSm md:text-ctaSize`,
				bgFill: `${styles.bgFill}  px-6 py-2  w-fit group  brandCall  text-ctaSizeSm md:text-ctaSize`,
				bgFillRound: `${styles.bgFill}  px-6 py-2  w-fit group  brandCall  text-ctaSizeSm md:text-ctaSize rounded-full`,
				round: `${styles.round} w-12 h-12 p-2  group  text-3xl rounded-full flex items-center brandCall`,
			},
		},
		defaultVariants: {
			type: "default",
		},
	}
);

export interface ButtonMasterProps
	extends React.HTMLAttributes<HTMLHeadingElement>,
		ButtonMasterVariantProps {
	color: any;
	link?: any;
	children?: any;
	cta: any;
	submit?: any;
	customColor?: any;
	anim?: string;
	target?: any;
	onClick?: any;
	customIcon?: any;
}

export function ButtonMaster({
	type,
	color,
	link,
	children,
	cta,
	submit,
	customColor,
	anim,
	target,
	onClick,
	customIcon,
}: ButtonMasterProps) {
	const style = {
		"--color": color?.hex,
		"--cta": cta?.hex,
		"--svgColor": customColor?.hex,
		"--svg": `url(${customIcon?.url})`,
	} as React.CSSProperties;
	if (link) {
		return (
			<LazyMotion features={domAnimation}>
				<m.div
					className={` w-fit h-fit relative z-30`}
					variants={buttonSliders(anim)}
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					exit="exit"
				>
					<button
						style={style as React.CSSProperties}
						className={`${ButtonMasterVariants({ type })}`}
					>
						{link == "none" ? (
							<div className="flex flex-row gap-2 lg:gap-4 items-center">
								{children}
								{customIcon ? (
									<div
										style={style as React.CSSProperties}
										className={`transition-all ease-in-out duration-300 group-hover:translate-x-[1rem]  ${styles.ctaIcons} `}
									></div>
								) : (
									<></>
								)}
							</div>
						) : (
							<CustomLink
								className={`flex flex-row gap-2 lg:gap-4 items-center text-ctaSizeSm md:text-ctaSize`}
								href={link}
								target={target}
							>
								{type == "round" ? <>+</> : <> {children}</>}

								{customIcon && (
									<div
										style={style as React.CSSProperties}
										className={`transition-all ease-in-out duration-300 group-hover:translate-x-[1rem]  ${styles.ctaIcons} `}
									></div>
								)}
							</CustomLink>
						)}
					</button>
				</m.div>
			</LazyMotion>
		);
	} else {
		return (
			<div className={` w-fit h-fit relative z-30 `}>
				<button
					disabled={submit}
					type="submit"
					onClick={onClick}
					style={style as React.CSSProperties}
					className={`${ButtonMasterVariants({ type })}`}
				>
					{type == "round" ? (
						<span className="text-ctaSizeSm md:text-ctaSize">+</span>
					) : (
						<>
							{" "}
							{children}
							{customIcon ? (
								<div
									style={style as React.CSSProperties}
									className={`${styles.ctaIcons} transition-all ease-in-out duration-300 group-hover:translate-x-[1rem]  `}
								></div>
							) : (
								<></>
							)}
						</>
					)}
				</button>
			</div>
		);
	}
}

export function ButtonSection({
	type,
	style,
	color,
	link,
	children,
	props,
}: LinkProps) {
	if (link) {
		return (
			<LazyMotion features={domAnimation}>
				<m.div
					className={` w-fit h-fit relative z-50`}
					variants={variantsFull()}
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					exit="exit"
				>
					<button
						style={style as React.CSSProperties}
						className={`${styles.borderFull} mt-8 md:mt-0  px-24 text-2xl py-2 border w-fit `}
					>
						<CustomLink
							className={`flex flex-row gap-4 items-center`}
							href={link}
						>
							{children}
						</CustomLink>
					</button>
				</m.div>
			</LazyMotion>
		);
	} else {
		return (
			<div
				className={`group w-sm relative italic text-lg sm:text-xl lg:text-2xl flex items-center space-x-2 cursor-pointer ${props.className} `}
			>
				<button>{props.children}</button>

				<span className="group-hover:translate-x-1 transition ease-in-out duration-200">
					<ArrowRight color={props.color} />
				</span>
			</div>
		);
	}
}
