import Image from "@/components/Atoms/Image";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { getPortfolioSlug, pageRelatedLink, slideIn } from "@/lib/functions";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { useRouter } from "next/router";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import { HeroLabel } from "@/components/Atoms/Paragraph";

import {
	Title,
	HeroSignatureTitle,
	HeroFeaturedTitle,
	BolderTitle,
} from "@/components/Atoms/Title";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import CustomImage from "@/components/Atoms/Image";

interface Props {
	title: string;
	description: string;
	label: any;
	color: any[any];
	layout: string;
	cta?: any;
	flex: string;
	underline: any;
}

export function SliderCopyCard({
	title,
	description,
	label,
	color,
	layout,
	cta,
	flex,
	underline,
}: Props) {
	const { locale }: any = useRouter();

	return (
		<>
			<div
				className={` ${
					flex == "center"
						? "items-center"
						: flex == "start"
						? "items-start"
						: "items-end"
				} ${
					layout == "left"
						? "justify-start"
						: layout == "right"
						? "justify-end"
						: layout == "center"
						? "justify-center"
						: ""
				} container mx-auto flex relative z-10 px-6 md:px-12 lg:px-16 h-full`}
			>
				<header className={` lg:max-w-[50rem]  flex flex-col gap-4 py-24`}>
					<div className="px-6 lg:px-4 xl:px-2 2xl:px-0">
						<LazyMotion features={domAnimation}>
							<m.div
								variants={slideIn()}
								initial="hidden"
								whileInView="visible"
								viewport={{ once: true }}
								className={`${
									layout === "center" ? "items-center" : ""
								} flex flex-col gap-12`}
							>
								<div className="flex flex-col gap-2 md:gap-6 ">
									{label[0] && (
										<>
											{label && (
												<HeroLabel
													layout={layout}
													color={color}
													copy={label[1]}
												/>
											)}
										</>
									)}
									{title && (
										<div className="flex flex-col">
											<Title
												color={color}
												tag="h2"
												data={title}
												layout={layout}
											/>
											{underline?.length > 0 && (
												<>
													{underline[1]?.img ? (
														<CustomUnderline
															shape={underline[1]?.img}
															color={underline[2]?.color}
														/>
													) : (
														<Underline color={underline[2]?.color} />
													)}
												</>
											)}
										</div>
									)}
									{description && (
										<HeroSignatureTitle
											color={color}
											tag={title ? `h3` : `h2`}
											data={description}
											layout={layout}
										/>
									)}
									{!label[0] && (
										<>
											{" "}
											{label[1] && (
												<>
													{label && <HeroLabel color={color} copy={label[1]} />}
												</>
											)}
										</>
									)}
								</div>

								{cta[0] && (
									<>
										{cta[1].map((entry: any, i: number) => {
											const portfolioSlug = getPortfolioSlug(
												entry.page,
												locale
											);
											if (entry.linksToProductPage) {
												return (
													<ButtonMaster
														key={i}
														type={entry?.buttonStyle}
														cta={entry?.typeColor?.color}
														color={entry?.labelColor?.color}
														customIcon={entry?.icon?.img}
														customColor={entry?.iconColor?.color}
														link={`${routerHandler({
															typename: entry?.product.product.__typename,
															slug: [
																portfolioSlug,
																entry?.product.product.productTypeSpecific
																	?.slug,
																entry?.product.product.slug,
															],
															locale: locale,
														})}`}
													>
														{entry?.label || entry?.buttonLabel?.label}
													</ButtonMaster>
												);
											} else {
												return (
													<ButtonMaster
														key={i}
														type={entry?.buttonStyle}
														cta={entry?.typeColor?.color}
														color={entry?.labelColor?.color}
														customIcon={entry?.icon?.img}
														customColor={entry?.iconColor?.color}
														link={`${
															entry?.page?.__typename == "ArticleRecord"
																? `${locale}/news/${entry?.page?.slug}`
																: `${routerHandler({
																		typename: entry?.__typename,
																		slug: pageRelatedLink(entry?.page, locale),
																		locale: locale,
																  })}`
														}`}
													>
														{entry?.label || entry?.buttonLabel?.label}
													</ButtonMaster>
												);
											}
										})}
									</>
								)}
							</m.div>
						</LazyMotion>
					</div>
				</header>
			</div>
		</>
	);
}

interface ImageCopyCardProps extends Props {
	swapTitle: boolean;
	imageReplacer: any;
	width?: string;
	labelPosition?: boolean;
	imagePosition?: string;
	underline: any;
	labelColor?: any;
	className?: any;
}

export function ImageCopyCard({
	title,
	description,
	label,
	color,
	swapTitle,
	imageReplacer,
	labelPosition,
	layout,
	width,
	flex,
	imagePosition,
	underline,
	labelColor,
	className,
}: ImageCopyCardProps) {
	return (
		<>
			<div
				className={`  ${
					layout == "left"
						? "justify-start text-left"
						: "justify-center text-center "
				} container mx-auto flex relative z-10 px-6 md:px-12 lg:px-16 h-fit pb-[5rem] pt-[100px] ${className}`}
			>
				{imagePosition == "featured" ? (
					<header
						className={` flex flex-col md:gap-4 items-center  w-full  ${
							width === "full" ? "w-full" : "lg:max-w-[50rem]"
						} ${layout == "left" ? "" : " lg:pb-6"}`}
					>
						<Image className={`w-full max-w-[30rem]`} data={imageReplacer} />
						<div className="lg:max-w-[50rem]">
							<HeroFeaturedTitle
								color={color}
								tag="h4"
								data={description}
								layout={layout}
							/>
						</div>
						{!labelPosition && (
							<HeroSignatureTitle
								color={labelColor || color}
								tag="h3"
								data={label}
								layout={layout}
							/>
						)}
					</header>
				) : (
					<header
						className={` flex flex-col gap-2 lg:gap-8  w-full ${
							layout == "left"
								? "items-start"
								: layout == "fixedCenter"
								? "items-center"
								: layout == "center"
								? "items-center"
								: "items-end"
						} ${width === "full" ? "w-full" : "lg:max-w-[50rem]"} ${
							layout == "left" ? "" : " lg:pb-6"
						}`}
					>
						{swapTitle && (
							<>
								{imageReplacer && (
									<Image
										className={`${
											imageReplacer?.responsiveImage?.aspectRatio > 1
												? "max-w-[15rem] md:max-w-[25rem] lg:max-w-[35rem]"
												: `${
														imageReplacer?.format == "svg"
															? `max-w-[6rem]`
															: `max-w-[25rem]`
												  }`
										}`}
										data={imageReplacer.url}
									/>
								)}
							</>
						)}
						{labelPosition && (
							<HeroSignatureTitle
								color={labelColor || color}
								tag="h3"
								data={label}
								layout={layout}
							/>
						)}
						{swapTitle ? (
							<>
								{!imageReplacer && (
									<HeroSignatureTitle
										color={color}
										tag="h2"
										data={title}
										layout={layout}
									/>
								)}
							</>
						) : (
							<div className="flex flex-col w-fit">
								<Title color={color} tag="h2" data={title} layout={layout} />
								{underline && (
									<>
										{underline[1]?.img ? (
											<CustomUnderline
												shape={underline[1]?.img}
												color={underline[2]?.color}
											/>
										) : (
											<Underline color={underline[2]?.color} />
										)}
									</>
								)}
							</div>
						)}
						{description && (
							<div className="lg:max-w-[50rem]">
								<HeroFeaturedTitle
									color={color}
									tag="h4"
									data={description}
									layout={layout}
								/>
							</div>
						)}

						{label && (
							<>
								{" "}
								{!labelPosition && (
									<HeroSignatureTitle
										color={color}
										tag="h3"
										data={label}
										layout={layout}
									/>
								)}
							</>
						)}
					</header>
				)}
			</div>
		</>
	);
}

interface FeaturedProps {
	title: string;
	description: string;
	descriptionColor: any;
	color: any[any];
	cta: any;
	parallaxImage?: any;
}

export function FeaturedCopyCard({
	title,
	description,
	color,
	descriptionColor,
	cta,
	parallaxImage,
}: FeaturedProps) {
	const { locale }: any = useRouter();
	return (
		<header className={` flex flex-col md:gap-4 items-center  w-full`}>
			<div className=" max-w-[60%] xl:max-w-[40rem] flex flex-col gap-4 md:gap-6 items-center pt-6 lg:pt-0 ">
				<div className="relative">
					<BolderTitle
						className="font-bold"
						color={color}
						tag="h2"
						data={title}
						layout={"center"}
					/>
					{parallaxImage?.[0] && (
						<div className="absolute left-0  translate-x-[-2rem] md:translate-x-[-3rem] bottom-0 top-0 flex justify-center">
							<CustomImage
								className="max-w-[1.5rem] md:max-w-[3rem]"
								data={parallaxImage[1]}
							/>
						</div>
					)}
					{parallaxImage?.[0] && (
						<div className="absolute right-0 translate-x-[2rem]  md:translate-x-[3rem] bottom-0 top-0 flex justify-center">
							<CustomImage
								className="max-w-[1.5rem] md:max-w-[3rem]"
								data={parallaxImage[1]}
							/>
						</div>
					)}
				</div>
				{description && (
					<BolderTitle
						color={descriptionColor}
						tag="h4"
						data={description}
						layout={"center"}
					/>
				)}
				{cta[0] && (
					<>
						{cta[1].map((entry: any, i: number) => {
							if (entry.linksToProductPage) {
								const portfolioSlug = getPortfolioSlug(entry.page, locale);
								return (
									<ButtonMaster
										key={i}
										type={entry?.buttonStyle}
										cta={entry?.typeColor?.color}
										color={entry?.labelColor?.color}
										customIcon={entry?.icon?.img}
										customColor={entry?.iconColor?.color}
										link={`${routerHandler({
											typename: entry?.product.product.__typename,
											slug: [
												portfolioSlug,
												entry?.product.product.productTypeSpecific?.slug,
												entry?.product.product.slug,
											],
											locale: locale,
										})}`}
									>
										{entry?.label || entry?.buttonLabel?.label}
									</ButtonMaster>
								);
							} else {
								return (
									<ButtonMaster
										key={i}
										type={entry?.buttonStyle}
										cta={entry?.typeColor?.color}
										color={entry?.labelColor?.color}
										customIcon={entry?.icon?.img}
										customColor={entry?.iconColor?.color}
										link={`${routerHandler({
											typename: entry?.__typename,
											slug: pageRelatedLink(entry?.page, locale),
											locale: locale,
										})}`}
									>
										{entry?.label || entry?.buttonLabel?.label}
									</ButtonMaster>
								);
							}
						})}
					</>
				)}
			</div>
		</header>
	);
}
