import { Title } from "@/atoms/Title";
import { Paragraph } from "@/atoms/Paragraph";
import { Spacer } from "@/components/Atoms/Vectors";
import { Underline } from "@/atoms/Underline";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import { useTranslation } from "next-i18next";
import { routerHandler } from "@/lib/routes/router-links-handler";
import Image from "@/components/Atoms/Image";

import styles from "./index.module.scss";
import { useRouter } from "next/router";

interface Props {
  title: string;
  description: string;
  pageRelated: any[any];
  card: any[];
  navArrow: any[any];
  related: any[any];
  underline: boolean;
  underlineColor: any;
  customUnderline: any;
  image: any[any];
  portfolioSlug: any;
}

export const SliderCard = ({
  title,
  description,
  pageRelated,
  card,
  navArrow,
  underline,
  underlineColor,
  portfolioSlug,
  image,
}: Props) => {
  const style = {
    "--color": card[2]?.hex,
    "--bg": card[1]?.hex,
    "--arrow": navArrow[1]?.hex,
  } as React.CSSProperties;

  const { t } = useTranslation();
  const { locale }: any = useRouter();
  switch (card[0]) {
    case "oblique":
      return (
        <>
          <article
            style={style as React.CSSProperties}
            className={`${styles.obliqueCard} mt-12 md:mt-0 md:absolute md:pt-8 w-full md:w-[30rem] flex-col hidden min-h-[23rem] gap-12 left-[54%] bottom-[10%] px-12 md:px-[5rem] pb-12 items-center `}
          >
            <Spacer style={card[0]} type="card" color={card[1]} />
            <div className="pt-6 md:pt-0 flex flex-col gap-4 md:gap-8">
              <div className="flex flex-col gap-2">
                <Title
                  tag={"h4"}
                  data={title}
                  color={card[2]}
                  layout={"left"}
                />
                {underline && (
                  <>
                    <Underline color={underlineColor} />
                  </>
                )}
              </div>
              <div className=" hidden md:flex">
              <Paragraph copy={description !== "" ? description : title} color={card[2]} />
              </div>
            </div>
            <Image className={`md:hidden`} data={image} />
            {pageRelated[0] && (
              <>
                <ButtonMaster
                  anim={"slideTop"}
                  type={pageRelated[1]}
                  cta={pageRelated[3]}
                  color={pageRelated[2]}
                  customIcon={pageRelated[7]?.img}
                  customColor={pageRelated[2]}
                  link={`${routerHandler({
                    typename: pageRelated[4],
                    slug: [
                      portfolioSlug,
                      pageRelated[6]?.slug,
                      pageRelated[0],
                      ,
                    ],
                    locale: locale,
                  })}`}
                >
                  {t("seeMore")}
                </ButtonMaster>
              </>
            )}
          </article>
        </>
      );
      break;
    case "default":
      return <></>;
      break;
    default:
      return <></>;
      break;
  }
};
