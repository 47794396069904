import { Image } from "react-datocms";

const siteName = process.env.NEXT_PUBLIC_WEBSITE_NAME;

interface ImageProps {
  data: any;
  className?: string;
  ariaHidden?: any;
  draggable?: any;
  layout?: any;
  lazyLoad?: any;
  onLoad?: any;
}

export default function CustomImage({
  data,
  className,
  ariaHidden,
  draggable,
  layout,
  lazyLoad,
  onLoad,
}: ImageProps) {
  if (!data) {
    return <></>;
  }

  if (!data.responsiveImage) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        src={data.url || data}
        width={data?.width}
        height={data?.height}
        alt={data.alt || siteName}
        className={className}
        draggable={draggable}
        loading={"lazy"}
        aria-hidden={ariaHidden}
      ></img>
    );
  }

  const placeholder = /.(jpg|jpeg)/.test(data.responsiveImage.src);
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      data={{
        ...data.responsiveImage,
        alt: ariaHidden ? "" : data.alt || data.title,
      }}
      layout={layout || "responsive"}
      onLoad={onLoad}
      className={className}
      fadeInDuration={10}
      lazyLoad={lazyLoad || true}
      usePlaceholder={false}
      style={
        className === undefined
          ? { maxWidth: `${data.responsiveImage.width}px`, margin: "auto" }
          : {}
      }
    />
  );
}
