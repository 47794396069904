import { fetchAPI } from "@/lib/api";
import { responsiveImageFragment, pageRelatedFragment } from "@/lib/fragments";
import {
	HeroSliderFragment,
	TextImageFragment,
	ImageVideoFragment,
	HeroImageFragment,
	TestimonialSectionFragment,
	SectionGalleryFragment,
	FormFragment,
	HeroVideoFragment,
	SectionQuoteFragment,
	TextFragment,
	TextGalleryFragment,
	SectionLocationFragment,
	SectionExperienceFragment,
	SectionSocialFragment,
	SectionGalleryVideoFragment,
	HeroFeaturedFragment,
	StoreLocatorFragment,
	SectionBrandArticleFragment,
	SectionBrandRecipeFragment,
	GrapeVarietyFragment,
	SectionMultiPurposeBlock,
	SectionTimelineFragment,
	SectionWineTourismFragment,
} from "@/lib/section-fragment";

export async function getPage(slug, locale, preview, siteConfig) {
	const data = await fetchAPI(
		`
        query PageBySlug($slug: String, $locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          brandsPage(filter: {slug: {eq: $slug}, siteConfig: {eq: "${siteConfig}"}}, locale: $locale) {
              _allSlugLocales {
                locale
                value
              }
              title
              seo: _seoMetaTags {
                tag
                attributes
                content
              }
              metadata {
                description
                title
                twitterCard
              }
              hero{
                __typename
                ...HeroSliderFragment
                ...HeroImageFragment
                ...HeroVideoFragment
                ...HeroFeaturedFragment 
                
              }
              sections {
                __typename
                ...TextImageFragment
                ...TestimonialSectionFragment
                ...SectionGalleryFragment
                ...FormFragment
                ...ImageVideoFragment
                ...SectionQuoteFragment
                ...TextFragment
                ...TextGalleryFragment
                ...SectionLocationFragment
                ...SectionExperienceFragment
                ...SectionSocialFragment
                ...SectionGalleryVideoFragment
                ...StoreLocatorFragment 
                ...SectionBrandArticleFragment
                ...SectionBrandRecipeFragment
                ...GrapeVarietyFragment
                ...SectionMultiPurposeBlock
                ...SectionTimelineFragment
                ...SectionWineTourismFragment
              }
            }
            brandsProduct(filter: {siteConfig: {eq: "${siteConfig}"}}) {
              __typename
              slug
              typeList
            }
          }
          
          
          ${responsiveImageFragment}
          ${pageRelatedFragment}
          ${HeroSliderFragment}
          ${HeroImageFragment}
          ${TextImageFragment}
          ${TextGalleryFragment}
          ${TestimonialSectionFragment}
          ${SectionGalleryFragment}
          ${FormFragment}
          ${ImageVideoFragment}
          ${HeroVideoFragment}
          ${SectionQuoteFragment}
          ${TextFragment}
          ${SectionLocationFragment}
          ${SectionExperienceFragment}
          ${SectionSocialFragment}
          ${SectionGalleryVideoFragment}
          ${HeroFeaturedFragment}
          ${StoreLocatorFragment}
          ${SectionBrandArticleFragment}
          ${SectionBrandRecipeFragment}
          ${GrapeVarietyFragment}
          ${SectionMultiPurposeBlock}
          ${SectionTimelineFragment}
          ${SectionWineTourismFragment}
    `,
		{
			preview,
			variables: {
				slug,
				locale,
			},
		}
	);
	return data;
}
