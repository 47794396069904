import { DropdownIcon } from "@/components/Atoms/Vectors";
import { useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import styles from "./index.module.scss";
import CustomLink from "@/components/Atoms/CustomLink";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { useRouter } from "next/router";

interface Props {
  data: any;
  bg: any;
  text: any;
  portfolioSlug: string;
}

export default function MenuPortfolio({
  data,
  bg,
  text,
  portfolioSlug,
}: Props) {
  const style = {
    "--color": text?.color.hex,
  } as React.CSSProperties;
  const [active, setActive] = useState(null);
  const { locale }: any = useRouter();
  const toggleDrop = (i: any) => {
    if (i == active) {
      setActive(null);
    } else {
      setActive(i);
    }
  };

  const { asPath } = useRouter();

  useEffect(() => {
    const hash = asPath?.split("#")?.[1];
    if (hash == "mpf") {
      setTimeout(() => {
        const elemento = document.getElementById("final") as HTMLInputElement;
        elemento.scrollIntoView({ behavior: "smooth" });
      }, 750);
    }
  }, []);

  return (
    <div
      style={{ backgroundColor: bg?.color.hex }}
      className="w-full scroll-mt-24"
      id="final"
    >
      <Popover.Group className=" grid  md:flex flex-row justify-center gap-4 lg:gap-24 py-4 w-fit m-auto  font-secondary relative pb-8">
        {data.map((entry: any, i: number) => {
          if (entry.menuItem.length <= 1) {
            return (
              <div style={style as React.CSSProperties} className="">
                <CustomLink
                  className={`${styles.menuText} uppercase`}
                  href={`${routerHandler({
                    typename: entry.title?.__typename,
                    slug: [portfolioSlug, entry.menuItem?.[0]?.slug],
                    locale: locale,
                  })}`}
                >
                  {entry.title.title}
                </CustomLink>
              </div>
            );
          } else {
            return (
              <>
                {" "}
                <Popover className="relative">
                  <Popover.Button
                    onClick={() => toggleDrop(i)}
                    style={style as React.CSSProperties}
                    className={`uppercase m-auto  font-secondary flex flex-row items-center gap-2 relative  text-xs sm:text-base  ${styles.menuText}`}
                  >
                    {entry.title.title}
                    <DropdownIcon
                      id={i}
                      className={`${
                        active === i ? "scale-[-1]" : "scale-1"
                      }  transition duration-300 ease-out `}
                      color={text?.color?.hex}
                    />
                  </Popover.Button>

                  <Popover.Panel className="w-full">
                    <Transition
                      style={{ backgroundColor: bg.color.hex }}
                      className={`md:absolute top-12  md:min-w-[15rem] border-0 md:px-6 focus:outline-none flex flex-col  z-10 py-6 text-xs sm:text-base gap-6 `}
                      enter="transition duration-300 ease-out"
                      enterFrom="transform scale-95 opacity-0 -translate-y-4"
                      enterTo="transform scale-100 opacity-100 translate-y-0"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      {entry.menuItem.map((item: any, i: number) => (
                        <div style={style as React.CSSProperties} className="m-auto md:m-0">
                          <CustomLink
                            className={`${styles.menuText} hover:border-b pb-2 transition duration-75 `}
                            href={`${routerHandler({
                              typename: item.__typename,
                              slug: [portfolioSlug, `${item.slug}#mpf`],
                              locale: locale,
                            })}`}
                          >
                            {item.title}
                          </CustomLink>
                        </div>
                      ))}
                    </Transition>
                  </Popover.Panel>
                </Popover>
              </>
            );
          }
        })}
      </Popover.Group>
    </div>
  );
}
