import { useTranslation } from "next-i18next";
import Image from "@/components/Atoms/Image";
import styles from "./index.module.scss";
import { FeaturedTitle } from "@/components/Atoms/Title";
import {
  ImageLabel,
  Paragraph,
  ParagraphNumber,
} from "@/components/Atoms/Paragraph";
import { Disclosure, Transition } from "@headlessui/react";

import { Chevron } from "@/components/Atoms/Vectors";
interface Props {
  content: any;
  color: any;
  blockBgColor: any;
  conf: boolean;
  year: number;
}
export function RatingCard({
  content,
  color,
  blockBgColor,
  conf,
  year,
}: Props) {
  const { t } = useTranslation();
  const style = {
    "--r": blockBgColor?.color.red,
    "--g": blockBgColor?.color.green,
    "--b": blockBgColor?.color.blue,
  } as React.CSSProperties;

  return (
    <>
      <article
        style={style}
        className={`${styles.blockContainer} flex flex-col p-8`}
      >
        <div className="flex   flex-row items-center gap-6">
          <Image
            className="m-auto max-w-[5rem] lg:max-w-[7rem]"
            data={content.rating?.ratingImage}
          />
          <div className="flex flex-col min-w-[50%] flex-1">
            <Paragraph copy={t("review")} color={color} />
            <FeaturedTitle
              layout="left"
              color={color}
              tag="h4"
              data={content.mediaPublication?.title}
            />
          </div>
          {conf && (
            <Image
              className=" lg:flex m-auto  max-w-[4rem] lg:max-w-[6rem]"
              data={content.mediaPublication?.image}
            />
          )}
        </div>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex gap-4 items-center ">
                {!open ? (
                  <span>{t("showDetail")}</span>
                ) : (
                  <span>{t("hideDetail")}</span>
                )}
                <span
                  className={`${
                    open ? "rotate-[-90deg] transform" : "rotate-90"
                  } `}
                >
                  <Chevron color={color} />
                </span>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform opacity-0 -translate-y-2"
                enterTo="transform opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform  opacity-100"
                leaveTo="transform -translate-y-2 opacity-0"
              >
                <Disclosure.Panel className="pt-4 pb-2 flex flex-col">
                  <div className="mb-4 flex fle-row gap-12">
                    {content.taster?.length > 0 && (
                      <div>
                        {" "}
                        <ImageLabel copy={t("taster")} color={color} />
                        <div className="flex flex-col gap-1">
                          {content.taster.map((x: any, index: number) => (
                            <Paragraph
                              key={index}
                              copy={x?.title}
                              color={color}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                    <div>
                      <ImageLabel copy={t("country")} color={color} />
                      <ParagraphNumber
                        copy={content.country?.title}
                        color={color}
                      />
                    </div>
                    <div>
                      <ImageLabel copy={t("year")} color={color} />
                      <ParagraphNumber copy={year} color={color} />
                    </div>
                  </div>
                  {content.tastingNote.length > 0 && (
                    <>
                      <ImageLabel copy={t("review")} color={color} />
                      <Paragraph copy={content.tastingNote} color={color} />
                    </>
                  )}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </article>
    </>
  );
}

export function AwardCard({ content, color, blockBgColor, conf, year }: Props) {
  const { t } = useTranslation();
  const style = {
    "--r": blockBgColor?.color.red,
    "--g": blockBgColor?.color.green,
    "--b": blockBgColor?.color.blue,
  } as React.CSSProperties;

  return (
    <>
      <article
        style={style}
        className={`${styles.blockContainer} flex flex-col p-8`}
      >
        <div className="flex flex-row items-center gap-6">
          <Image
            className="m-auto max-w-[5rem] lg:max-w-[7rem]"
            data={content.award?.awardImage}
          />
          <div className="flex flex-col min-w-[50%] flex-1">
            <Paragraph
              copy={content.award.awardTypes?.awardCategory}
              color={color}
            />
            <FeaturedTitle
              layout="left"
              color={color}
              tag="h4"
              data={content.wineContest?.title}
            />
          </div>
          {conf && (
            <Image
              className=" lg:flex m-auto  max-w-[4rem] lg:max-w-[6rem]"
              data={content.wineContest?.image}
            />
          )}
        </div>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex gap-4 items-center ">
                {!open ? (
                  <span>{t("showDetail")}</span>
                ) : (
                  <span>{t("hideDetail")}</span>
                )}
                <span
                  className={`${
                    open ? "rotate-[-90deg] transform" : "rotate-90"
                  } `}
                >
                  <Chevron color={color} />
                </span>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform opacity-0 -translate-y-2"
                enterTo="transform opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform  opacity-100"
                leaveTo="transform -translate-y-2 opacity-0"
              >
                <Disclosure.Panel className="pt-4 pb-2 flex flex-col">
                  <div className="mb-4 flex fle-row gap-12">
                    <div>
                      <ImageLabel copy={t("country")} color={color} />
                      <ParagraphNumber
                        copy={content.country?.title}
                        color={color}
                      />
                    </div>
                    <div>
                      <ImageLabel copy={t("year")} color={color} />
                      <ParagraphNumber copy={year} color={color} />
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </article>
    </>
  );
}
