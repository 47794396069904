import { useState } from "react";
import Image from "@/components/Atoms/Image";
import styles from "./index.module.scss";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { variants } from "@/lib/functions";

interface Props {
  data: any;
  position?: string;
  className?: string;
  options?: string;
  padding?: string;
}

export function FullBackgroundImage({
  data,
  position,
  className,
  padding,
}: Props) {
  const [imageLoading, setImageLoading] = useState(false);
  const style = { "--style": padding } as React.CSSProperties;
  if (position == "Contain") {
    return (
      <LazyMotion features={domAnimation}>
        <m.div
          variants={variants("")}
          initial="hidden"
          whileInView={`${imageLoading && "visible"}`}
          viewport={{ once: true }}
          exit="exit"
          key={data.id}
          className={` top-0 w-full h-full absolute translate-x-[100%] `}
        >
          <Image
            onLoad={() => setImageLoading(true)}
            className={`top-0 w-full h-full  ${
              position == "Contain"
                ? `${styles.bgContain}`
                : `${styles.bgCover}`
            } `}
            data={data}
          />
        </m.div>
      </LazyMotion>
    );
  } else {
    return (
      <div className={styles.customStyle} style={style as React.CSSProperties}>
        <Image
          className={`top-0 w-full h-full object-center   ${className}
          } ${
            position == "contain"
              ? `${styles.bgContainSection}`
              : `${styles.bgCover}`
          } `}
          data={data}
        />
      </div>
    );
  }
}

export function FullBackgroundImageHero({
  data,
  position,
  className,
  padding,
}: Props) {
  const [imageLoading, setImageLoading] = useState(false);
  const style = {
    "--style": padding,
    "--x": `${data.focalPoint?.x}%`,
    "--y": `${data.focalPoint?.y}%`,
  } as React.CSSProperties;

  if (position == "Contain") {
    return (
      <LazyMotion features={domAnimation}>
        <m.div
          variants={variants("")}
          initial="hidden"
          whileInView={`${imageLoading && "visible"}`}
          viewport={{ once: true }}
          exit="exit"
          key={data.id}
          className={` top-0 w-full h-full absolute translate-x-[100%]`}
        >
          <Image
            onLoad={() => setImageLoading(true)}
            className={`top-0 w-full h-full  ${
              position == "Contain"
                ? `${styles.bgContain}`
                : `${styles.bgCover}`
            } `}
            data={data}
          />
        </m.div>
      </LazyMotion>
    );
  } else {
    return (
      <div className={styles.customStyle} style={style as React.CSSProperties}>
        <Image
          className={`top-0 w-full h-full  ${className}
          } ${
            position == "contain"
              ? `${styles.bgContainSection}`
              : `${styles.bgCover}`
          } `}
          data={data}
        />
      </div>
    );
  }
}

export function IconImage({ data }: Props) {
  return (
    <>
      <Image
        className={`max-w-[6rem] mx-auto rounded-full aspect-square object-cover `}
        data={data}
      />
    </>
  );
}

export function GalleryImage({ data, options }: Props) {
  return (
    <>
      <Image
        className={`${styles.imageGallery}  ${
          options == "rounded"
            ? "rounded-lg"
            : options == "roundedXl"
            ? "rounded-3xl"
            : ""
        }  overflow-hidden`}
        data={data}
      />
    </>
  );
}
