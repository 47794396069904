import { Paragraph } from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";
import RecipeBlock from "@/components/Molecules/RecipeBlock";
import { string_to_slug } from "@/lib/functions";
import { VariantProps, cva } from "class-variance-authority";

export type SectionBrandRecipesVariantProps = VariantProps<
	typeof SectionBrandRecipesVariants
>;
const SectionBrandRecipesVariants = cva(` flex w-full flex-col gap-4 `, {
	variants: {
		titleBlockPosition: {
			left: "items-start",
			default: "items-center text-center",
		},
	},
	defaultVariants: {
		titleBlockPosition: "default",
	},
});

export interface titleBlockPosition
	extends React.HTMLAttributes<HTMLHeadingElement>,
		SectionBrandRecipesVariantProps {
	bgColor: any;
	title: string;
	hideTitle: boolean;
	useSectionMargins: boolean;
	headerColor: any;
	layout: "default" | "featured" | "gridRounded" | null | undefined;
	relations: any;
	articleTitleColor: any;
	excerptColor: any;
	ctaConfig: any;
	sectionCtaConfig: any;
	highlightColor: any;
	description: string;
}

export default function SectionBrandRecipes({
	titleBlockPosition,
	bgColor,
	headerColor,
	title,
	hideTitle,
	useSectionMargins,
	layout,
	relations,
	articleTitleColor,
	excerptColor,
	ctaConfig,
	sectionCtaConfig,
	highlightColor,
	description,
}: titleBlockPosition) {
	return (
		<div
			style={{ backgroundColor: bgColor?.color.hex }}
			id={string_to_slug(title)}
			className="w-full"
		>
			<div
				className={`flex flex-col gap-4 lg:gap-6  items-center container mx-auto ${
					useSectionMargins ? "py-12 lg:py-24" : "py-12"
				} px-6 lg:px-4 xl:px-2 2xl:px-0 2xl:max-w-[1360px]`}
			>
				<header
					className={` ${SectionBrandRecipesVariants({ titleBlockPosition })} `}
				>
					{!hideTitle && (
						<Title tag={"h3"} data={title} color={headerColor?.color} />
					)}
					{description && (
						<Paragraph copy={description} color={headerColor?.color} />
					)}
				</header>

				<RecipeBlock
					layout={layout}
					data={relations}
					color={articleTitleColor}
					excerpt={excerptColor}
					cta={ctaConfig}
					load={sectionCtaConfig}
					highlight={highlightColor}
					labelColor={bgColor}
				/>
			</div>
		</div>
	);
}

export const query = `
    query BrandArticlesByCategory($locale: SiteLocale = en, $websiteConfiguration  : [ItemId],  $datefrom: DateTime = "01-01-2000", $dateto: DateTime = "01-01-2100") {
      allRecipes (filter: { websiteConfiguration: {eq: $websiteConfiguration },  _publishedAt: {gte: $datefrom, lte: $dateto} },  orderBy: _firstPublishedAt_DESC, locale: $locale) {
        websiteConfiguration {
          ... on SiteConfigurationRecord{
        id
      }
        }
        layoutType
        slug
        metadata {
          description
          title
          twitterCard
        }
        _seoMetaTags {
          attributes
          content
        }
        pairing {
        ... on ProductRecord{
          productName
          }
        }
        name
        alternativeHeadline
        addOverlay
        featuredImage{
          focalPoint {
            x
            y
          }
          responsiveImage (imgixParams: {q: "65", maxW: "1250"}) {
            ...responsiveImageFragment
           }
        }
        image {
          responsiveImage (imgixParams: {q: 65, maxW: 650}) {
           ...responsiveImageFragment
          }
        }
        author {
          title
        }
        recipeCategory {
          title
        }
        recipeDetails {
          detailType
          value
        }
        cuisine {
          title
        }
        suitableForDiet
        description
        difficulty
        ingredients {
          __typename
          ... on IngredientsSectionRecord {
            id
            headline {
              label
            }
          }
          headline {
            label
          }
          ingredient {
            ingredient {
              ingredientName
            }
            measures {
              label
            }
          }
        }
        mediaGalleryTitle
        media {
          ... on ImageRecord {
            id
          }
          ... on VideoRecord {
            id
          }
        }
        addTips
        tip
        recipeInstructions {
          headline
          step {
            name
            text
            addImage
            image {
              responsiveImage (imgixParams: {q: 65, maxW: 650}) {
                ...responsiveImageFragment
               }
            }
          }
        }
      }
    }
`;

export const variables = (data: any) => {
	return {
		websiteConfiguration: data.websiteConfiguration.id,
	};
};

export const fragment = `
    fragment SectionBrandRecipeFragment on SectionBrandRecipeRecord{
        __typename
        title
        hideTitle
        description
        titleBlockPosition
        useSectionMargins
        websiteConfiguration {
          id
        }
        layout
        headerColor {
          color {
            hex
          }
        }
        highlightColor {
          color {
            hex
          }
        }
        bgColor {
          color{
            hex
          }
        }
        articleTitleColor {
          color {
            hex
          }
        }
        excerptColor {
          color {
            hex
          }
        }
        ctaConfig {
          ...pageRelatedFragment
        }
        sectionCtaConfig {
          ...pageRelatedFragment
        }
  }
`;
