import Image from "next/image";

interface BrandGridProps {
	brands: any[];
	iconColor: any;
}
export const BrandGrid = ({ brands, iconColor }: BrandGridProps) => {
	return (
		<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-y-14 gap-x-0 md:gap-y-20 md:gap-x-20 lg:gap-y-20 lg:gap-x-20 container mx-auto items-center justify-center">
			{brands?.map((brand, index: number) => (
				<Image
					src={brand?.primaryLogotypeProductBrand.url}
					width={brand?.primaryLogotypeProductBrand.width}
					height={brand?.primaryLogotypeProductBrand.height}
					alt={brand.primaryLogotypeProductBrand || "Brand Logo"}
					key={index}
					style={{ fill: iconColor }}
				/>
			))}
		</div>
	);
};
