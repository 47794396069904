import { pageHandler } from "@/lib/pages-handler";
import { getPage } from "@/dato-api/landingPages";
import dynamic from "next/dynamic";
import Blocks from "@/components/Organisms";
import {
  NotificationContextProvider,
  buildHrefLangsList,
  getPortfolioSlug,
} from "@/lib/functions";
import { useEffect } from "react";
import { useRouter } from "next/router";
import HrefLangs from "@/components/Atoms/hrefLangs";
import OgUrl from "@/components/Atoms/ogUrl";
import { fetchComponentRelations } from "@/lib/api";

const Hero = dynamic(() => import("@/components/Organisms/Hero"));

export default function LandingPage({ data, relations }) {
  const { asPath } = useRouter();

  useEffect(() => {
    const hash = asPath?.split("#")?.[1];
    if (hash !== undefined && hash.length > 0)
      document.getElementById(hash).scrollIntoView({ behavior: "smooth" });
  }, []);

  const { locale } = useRouter();

  return (
    <>
      <NotificationContextProvider>
        <HrefLangs links={data.hrefLangs} />
        <OgUrl
          url={
            data.hrefLangs.filter((link) => link.hrefLang === locale)?.[0]?.href
          }
        />
        <Hero data={data.hero} />
        <Blocks
          sections={data.sections}
          relations={relations}
          portfolioSlug={data.portfolioSlug}
        ></Blocks>
      </NotificationContextProvider>
    </>
  );
}

export async function _getStaticProps(
  context,
  pageType,
  serverSideTranslations,
  siteConfig
) {
  const preview = context.preview !== undefined ? context.preview : false;
  const currentSlug =
    context.params.slug.length > 1
      ? context.params.slug[1]
      : context.params.slug[0];
  const result = await getPage(
    currentSlug,
    context.locale,
    preview,
    siteConfig.siteConfiguration.id
  );

  const pageData = await pageHandler(
    context,
    serverSideTranslations,
    result,
    "brandsPage"
  );

  const portfolioSlug = getPortfolioSlug(result?.brandsProduct, context.locale);

  const _allSlugLocales = pageData.data?._allSlugLocales;

  const hrefLangs = buildHrefLangsList(
    _allSlugLocales,
    pageData.data?.__typename
  );
  
  const relations = await fetchComponentRelations(
    { sections: pageData.data?.sections },
    context.locale,
    context.preview
  );

  return {
    props: {
      siteConfig: siteConfig.siteConfiguration,
      ...pageData.sst,
      data: { ...pageData.data, hrefLangs, portfolioSlug },
      preview: pageData.preview,
      relations: relations,
      pageType,
    },
  };
}
