import { FeaturedQuoteTitle, Title } from "@/components/Atoms/Title";
import { FeaturedParagraph } from "@/components/Atoms/Paragraph";
import Image from "@/components/Atoms/Image";
import { VariantProps, cva } from "class-variance-authority";
import QuoteSlider from "@/components/Molecules/QuoteSlider";
import { Shape } from "@/components/Atoms/Spacer";

export type BlockVariantProps = VariantProps<typeof BlockVariants>;
const BlockVariants = cva(
	`px-6 lg:px-12 flex flex-col justify-center items-center py-6 md:py-12 lg:py-24  gap-12 `,
	{
		variants: {
			layout: {
				container: `lg:max-w-[70rem] `,
				fullWidth: ``,
				featured: " 2xl:max-w-[1360px]",
			},
		},
		defaultVariants: {
			layout: "container",
		},
	}
);

interface Props {
	layout: "container" | "fullWidth" | "featured";
	bgColor: any;
	textColor: any;
	textSize: string;
	quote: string;
	description: string;
	wantDescription: boolean;
	addImageAsset: boolean;
	asset: any;
	quotesNumber: boolean;
	quotesConfiguration: any;
	addSpacer: boolean;
	spacerConfiguration: any;
}

export default function Quote({
	layout,
	bgColor,
	quote,
	textColor,
	textSize,
	wantDescription,
	description,
	addImageAsset,
	asset,
	quotesNumber,
	quotesConfiguration,
	addSpacer,
	spacerConfiguration,
}: Props) {
	switch (quotesNumber) {
		case true:
			return (
				<div
					style={{ background: bgColor?.color.hex }}
					className={` flex flex-col justify-center items-center m-auto w-full relative`}
				>
					{" "}
					{addSpacer && (
						<Shape
							shape={spacerConfiguration?.customShape}
							color={bgColor}
							direction={spacerConfiguration?.spacerDirection}
							icon={[
								spacerConfiguration?.addIconElement,
								spacerConfiguration?.spacerIcon,
							]}
							position={spacerConfiguration?.spacerPosition}
							invert={false}
						/>
					)}
					<article className={BlockVariants({ layout })}>
						{textSize == "default" ? (
							<Title
								tag={`${layout == "container" ? "h4" : "h3"}`}
								data={quote}
								color={textColor?.color}
								layout="fixedCenter"
								className={` ${
									layout == "featured" ? "brandQuoteFeatured" : ""
								}  `}
							/>
						) : (
							<FeaturedQuoteTitle
								tag={`${layout == "container" ? "h4" : "h3"}`}
								data={quote}
								color={textColor?.color}
								layout="fixedCenter"
								className={` ${
									layout == "featured" ? "brandQuoteFeatured" : ""
								}  `}
							/>
						)}
						{wantDescription && (
							<div className="lg:max-w-[80%] text-center">
								<FeaturedParagraph
									copy={description}
									color={textColor?.color}
								/>
							</div>
						)}
						{addImageAsset && (
							<Image className={`max-w-[60%] lg:max-w-[30rem]`} data={asset} />
						)}
					</article>
				</div>
			);
		case false:
			return (
				<div
					style={{ background: bgColor?.color.hex }}
					className={` flex flex-col justify-center items-center m-auto`}
				>
					{addSpacer && (
						<Shape
							shape={spacerConfiguration?.customShape}
							color={bgColor}
							direction={spacerConfiguration?.spacerDirection}
							icon={[
								spacerConfiguration?.addIconElement,
								spacerConfiguration?.spacerIcon,
							]}
							position={"bottom"}
							invert={false}
						/>
					)}
					<QuoteSlider color={textColor?.color} data={quotesConfiguration} />
				</div>
			);
		default:
			return <></>;
			break;
	}
}

export const fragment = `
    fragment SectionQuoteFragment on SectionQuoteRecord{
      __typename
      id
      quote
      layout
      addSpacer
      textSize
      spacerConfiguration {
        id
        spacerDirection
        spacerPosition
        customShape {
          img {
            alt
            url
            width
            height
          }
        }
        addIconElement
        spacerIcon {
          img {
            alt
            url
            width
            height
          }
        }
      }
      wantDescription
      description
      quotesNumber
      quotesConfiguration {
        content
        additionalContent
      }
      bgColor{
        color{
          hex
        }
      }
      textColor{
        color{
          hex
        }
      }
      addImageAsset
      asset{
        url
        width
        height
      }
  }
`;
