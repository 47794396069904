export function StoreLocatorIframe({ id }: any) {
  return (
    <iframe
      title="Store Locator"
      src={`https://locator.grappos.com/?uid=${id}`}
      allow="geolocation"
      width="100%"
      height="600"
    ></iframe>
  );
}
