import { ImageLabel, Paragraph } from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import styles from "./index.module.scss";
import { VariantProps, cva } from "class-variance-authority";

export type RecipeCardVariantProps = VariantProps<typeof RecipeCardVariants>;
const RecipeCardVariants = cva(`flex flex-row justify-between  `, {
  variants: {
    layout: {
      default: ` border  p-2 ${styles.border}`,
      gridRounded: `rounded-full p-4 ${styles.borderRound}`,
      featured: "",
    },
  },
  defaultVariants: {
    layout: "default",
  },
});

export interface RecipeCardProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    RecipeCardVariantProps {
  post: any;
  cta: any;
  color: any;
  excerpt: any;
  labelColor?: any;
}

//BUTTON VARIANTS

export type ButtonVariantProps = VariantProps<typeof ButtonVariants>;
const ButtonVariants = cva(`  `, {
  variants: {
    layout: {
      default: ``,
      gridRounded: `mt-4 flex justify-center`,
      featured: "",
    },
  },
  defaultVariants: {
    layout: "default",
  },
});

export default function RecipeCard({
  post,
  color,
  excerpt,
  cta,
  layout,
  labelColor,
}: RecipeCardProps) {
  const style = {
    "--color": excerpt?.color?.hex,
    "--labelColor": labelColor?.color?.hex,
  } as React.CSSProperties;
  return (
    <article className="col-span-2  p-4 px-6 gap-2 flex flex-col h-full justify-between md:min-h-[24rem]  ">
      <div>
        <Title
          className="truncate"
          tag={"h3"}
          data={post.name}
          color={color?.color}
        />
        <Paragraph copy={post.alternativeHeadline} color={excerpt?.color} />
      </div>
      <div className="gap-2 flex flex-col">
        <div style={style} className={` ${RecipeCardVariants({ layout })}`}>
          {post.recipeDetails
            .filter((detail: any) => detail?.detailType == "prepTime")
            .map((time: any, i: number) => (
              <div className="flex-1 flex">
                <ImageLabel
                  className=""
                  key={i}
                  copy={time.value}
                  color={excerpt?.color}
                />
              </div>
            ))}{" "}
          <div
            style={style}
            className={`${styles.border} flex-1 justify-end flex border-l-2`}
          >
            <ImageLabel
              className=""
              copy={post.difficulty}
              color={excerpt?.color}
            />
          </div>
        </div>
        <div className={`${ButtonVariants({ layout })}`}>
          <ButtonMaster
            type={cta?.buttonStyle}
            cta={cta?.styleColor?.color}
            color={cta?.labelColor?.color}
            customIcon={cta?.icon?.img}
            customColor={cta?.iconColor?.color}
            link={`${post?.slug}
        `}
          >
            {cta?.buttonLabel?.label}
          </ButtonMaster>
        </div>
      </div>
    </article>
  );
}
