// components/WineFilter.tsx
import { Title } from "@/components/Atoms/Title";
import { useEffect, useState } from "react";
import DynamicFilter from "./DynamicFilter";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { Console } from "console";

export type AccoladeSelectedFiltersProps = {
  [category: string]: string;
};

export type AccoladeFilterProps = {
  config: any;
  wines: any;
  selectedFilters: AccoladeSelectedFiltersProps;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<AccoladeSelectedFiltersProps>
  >;
  filteredWines: any;
  setDynamicFilter: React.Dispatch<React.SetStateAction<string>>;
  dynamicFilter: string;
};

const WineFilter = ({
  config,
  selectedFilters,
  setSelectedFilters,
  filteredWines,
  setDynamicFilter,
  dynamicFilter,
}: AccoladeFilterProps) => {
  const style = {
    "--filterBg": config?.filterBarBgColor?.color.hex,
    "--label": config?.labelColor?.color?.hex,
    "--textColor": config?.textColor?.color?.hex,
  } as React.CSSProperties;
  const [showFilters, setShowFilters] = useState(false);
  const { t } = useTranslation();

  // show filters on page load if selectedFilters is not empty

  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      setShowFilters(true);
    }
  }, [selectedFilters]);

  useEffect(() => {
    setDynamicFilter(
      selectedFilters.type ||
        dynamicFilter ||
        config?.dynamicFilters[0].__typename
    );
  }, [selectedFilters]);

  const handleFilterChange = (
    category: string,
    value: string,
    type: string
  ) => {
    setSelectedFilters((prevFilters: any) => ({
      ...prevFilters,
      [category]: value,
    }));
    if (category !== value) {
      // add params to url using window.history.pushState
      const params = new URLSearchParams(window.location.search);

      params.set(category, value);
      params.set("type", type);
      window.history.pushState({}, "", `${window.location.pathname}?${params}`);
    }
  };

  const handleFilterRemove = (category: string) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[category];
      return newFilters;
    });
    // remove params from url using window.history.pushState
    const params = new URLSearchParams(window.location.search);
    params.delete(category);
    window.history.pushState({}, "", `${window.location.pathname}?${params}`);
  };

  // Use useEffect to initialize the selectedFilters from URL parameters
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const initialFilters: { [key: string]: string } = {};
    params.forEach((value, key) => {
      initialFilters[key] = value;
    });
    setSelectedFilters(initialFilters);
  }, []);

  const allowedKeys = [
    "ratings",
    "mediaPublication",
    "taster",
    "accoladeYear",
    "country",
    "year",
    "winemaker",
    "productTypeSpecific",
    "type",
    "productCategory",
    "awards",
    "contest",
    "productColour",
  ];

  const filteredFilters = Object.keys(selectedFilters)
    .filter((key) => allowedKeys.includes(key))
    .reduce((obj: any, key) => {
      obj[key] = selectedFilters[key];
      return obj;
    }, {});

  const renderSelectedFilters = () => {
    return Object.entries(filteredFilters)
      .map(([category, value]: any) => {
        if (category !== value && category !== "type") {
          return (
            <div className="min-w-fit relative font-secondary" key={category}>
              <p
                style={style}
                className={`${styles.labelFilter} md:px-[5px] text-[10px] md:absolute left-[5px] top-[-7px] font-bold`}
              >
                {t(category)}
              </p>
              <button
                style={style}
                onClick={() => handleFilterRemove(category)}
                className={`${styles.btnFilter} flex flex-row gap-6 border px-4 py-2 items-center w-full justify-between text-xs md:text-sm h-[47px] md:h-[55px]`}
              >
                {value}
                <span className="font-bold text-md">x</span>
              </button>
            </div>
          );
        }
        return null; // Don't render anything if category === value
      })
      .filter(Boolean); // Remove any `null` entries
  };

  // CLEAR ALL FILTERS
  const clearAllFilters = () => {
    setSelectedFilters({});
    window.history.pushState({}, "", window.location.pathname);
  };

  // TOGGLE FILTERS
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // TOGGLE DYNAMIC FILTERS
  const toggleDynamicFilters = (item: string) => {
    setDynamicFilter(item);
    clearAllFilters();
  };

  //FRAMER ANIMATIONS OPTIONS

  const opacity = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const variants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
  };

  const variantsAlt = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
  };

  return (
    <div className="flex flex-col  w-full ">
      <div
        style={style}
        className={`${styles.filter} w-full flex flex-row gap-4   px-6 lg:px-4 xl:px-2 2xl:px-0 py-6`}
      >
        <div className=" container mx-auto  flex flex-col gap-10 md:flex-row justify-between  px-6 md:px-12 lg:px-16">
          <div className="flex flex-wrap md:flex-row gap-4 items-end">
            {config?.filterButtonConfig.map((item: any, i: number) => (
              <ButtonMaster
                key={i}
                type={item?.buttonStyle}
                onClick={toggleFilters}
                color={item?.labelColor?.color}
                cta={item?.typeColor?.color}
                link={""}
                customColor={item?.iconColor?.color}
                customIcon={item?.icon?.img}
              >
                {item?.buttonLabel?.label}
              </ButtonMaster>
            ))}
            {renderSelectedFilters()}
          </div>

          {showFilters && (
            <motion.div
              key={"clear"}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5 }}
              className="flex flex-row gap-4 md:gap-6 items-center w-full md:w-fit justify-between flex-none"
            >
              <button
                onClick={clearAllFilters}
                className="font-primary text-base md:text-lg"
              >
                {t("Clear")}
              </button>
              <div className="font-primary text-base md:text-lg">
                {filteredWines.length} {t("results")}
              </div>
            </motion.div>
          )}
        </div>
      </div>

      {config?.addHeadline && (
        <div className="py-6 md:py-12 ">
          <Title
            layout="fixedCenter"
            tag={"h3"}
            data={config?.headline}
            color={config?.textColor?.color}
          />
        </div>
      )}

      {showFilters && (
        <motion.div
          key={"button"}
          variants={opacity}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5 }}
          className="filterBox container mx-auto px-6 md:px-12 lg:px-16"
        >
          <motion.div
            variants={variantsAlt}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ delay: 0.3, duration: 0.5 }}
            className="flex flex-col md:flex-row gap-4 pt-2 md:pt-6"
          >
            {config?.dynamicFilters.map((item: any, i: number) => (
              <button
                style={style}
                onClick={() => toggleDynamicFilters(item.__typename)}
                className={`${styles.buttonFilterCat} ${
                  item.__typename == dynamicFilter ? `${styles.activeBtn}` : ``
                } border w-full md:w-fit p-2 md:p-4 font-secondary text-xs md:text-sm`}
                key={i}
              >
                {t("searchBy")} {t(item.__typename)}
              </button>
            ))}
          </motion.div>
          <motion.div
            variants={variantsAlt}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ delay: 0.5, duration: 0.5 }}
            className="pt-2 md:pt-6"
          >
            {config?.dynamicFilters.map((item: any, i: number) => {
              if (dynamicFilter == item.__typename) {
                return (
                  <DynamicFilter
                    key={i}
                    config={config}
                    onFilterChange={handleFilterChange}
                    selectedFilters={selectedFilters}
                    filters={item}
                    products={filteredWines}
                  />
                );
              }
            })}
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default WineFilter;
