import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { Title } from "@/components/Atoms/Title";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import { StoreLocatorIframe } from "@/components/Atoms/Iframes";

export default function StoreLocator(data: any) {
  return (
    <>
      <div
        style={{ background: data.bgColor?.color.hex }}
        className={` flex flex-col justify-center items-center m-auto relative`}
      >
        {data.title && (
          <>
            {" "}
            <FullBackgroundImage data={data.backgroundImage} />
            <header className="relative py-12 lg:py-20 flex flex-col items-center gap-4 text-center">
              <div className="flex flex-col gap-4 items-center">
                <Title
                  tag={"h2"}
                  data={data.title}
                  color={data.textColor?.color}
                />
                {data.addTitleUnderline && (
                  <>
                    {data.customUnderlineAsset?.img ? (
                      <CustomUnderline
                        shape={data.customUnderlineAsset?.img}
                        color={data.underlineColor?.color}
                      />
                    ) : (
                      <Underline color={data.underlineColor?.color} />
                    )}
                  </>
                )}
              </div>
            </header>
          </>
        )}
      </div>
      <StoreLocatorIframe id={data.storeId} />
    </>
  );
}

export const fragment = `
      fragment StoreLocatorFragment on SectionStoreLocatorRecord{
        __typename
        title
        id
        storeId
        addBackgroundImage
        bgColor {
          color {
            hex
          }
        }
        backgroundImage {
          responsiveImage {
            ... responsiveImageFragment
          }
        }
        addTitleUnderline
        underlineColor{
          color{
            hex
          }
        }
        customUnderlineAsset{
          img{
            url
            width
            height
          }
        }
        textColor {
          color {
            hex
          }
        }
    }
  `;
