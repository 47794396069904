import dynamic from "next/dynamic";
import {
  dynamicPagesConfig,
  LocaleRouteTypes,
} from "@/lib/dynamic-pages-config";

const defaultLanguage = process.env.DEFAULT_SITE_LOCALE;

interface IRouterHandler {
  typename: string;
  slug: any;
  locale: any;
}

export const routerHandler = ({ typename, slug, locale }: IRouterHandler) => {
  const _typename = typename as keyof typeof dynamicPagesConfig;
  const _locale = locale as keyof LocaleRouteTypes;

  if (typeof slug === 'object') {
    slug = slug?.filter((s: any) => s !== undefined).join('/');
  }

  const language = locale !== defaultLanguage ? `/${locale}` : "";
  const basePath = dynamicPagesConfig[_typename]?.routers[_locale] || "";
  return (`${language}/${basePath.length > 0 ? basePath + '/' : ''}/${slug}`).replace(new RegExp('(\\/)\\1+', 'mg'), '/');
};

/**
 * @deprecated
 * Define which type of page based on slug
 */
// export const getPageType = ({ slug }) => {

//     if (slug !== undefined && slug.length === 1) {
//         // this is a landing page
//         return 'BrandsPageRecord';
//     } else {

//         let pageType = []
//         const component2 = Object.entries(dynamicPagesConfig).forEach(key => {

//             let routers = Object.values(key[1].routers)
//             routers.map(r => {
//                 if (r.indexOf(slug[0]) > -1) {
//                     pageType = key[0];
//                 }
//             })
//         });
//         return pageType;
//     }
// }
