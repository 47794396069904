import React from "react";
import Image from "@/components/Atoms/Image";
import "react-multi-carousel/lib/styles.css";
import { OverlayShadow } from "@/components/Molecules/OverlayShadow/Overlay";
import { FullBackgroundImageHero } from "@/components/Molecules/ImageCard";
import { FeaturedCopyCard } from "@/components/Molecules/HeroCopyCard";
import { HubSpotForm } from "@/components/Molecules/Forms";

export default function HeroFeatured(data: any[any]) {
  return (
    <div
      className="min-h-[90vh] relative overflow-hidden"
      id={data.id}
      style={{ backgroundColor: data?.bgColor?.color.hex }}
    >
      <div
        className={`${
          data.addContent ? " grid-cols-1 lg:grid-cols-2" : "grid-cols-1"
        } grid`}
      >
        <div className="col-span-1 relative">
          {data.overlay && (
            <OverlayShadow
              format={data.textAlignment}
              data={data.overlay}
              color={data.imageOverlayBaseColor}
              layout={data.textAlignment}
            />
          )}
          {!data.swapBgForColor && (
            <>
              {" "}
              {data.backgroundImage && (
                <FullBackgroundImageHero
                  position={data.imagePosition}
                  data={data.backgroundImage}
                />
              )}
            </>
          )}

          <div className="grid grid-cols-1  container mx-auto  gap-6 md:gap-0 m:pb-[10rem] relative h-full  md:min-h-[90vh] ">
            <div
              className={`col-span-1 flex items-center  ${
                !data.addContent ? `max-w-[90%] m-auto pt-[150px] ` : ` pt-[100px] lg:pt-[0px] lg:translate-y-[10rem] `
              } `}
            >
              <FeaturedCopyCard
                parallaxImage={[
                  data?.addParallaxElements,
                  data?.parallaxAsset,
                ]}
                title={data.title}
                description={data.description}
                color={data.textColor?.color}
                descriptionColor={data.descriptionColor?.color}
                cta={[data.addCallToAction, data.pageRelated]}
              />
            </div>
            <div
              className={`  ${
                !data.addContent ? `translate-y-[5rem]` : `translate-y-[2rem] md:translate-y-[4rem]`
              } col-span-1 flex items-end  bottom-[-4rem] left-0 right-0  `}
            >
              <Image
                data={data?.featuredImage}
                className="mx-auto max-w-[250px] md:max-w-[300px]  lg:max-w-[400px]"
              />
            </div>
          </div>
        </div>
        {data.addContent && (
          <div
            style={{ backgroundColor: data?.bgColor?.color.hex }}
            className="col-span-1 flex flex-col py-12 md:pt-[120px] text-center relative z-10 justify-center"
          >
            <HubSpotForm data={data?.contentType} />
          </div>
        )}
      </div>
    </div>
  );
}

export const fragment = `
    fragment HeroFeaturedFragment on SectionHeroFeaturedRecord{
      __typename
      id
      name
      title
      description
      textColor{
        color{
          hex
        }
      }
        overlay
        imageOverlayBaseColor {
          hex
        }
        textAlignment
        textFlexPosition
        bgColor{
          color{
            hex
          }
        }
        swapBgForColor
        addScrollIcon
        scrollIconType
        iconColor {
          color {
            hex
            alpha
          }
        }
        iconBackgroundColor {
          color {
            hex
          }
        }
        addTitleUnderline
        underlineColor{
          color{
            hex
          }
        }
        customUnderlineAsset{
          img{
            url
            width
            height
          }
        }
        addCustomIconBackgroundElement
        iconBackgroundAsset {
          url
          width
          height
        }
        customScrollIcon {
          url
          width
          height
        }
        useSpacer
        addParallaxElements
        parallaxAsset {
          url
        }
      featuredImage {
        id
        title
        alt
        tags
        format
        focalPoint {
          y
          x
        }
        responsiveImage (imgixParams: { fit: crop, ar: auto, auto:format, q:80 }) {
          ... responsiveImageFragment
        }
      }
      backgroundImage {
        id
        title
        alt
        tags
        format
        focalPoint {
          y
          x
        }
        responsiveImage (imgixParams: { fit: crop, minW: "1500", crop: focalpoint, ar: auto, auto:format, q:80 }) {
          ... responsiveImageFragment
        }
      }
      addContent
      contentType {
        __typename
        ...FormFragment
      }
    }
`;
