import { ImageLabel, Label } from "@/components/Atoms/Paragraph";
import { TextCopyCard } from "@/components/Molecules/CopyCard";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { VideoCard } from "@/components/Molecules/VideoCard";
import { string_to_slug, variantsFull } from "@/lib/functions";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { useState } from "react";
import { Shape } from "@/components/Atoms/Spacer";
import React from "react";

interface Props {
  data: any;
}

export default function TextImageFull({ data }: Props) {
  const [playing, setPlaying] = useState(false);

  const togglePlay = () => {
    setPlaying(!playing);
  };

  return (
    <>
      {" "}
      {data.addSpacer && (
        <Shape
          shape={data.spacerConfiguration?.customShape}
          color={data.bgColor}
          direction={data.spacerConfiguration?.spacerDirection}
          icon={[
            data.spacerConfiguration?.addIconElement,
            data?.spacerConfiguration?.spacerIcon,
          ]}
          position={data.spacerConfiguration?.spacerPosition} invert={false}        />
      )}
      <div
        style={{ backgroundColor: data?.bgColor?.color?.hex }}
        className={`${data.useExtraTopMargins ? "pt-24" : ""} relative w-full`}
      >
        <div className="container mx-auto">
          <div
            className={`  max-w-[1360px] flex ${
              data.copyPlacement == "first" ? "flex-col-reverse " : " flex-col"
            }   gap-12 gap-x-12 relative mx-auto w-full   ${
              data.useExtraPadding ? "pt-12 pb-32" : "py-6 md:py-12 "
            } `}
          >
            <div
              className={` relative ${
                data.textAlignment === "center"
                  ? " items-center"
                  : data.textAlignment === "left"
                  ? " items-start"
                  : data.textAlignment === "right"
                  ? " items-end"
                  : "items-center text-center"
              } order-2  flex flex-col px-6  lg:px-0 ${
                data?.imagePosition == "contain" ? "mt-12" : ""
              } `}
            >
              <TextCopyCard
                layout={data.textAlignment}
                placeEnd={data.placeTitleEndOfContent}
                data={data}
              />
              <div
                className="absolute top-[-100px]"
                id={string_to_slug(data.title)}
              ></div>
            </div>

            <div
              className={` order-1 justify-center flex flex-col relative  px-6 lg:px-4 xl:px-2 2xl:px-0 ${
                data.contentBorderRadius == "rounded"
                  ? "rounded-lg overflow-hidden"
                  : ""
              }`}
            >
              {data.image.video ? (
                <LazyMotion features={domAnimation}>
                  <m.div
                    className="relative"
                    variants={variantsFull()}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    exit="exit"
                  >
                    <VideoCard
                      onClick={() => togglePlay()}
                      color={data.fontColor?.hex}
                      controls={[
                        data.loop,
                        data.muted,
                        data.controls,
                        data.videoPreview,
                        data.autoplay,
                      ]}
                      file={data.image.video}
                      className=" cursor-pointer object-cover object-bottom h-full"
                      placement={data.__typename}
                    />
                  </m.div>
                </LazyMotion>
              ) : (
                <div
                  className={`relative w-full h-[15rem] md:h-[20rem] lg:h-[40rem] `}
                >
                  <LazyMotion features={domAnimation}>
                    <m.div
                      className="top-0 w-full h-full md:absolute  md:block "
                      variants={variantsFull()}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      exit="exit"
                    >
                      <FullBackgroundImage
                        className="object-center"
                        data={data.image}
                        position={data?.imagePosition}
                      />
                    </m.div>
                  </LazyMotion>
                  {data.showLabel && (
                    <div className="absolute bottom-[-1.5rem] left-0 w-fit">
                      <ImageLabel
                        color={data.assetLabelColor?.color}
                        copy={data.image?.title}
                      />
                    </div>
                  )}
                </div>
              )}
              {data.showLabel && (
                <Label
                  className="uppercase"
                  color={data.imageLabelColor}
                  copy={data?.imageLabel}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
