import { useRouter } from "next/router";
import { NextResponse } from "next/server";
import type { NextRequest } from "next/server";

interface Props {
	children: any;
	list: boolean;
	country: any;
	initial: boolean;
	type?: string;
	data?: any;
}

export function GeoHandler({
	list,
	country,
	initial,
}: Pick<Props, "list" | "country" | "initial">) {
	const router = useRouter();
	const currentCountry = router.query.country;
	const ignoreGeo = router.query.ignoregeo;

	if (initial !== false || ignoreGeo == "true") return true;

	if (list) {
		var availableCountries = country?.some(
			(country: any) => country.countryCode !== currentCountry
		);
	} else {
		var availableCountries = country?.some(
			(country: any) => country.countryCode == currentCountry
		);
	}
	if (availableCountries) return true;

	return false;
}

export default function GeoWrapper({
	children,
	list,
	country,
	initial,
}: Props) {
	const router = useRouter();
	const currentCountry = router.query.country;
	const ignoreGeo = router.query.ignoregeo;

	if (initial !== false || ignoreGeo == "true") {
		return children;
	} else {
		if (list) {
			var availableCountries = country?.some(
				(country: any) => country.countryCode !== currentCountry
			);
		} else {
			var availableCountries = country?.some(
				(country: any) => country.countryCode == currentCountry
			);
		}

		if (availableCountries) {
			return children;
		} else {
			return null;
		}
	}
}

export function GeoWrapperPage({ list, country, initial, children }: Props) {
	const router = useRouter();
	const currentCountry = router.query.country;
	const ignoreGeo = router.query.ignoregeo;

	if (initial !== false || ignoreGeo == "true") {
		return children;
	} else {
		if (list) {
			var availableCountries = country?.some(
				(country: any) => country.countryCode !== currentCountry
			);
		} else {
			var availableCountries = country?.some(
				(country: any) => country.countryCode == currentCountry
			);
		}

		if (availableCountries) {
			return children;
		} else {
			return <></>;
		}
	}
}
