import React, { useState } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Carousel from "react-multi-carousel";
import Image from "@/components/Atoms/Image";
import "react-multi-carousel/lib/styles.css";
import { routerHandler } from "@/lib/routes/router-links-handler";

import styles from "./index.module.scss";

import { Arrow, Chevron } from "@/components/Atoms/Vectors";

import { Title } from "@/components/Atoms/Title";

import { ButtonMaster, ButtonSection } from "@/atoms/Buttons";

import { SliderCard } from "@/components/Molecules/SliderCard";
import { getPortfolioSlug, pageRelatedLink } from "@/lib/functions";

interface SliderNav {
  next: Function;
  previous: Function;
  goToSlide: Function;
  carouselState: any;
  rest?: any;
}

export default function SectionSlider(
  data: any[any],
  { next, previous, goToSlide, carouselState }: SliderNav
) {
  const { t } = useTranslation();
  const { locale }: any = useRouter();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 100,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 100,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 10,
    },
  };

  const HeroSliderNav = ({ next, previous, goToSlide, ...rest }: SliderNav) => {
    const {
      carouselState: { currentSlide },
    } = rest;

    switch (data.addSliderNavigationArrows) {
      case true:
        return (
          <div className=" absolute justify-between mx-auto   w-[90%] left-0 right-0 flex flex-row gap-12">
            <button
              className={` flex items-center justify-center scale-[-2.5]`}
              onClick={() => previous()}
            >
              <Chevron color={data.sliderNavigationColor?.color} />
            </button>
            <button
              className={`flex items-center justify-center scale-[2.5]`}
              onClick={() => goToSlide(currentSlide + 1)}
            >
              <Chevron color={data.sliderNavigationColor?.color} />
            </button>
          </div>
        );
        break;
      default:
        return <></>;
        break;
    }
  };
  const portfolioSlug = getPortfolioSlug(data.pageRelated?.[0]?.page, locale);
  return (
    <div style={{ backgroundColor: data.sectionBackgroundColor?.color.hex }}>
      <div
        className={`overflow-hidden ${data.useSectionMargins ? "py-6 md:py-12" : "py-0"
          } `}
      >
        <Title
          tag="h3"
          data={data.title}
          color={data.cardTextColor?.color}
          layout="fixedCenter"
        />
        <div className="max-w-[1000px] my-12 mx-auto">
          <Carousel
            containerClass={`${styles.Slider}`}
            responsive={responsive}
            itemClass="h-full"
            arrows={false}
            centerMode={true}
            infinite={true}
            sliderClass={styles.SliderTrack}
            minimumTouchDrag={10}
            customButtonGroup={
              <HeroSliderNav
                carouselState={carouselState}
                next={next}
                previous={previous}
                goToSlide={goToSlide}
              />
            }
          >
            {data.content.map((entry: any, index: number) => (
              <div
                key={index}
                className={`${styles.activeDiv} h-full relative`}
              >
                {entry && (
                  <>
                    <Image
                      className={`${styles.sliderImage} z-30 mx-auto h-[33rem] hidden md:flex`}
                      data={
                        entry?.factSheetImage || entry.sku[0]?.skuImageGallery[0]
                      }
                    />
                    <SliderCard
                      title={entry?.productName}
                      description={entry?.productDescriptionShort}
                      image={
                        entry?.factSheetImage || entry.sku[0]?.skuImageGallery[0]
                      }
                      pageRelated={[
                        entry.slug,
                        data.sliderCardCallToActionStyle,
                        data.callToActionTextColor?.color,
                        data.callToActionStyleColor?.color,
                        entry.__typename,
                        locale,
                        entry.productTypeSpecific,
                        data.callToActionCustomIcon,
                      ]}
                      portfolioSlug={portfolioSlug}
                      card={[
                        data.sliderCardLayout,
                        data.cardColor?.color,
                        data.cardTextColor?.color,
                      ]}
                      navArrow={[
                        data.addSliderNavigationArrows,
                        data.sliderNavigationArrowColors,
                      ]}
                      related={data.pageRelated}
                      underline={data.addTitleUnderline}
                      underlineColor={data.underlineColor?.color}
                      customUnderline={data.customUnderlineAsset}
                    />
                  </>
                )}
              </div>
            ))}
          </Carousel>
        </div>
        {data.pageRelated &&
          data.pageRelated.map((entry: any, i: number) => {
            const style = {
              "--color": entry.buttonFontColor?.hex,
              "--cta": entry.buttonStyleColor?.hex,
            } as React.CSSProperties;

            return (
              <div key={i} className="flex flex-row justify-center ">
                <ButtonMaster
                  anim={""}
                  type={entry?.buttonStyle}
                  cta={entry?.typeColor?.color}
                  color={entry?.labelColor?.color}
                  customIcon={entry?.icon?.img}
                  customColor={entry?.iconColor?.color}
                  target={`${entry?.page ? `_self` : `_blank`}`}
                  link={` ${entry?.page
                      ? ` ${routerHandler({
                        typename: entry?.__typename,
                        slug: pageRelatedLink(
                          entry?.page,
                          locale,
                          entry?.sectionAnchor?.title
                        ),
                        locale: locale,
                      })}`
                      : `${entry?.externalUrl}`
                    }`}
                >
                  {entry?.buttonLabel?.label}
                </ButtonMaster>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export const fragment = `
    fragment SliderFragment on SectionSliderRecord{
        __typename
        id
        name
        title
        subtitle
        content {
            productName
            productDescriptionShort
            productDescription
            slug
            productTypeSpecific {
              __typename
              slug
            }
            factSheetImage {
              responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                ... responsiveImageFragment
              }
            }
            sku {
              skuName
              skuImageGallery {
                responsiveImage(imgixParams: {h: "500", fit: clamp, crop: focalpoint, auto: format, q: "65", ar: "1:1"}) {
                    ...responsiveImageFragment
                 }
                }
            }
         }
         useSectionMargins
        sliderCardLayout
        sliderCardCallToActionStyle
        addSliderNavigationArrows
        addSliderCardCallToAction
        addTitleUnderline
          underlineColor{
            color{
              hex
            }
          }
          cardColor{
            color{
              hex
            }
          }
          cardTextColor{
            color{
              hex
            }
          }
          customUnderlineAsset {
            img {
              url
              width
              height
            }
          }
          callToActionTextColor{
            color{
              hex
            }
          }
          callToActionStyleColor{
            color{
              hex
            }
          }
          callToActionCustomIcon{
            img{
              url
              width
              height
            }
          }
          sliderNavigationColor{
            color{
              hex
            }
          }
          sectionBackgroundColor{
            color{
              hex
            }
          }
          pageRelated {
            ...pageRelatedFragment
          }

  }
`;
