import { Label, Paragraph } from "@/components/Atoms/Paragraph";
import { HeroProductTitle } from "@/components/Atoms/Title";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { OverlayShadow } from "@/components/Molecules/OverlayShadow/Overlay";

interface Props {
  config: any;
  title: string;
  image: any;
  tag: any;
}

export default function HeroArticle({
  config,
  title,

  image,
  tag,
}: Props) {
  return (
    <div className={`relative lg:h-[700px] pb-8 flex mb-8 lg:mb-0`}>
      <div className=" top-0 absolute h-full w-full lg:h-[85%]">
        {config.addBackgroundOverlay && (
          <OverlayShadow
            format={"heroProduct"}
            data={"sdark"}
            color={config.shadowOverlayBaseColor}
            layout={"left"}
          />
        )}

        <FullBackgroundImage data={image} />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-y-4 md:gap-y-12 pt-12 lg:pt-0 relative z-10">
        <article className="flex px-6 lg:px-4 xl:px-2 2xl:px-0 flex-col  py-8 justify-center">
          {tag && (
            <>
              {tag.map((x: any, y: number) => (
                <Label
                  key={y}
                  copy={x.title}
                  color={config?.highlightTextColor.color}
                />
              ))}
            </>
          )}
          <HeroProductTitle
            layout={"left"}
            color={config?.heroTextColor?.color}
            className="brandProductTitle"
            mcolor={""}
            tag="h2"
            data={title}
          />
        </article>
      </div>
    </div>
  );
}
