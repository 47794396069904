import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function ImageSlider(data: any) {
  const responsiveSlider = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      containerClass={`relative w-full flex flex-row  mt-6 lg:mt-12 pb-6`}
      responsive={responsiveSlider}
      sliderClass={""}
      draggable={false}
      swipeable={true}
      arrows={true}
      infinite={true}
      minimumTouchDrag={10}
      itemClass={` h-full`}
    >
      {data.children}
    </Carousel>
  );
}
