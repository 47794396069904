import Image from "@/components/Atoms/Image";

interface Props {
  data: any;
  layout: string;
}

export function IconGallery({ data, layout }: Props) {
  if (layout == "tRight") {
    return (
      <div className="iconGallery absolute right-6 top-6 flex flex-row gap-4 z-10">
        {data.map((entry: any, i: number) => (
          <Image data={entry} className={"max-w-[8rem] lg:max-w-[7.5rem]"} />
        ))}
      </div>
    );
  } else {
    return (
      <div className="iconGallery absolute right-6 bottom-6 flex flex-row gap-4 z-10">
        {data.map((entry: any, i: number) => (
          <Image key={i} data={entry} className={"max-w-[7.5rem]"} />
        ))}
      </div>
    );
  }
}
